import React from 'react'
import './styles.css'

function UserCircle({
    color, fSize='16px' , 
    diametro ='51px', icon, 
    action = () => {},  
    children,className='', 
    disableBoxShadow,
    id, borderGray=false}) {
    /*const str =(children.length>2)?children[0].concat(children[2]):children[0].concat(children[1])*/
    const info= children ? <div className='userCircleFooter'>{children}</div> : null
    return (
        <div id={id} style={{display:'flex', flexDirection:'column', position:'relative', padding: 0}}>
        <button type='button' onClick={action} className={`${borderGray && 'borderGray'}  clearBtn userCircleCont ${className}`}>
            <div style={{boxShadow:disableBoxShadow && 'none', backgroundColor: color, height: diametro, width:diametro, fontSize: fSize}} className={`imgUser text-uppercase`}>{icon}{info/*children[0]+children[1]*/} </div>
        </button>
        </div>
    )
}

export default React.memo(UserCircle)
