import {SET_PLACES_ADMIN_LIST_QUERY} from '../const'

export const adminPlaces = (state = {},action) =>{
    switch (action.type) {
        case SET_PLACES_ADMIN_LIST_QUERY:
            const newState2 = {...state}
            newState2.places = action.value.places
            newState2.query = action.value.query || ''
            newState2.page = action.value.page ||0
            newState2.Nitems = action.value.Nitems
            newState2.hidden = action.value.hidden || ''
            newState2.incomplete = action.value.incomplete || ''
            newState2.city = action.value.city || ''

            return newState2;
        default:
            return state;
    }
}