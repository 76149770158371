import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import CityCard from './CityCard'
import './styles.css'
import { useTranslation } from 'react-i18next'
import api from '../../utils/axiosApi'
import Highlighted from '../../utils/Highlighted'
import '../SearchCalendarHeader/styles.css'
import '../SearchCalendarBody/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import logo from '../../img/logoW.avif'
let timeout
let timeout2

function CitiesPopUp({
    cityQuery='',
    onselectCity=()=>{},
    onClose=()=>{},
}) {
    const [state, setState] = useState({
        show:false,
        loading:false,
    })
    const [loading, setLoading] = useState(true)
    const [places, setPlaces] = useState([])
    const [cities, setCities] = useState({
        data:[],
        number:0,page:0
    })
    const [showSearch, setShowSearch] = useState(false)
    const [citySearch, setCitySearch] = useState({
        fullName:'',
        _id:''
    })
    const [actualQuery, setActualQuery] = useState(cityQuery)
     const { t,i18n } = useTranslation();
    useEffect(() => {
        const setCitiesRequest= async () => {
            try {
                const calendarsResp = await api.get(`/ciudades/getcitiesPage?query=${actualQuery}&page[number]=${0}`)
                const {data, error, meta } = calendarsResp.data
                if (error) return
                setCities({data, number:meta.page.total,page:1})
                setLoading(false)
            } catch (error) {
                //console.log(error)
            }
        }
        setCitiesRequest()
        return () => {
        }
    }, [actualQuery])
    const handleChange= async (e) => {
        setCitySearch({
            fullName:e.target.value,
            _id:''
        })
        
        if(timeout){
            clearTimeout(timeout)
        }
        timeout=setTimeout(()=>{
            
            handleSubmit(e.target.value)
            timeout =undefined
        },1500)
        if(e.target.value !== '') {
            if(places.length === 0){
                setState({
                    loading:true
                })
            }
            try {
                setShowSearch(true)
                
                if(timeout2){
                    clearTimeout(timeout2)
                }
                timeout2 = setTimeout(async ()=>{
                    const resp = await api.get(`/ciudades/getcities?city=${e.target.value}`)
                    const {data,error,meta} = resp.data
                    if(error) return
                    if(meta.query === e.target.value) {
                        setPlaces(data)
                        setState({
                            loading:false
                        })
                    }
                    timeout2=undefined
                },500)
            } catch (error) {
                setPlaces([])
                setState({
                    loading:false
                })
                //console.log(error)
            }
        } else {setPlaces([]); 
            setShowSearch(false)
            setState({
            loading:false
        })}
    }
    const handleSubmit = async (city) => {
        setLoading(true)
        
        setState({
            loading:false
        })
        setActualQuery(city)
        await fetchData(city)
        setLoading(false)
    }
    const fetchData = async (query) => {
        
        const {
            data=[],page=0} = cities
            if(!query && query!==''){

                try {
                    const resp = await api.get(`/ciudades/getcitiesPage?query=${actualQuery}&page[number]=${page}`)
                    const dataCities = resp.data.data,
                            error = resp.data.error,
                            meta = resp.data.meta
                    if (error) return
                    const newData= data.concat(dataCities)
                    setCities({data:newData, number:meta.page.total,page:1+page})
                    
                } catch (error) {
                    //console.log(error)
                }
            } else {
                try {
                    const resp = await api.get(`/ciudades/getcitiesPage?query=${query}&page[number]=${0}`)
                    const dataCities = resp.data.data,
                            error = resp.data.error,
                            meta = resp.data.meta
                    if (error) return
                    setCities({data:dataCities, number:meta.page.total,page:1})
                    
                } catch (error) {
                    //console.log(error)
                }
            }
    }
    return (
        <> 

            <div className='totalPopUpUpperCont'>
                <div id='citiesPopUp' className='popUpCitiesCont position-relative' onClick={(e)=>{
                    setShowSearch(false)
                }}>
                            <button 
                            style={{fontSize:'15px'}}
                            onClick={onClose} type='button' className=' btnCancelLog btnCloseCities d-md-none'>
                                <FontAwesomeIcon color={`${'#001A72'}`} icon='times'/>
                            </button>
                    <div className='headerCitycont'>
                        <h2>{t('CitySearchTitle',{nombre: cities.number})}
                            <div className='emojiCont d-inline-block ml-2'>
                            <img alt="✈️" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/2708-fe0f.png"/>
                            </div></h2>
                        <Col xs={12} md='10' lg='8' className=' mb-md-0 p-0'>
                        <form onSubmit={(e)=>{e.preventDefault() ;handleSubmit(citySearch.fullName)} } className='SearchInfoCont SearchCitiesCont d-flex' >

                            <input style={{height:'35px'}} onChange={handleChange} className='searchinput' value={citySearch.fullName} placeholder={t("CitySearchTitlePlaceHolder")} type='search'></input>
                            <button className='searchbutton searchbuttoncities  rounded-circle' type='submit'><FontAwesomeIcon icon='search'></FontAwesomeIcon></button>
                            
                            <Collapse id='citiesOptionColapse' style={{position: 'absolute', top:'45px', left:0, right:0}} isOpen={(showSearch)}>
                                <ul style={{zIndex: 300, position:'relative', top:0}} className='optionsSearch'> 
                                {(!state.loading) && places.map(e => {
                                
                                    let cityString = e.city
                            let country = e.country
                            let state = e.state
                            if(i18n.language==='en') {
                                cityString = e.cityEn
                                country = e.countryEn
                                state = e.stateEn
                            }
                            if(!cityString){
                                cityString = state
                            }
                            /*const citiesnames =cities.map(e=>{return e.city})
                            const firstInd = citiesnames.indexOf(e.city)
                            const lastIndexOf = citiesnames.lastIndexOf(e.city)*/
                            const place = `${cityString}, ${country}`
                            const cityText = cityString? Highlighted({text:cityString, highlight:citySearch.fullName}) :''
                            //const countrytext = country? Highlighted({text:country, highlight:citySearch.fullName}) :''
                            const statetext = state? Highlighted({text:state, highlight:citySearch.fullName}) :''
                            //const showState = (firstInd!==lastIndexOf) 
                            return (
                            <li className='optionAutoComplete optionAutoCompleteCity d-flex' onClick={() => {
                                    setCitySearch({
                                        fullName:place,
                                        _id:e._id
                                    })
                                    setState({
                                        loading:false
                                    })
                                    handleSubmit(place)
                                    setShowSearch(false)
                                }}>
                                {e?.emoji?.url && <div className='emojiCont d-inline-flex justify-content-center align-items-center mr-2'>
                                <img style={{height:'15px'}} alt={e?.emoji?.emoji} src={e?.emoji?.url}></img>
                                </div>}
                                <p className='m-0'>
                                {cityText} <span className='d-block'>{(state!==cityString) && statetext}</span>
                                </p>
                            </li>)
                            })}
                            {(places.length===0 && !state.loading ) && <li className='optionAutoComplete'>{t("noCityMsg")}<span></span></li> }
                            {(!state.loading) ? <></> : 
                                <div className='d-flex justify-content-center' style={{color: '#E5E9F2' ,fontSize: '50px', width:'100%', padding: '5px 0'}}>
                                <FontAwesomeIcon spin icon='circle-notch'/>
                            </div>
                            }
                                </ul>
                        </Collapse>
                        </form>
                    </Col>
                    </div>
                    

                    
                    <div className='bodyCitycont'>
                            <InfiniteScroll
                        style={{ width: '0px', color: 'transparent',overflowX: 'hidden'}}
                        className=' w-100'
                        dataLength={cities.data.length} //This is important field to render the next data
                        next={fetchData}
                        hasMore={cities.data.length<cities.number}
                        height={'100%'}
                        loader={<div style={{
                            marginTop:'15px',
                        color: '#E5E9F2' ,
                        fontSize: '100px',
                        height:'25px',
                        width:'100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center'}}>
                        <FontAwesomeIcon spin icon='circle-notch'/>
                        </div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                            <b></b>
                            </p>
                        }
                        >
                        {(!loading && cities.data.length===0) && <div className='noPlaces'>
                
                            <h3 className='text-center'>gg</h3>
                            
                        </div>}
                        <Row className=''>

                        {cities.data.map((e,i)=> {
                            
                            return <Col xs='6' sm='4' md='6' className={`mb-4 d-flex justify-content-center`} key={`cityCard_${i}`}>
                                <CityCard
                                onClick={()=>{
                                    let cityString = e.city
                                    let country = e.country
                                    if(i18n.language==='en') {
                                        cityString = e.cityEn
                                        country = e.countryEn
                                    }
                                    const place = `${cityString}, ${country}`
                                    onselectCity(
                                    {
                                        name:place,
                                        id:e._id
                                    }
                                )}}
                                city={e}
                                ></CityCard>
                            </Col>
                            })}
                        </Row>
                    </InfiniteScroll>
                    {loading && <div className='loadingPlacesScreen'>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center'
                        

                    }}>
                        <h3 className='text-center'>{t("lookingPlaces")}</h3>
                        <div className='loader-Cont'>
                            <div className='loader loader1'></div>
                            <div className='loader loader2'></div>
                            <div className='loader-Logo'>

                            <img className='d-block' alt='logo' src={logo}></img>
                            </div>
                        </div>
                    </div>
                </div>}
                    </div>

                    <div className='footerCitycont'>
                        <Row className='justify-content-end'>
                            <Col md='8'><p onClick={()=>{onselectCity()}} className='cursor-pointer text-right'>{t('CitySearchQuest')}</p></Col>
                            {/* <Col>
                                <button type='button' className='btnCreateActi'>
                                {t('CitySearchButton')}
                                <div className='emojiCont d-inline-block ml-2'>
                                <img alt="👏" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44f.png"></img>
                                </div>
                                </button>
                            </Col> */}
                        </Row>
                    </div>
                    
                </div>
            </div>
            <div onClick={onClose} className='backgroundPopUp'></div>
        </>
        
    )
}
export default React.memo(CitiesPopUp)
