import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { /*useEffect,*/ useState } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import api from '../../utils/axiosApi'
import Highlighted from '../../utils/Highlighted'
import logo from '../../img/logo.avif'
import './styles.css'
import { useTranslation } from 'react-i18next'
let timeout 

function Index({
    error=false, 
    signUp= false, 
    searchCity,
    style={},
    oncitiesPop=() => {}, 
    onSubmit=() => {}, 
    onCreateZeroCalendar=()=>{},
    onClose=() =>{} }) {
    const [state, setState] = useState({
        show:false,
        loading:true,
    })
    const [places, setPlaces] = useState([])
    const [showSearch, setShow] = useState(false)
    const [numberCities, setNumberCities] = useState(0)
    const [city, setCity] = useState({
        name:'',
        id:''
    })
    const createCalendar = async (e)=>{
        e.preventDefault()
        onCreateZeroCalendar(city)
    }
    const handleChange= async (e, first) => {
        setCity({
            name:e.target.value,
            id:''
        })
        if(e.target.value !== '' || first) {
            if(places.length === 0){
                setState({
                    loading:true
                })
            }
            try {
                setShow(true)
                if(timeout){
                    clearTimeout(timeout)
                }
                timeout = setTimeout(async ()=>{
                    const resp = await api.get(`/ciudades/getcities?city=${e.target.value}`)
                    const {data,error,meta} = resp.data
                    if (error) return error
                    if(meta.query === e.target.value) {
                        setPlaces(data)
                        setNumberCities(meta.totalCities)
                        setState({
                            loading:false
                        })
                    }
                    timeout=undefined
                },200)
            } catch (error) {
                setPlaces([])
                setState({
                    loading:false
                })
                //console.log(error)
            }
        } else {setPlaces([]); 
            setShow(false)
            setState({
            loading:false
        })}
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(city.id === '') {return}
        onSubmit(city.id,city.name)
        setCity({
            name:'',
            id:''
        })
    }/*
    useEffect(() => {
        handleChange({target:{value:''}},true)
        return () => {
            
        }
    }, [])*/
    const { t,i18n } = useTranslation();
    return (
        <>
            <div className='totalPopUpCont d-md-none' style={style}>
                <div className='searchMobilePopUp' onClick={()=>{showSearch && setShow(false)}}>
                <Col className='mt-3'>
                    <div >
                        <Row>
                            <Col className='SearchInfoCont p-0'>
                            <input onChange={handleChange} className='searchinput' value={city.name} placeholder={t('appbarSearchPlaceHolder')} type='text'></input>
                            </Col>
                            <Col xs={2}>
                            <button 
                            style={{fontSize:'15px'}}
                            onClick={onClose} type='button' className=' btnCancelLog'>
                                <FontAwesomeIcon color={`${'#001A72'}`} icon='times'/>
                            </button>
                            </Col>
                        </Row>
                        
                        
                        <Collapse  style={{position: 'absolute', top:'45px', left:0, right:0}} isOpen={(showSearch)}>
                            <ul style={{zIndex: 300, position:'relative', top:0}} className={`optionsSearch ${(places.length===0 && !state.loading ) && 'd-none'}`}> 
                            {(!state.loading) && places.map(e => {
                                let cityString = e.city
                            let country = e.country
                            let state = e.state
                            if(i18n.language==='en') {
                                cityString = e.cityEn
                                country = e.countryEn
                                state = e.stateEn
                            }
                            if(!cityString){
                                cityString = state
                            }
                            /*const citiesnames =cities.map(e=>{return e.city})
                            const firstInd = citiesnames.indexOf(e.city)
                            const lastIndexOf = citiesnames.lastIndexOf(e.city)*/
                            const place = `${cityString}, ${country}`
                            const cityText = cityString? Highlighted({text:cityString, highlight:city.name}) :''
                            //const countrytext = country? Highlighted({text:country, highlight:city.name}) :''
                            const statetext = state? Highlighted({text:state, highlight:city.name}) :''
                            //const showState = (firstInd!==lastIndexOf) 
                            return (
                            <li className='optionAutoComplete optionAutoCompleteCity d-flex' onClick={() => {
                                setCity({
                                    name:place,
                                    id:e._id
                                })
                                setState({
                                    loading:false
                                })
                                setShow(false)
                                }}>
                                {e?.emoji?.url && <div className='emojiCont d-inline-flex justify-content-center align-items-center mr-2'>
                                <img style={{height:'25px'}} alt={e?.emoji?.emoji} src={e?.emoji?.url}></img>
                                </div>}
                                <p className='m-0'>
                                {cityText} <span className='d-block'>{(state!==cityString) && statetext}</span>
                                </p>
                            </li>)
                            })}
                        {(places.length!==0 && !state.loading ) && <li onClick={oncitiesPop} className='optionAutoComplete d-flex justify-content-between align-items-center' style={{textTransform:'none',color:'#156cd5'}}>
                        {t('LandingSeeAll',{nombre: numberCities})} </li> 
                        }
                        {(!state.loading) ? <></> : 
                            <div className='d-flex justify-content-center' style={{color: '#E5E9F2' ,fontSize: '50px', width:'100%', height:'62px', padding: '5px 0'}}>
                            <FontAwesomeIcon spin icon='circle-notch'/>
                        </div>
                        }
                            </ul>
                    </Collapse>
                    </div>
                </Col>
                    <Col className=''>
                        {(places.length===0 && !state.loading ) && 
                        <div className='d-flex justify-content-center align-items-center' 
                        style={{
                            position: 'fixed',
                            top:'91px',
                            left:'0',
                            width:'100vw',

                            }}>
                        <div
                        style={{width:'65%'}}
                        className='noItemsMsg text-center'>
                        {t('noCityMsg')}<img alt='😔' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f614.png' style={{width:'19px'}}/></div>
                        </div>}
                        
                        {(places.length===0 && !state.loading ) && <p className='m-0 noItemsMsg'>
                            {t('searchNoCityMsg')}
                            <div className='ml-2 d-inline-block'><img style={{height:'33px'}} alt='logo' src={logo}></img></div>
                        </p>}
                        
                    </Col>
                    
                    
                        {(places.length===0 && !state.loading ) ?
                        <button 
                        onClick={createCalendar}
                        style={{height:'53px'}} className='btnCrear'>
                            
                        {t('noCitybtn')}
                        <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f4aa.png'></img></div>
                        </button> : 
                        <button 
                        onClick={searchCity? handleSubmit : createCalendar } 
                        style={{height:'53px', fontSize:'20px'}} className='btnCrear'>
                            
                            {t('btnSearchLanding')}
                                
                            
                        </button>
                        }
                </div>
                
            </div>
            
        </>
    )
}

export default Index
