import ReactDOM from "react-dom";
import React from "react";


const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen
) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(!confirmOpen);
    
    //localStorage.setItem('forceReg', 'true')
  };

  document.body.appendChild(container);
  const { 
    header,
    header2,
    content,
    confirm,
    cancel,
     } = JSON.parse(message);
  
ReactDOM.render(
  <div className='swal2-container swal2-center swal2-backdrop-show' style={{position: 'fixed', top:'0', left:'0'}}>
    <div className='confirmName popUpPreventOut animate__animated animate__zoomIn animate__faster'>
        <div class='sweetPopHeader'>
            <h2 class='sweetPopTittle'>
            {header}<div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6a8.png'></img></div>
              <span className='d-block'>{header2} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f631.png'></img></div></span>
            </h2>
            <p class='sweetPopSubTittle'>
            {content}
              <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f4aa.png'></img></div>
            
            </p>
        </div>
        <div className='swal2-actions justify-content-between'>
            <button style={{background:'#106AD4'}} className='swal2-confirm swal2-styled' onClick={handleCancel}>{confirm}
            <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f389.png'></img></div>
            </button>
            <div style={{fontSize:'12px', cursor:'pointer', color:'#106AD4', fontWeight:'500'}} onClick={handleConfirm}>{cancel}
            <div className='emojiCont d-inline-block ml-1 mr-2'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f605.png'></img></div>
            </div>
        </div>
    </div>
  </div>,

  container
);
};

export default UserLeaveConfirmation;