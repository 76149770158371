import React, { useEffect, useState } from 'react'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { setUsersNotifications } from '../../../redux/actions/usersActions';
import { getUserNotifications, getUserUnreadNotifications } from '../../../redux/reducers';
import { socketContext } from '../../../utils/socket';
import api from '../../../utils/axiosApi';
import { withTranslation } from 'react-i18next';
import Routes from '../../../utils/Routes';
import _ from 'lodash';


class NotificationBell extends React.Component {
    static contextType = socketContext;
    constructor(props) {
        super(props)
        this.state={
            listExpanded:false,
            dropdownOpen:false,
            showTour: undefined,
            cityName:'',
            idCalendarNotify:'',
            loading:true,
        }
        this.toggle = this.toggle.bind(this)
    }
    async componentDidMount() {
        const {agencyId} = this.props
        //document.title= i18n.language === 'es'? 'Mis Itinerarios - Tripendar': 'My itineraries - Tripendar'
        if(agencyId){
            try {
                
                const notifications = await api.get(`/agencias/getNotifications/${agencyId}`)
                const { data, error } = notifications.data
                this.props.setUsersNotifications(data)
            } catch (error) {
                
            }
            this.context.emit('joinRoom',agencyId)
            this.context.on('newNotifications',data => {
                this.props.setUsersNotifications(data)
            })
        }
        
        this.setState({loading:false})
    }
    shouldComponentUpdate(nextProps, nextState) {
      return (
        !_.isEqual( nextProps.notifications, this.props.notifications) ||
        nextProps.unreadNotifications !== this.props.unreadNotifications ||
        nextState.dropdownOpen !== this.state.unreadNotifications 
      );
    }
  
    componentWillUnmount() {
        //this.props.clearCalendarList()
        
        this.props.agencyId && this.context.emit('leaveRoom',this.props.agencyId)
        this.context.off('newNotifications')
    }
    toggle = () => {this.setState({dropdownOpen: !this.state.dropdownOpen, listExpanded:false})};
    openNotification = async ({idCalendar})=>{
        

        if(idCalendar || idCalendar===''){
            if(this.props.onOpenNotification) {
                this.props.onOpenNotification(idCalendar)
            }else {
                this.props.history.push(`${Routes.Bussiness('reserve')}?calendar=${idCalendar}`)
            }
        }
        
        const notifications = await api.post(`/agencias/setNotifications/${this.props.agencyId}?calendarIdChat=${idCalendar}`)
        if(notifications.data.error) return
        
        this.props.setUsersNotifications(notifications.data.data)
    }
    
  render() {
    const {t,unreadNotifications,notifications } = this.props
    const {dropdownOpen } = this.state
    return (
      
    <Dropdown  isOpen={dropdownOpen} toggle={this.toggle}>
    <DropdownToggle className='bellCont cursor-pointer' tag="div"

        aria-expanded={dropdownOpen}>
        <FontAwesomeIcon icon='bell'/>
        {(unreadNotifications>0)&&<span className='numberNotificationsBell'>{unreadNotifications}</span>}
    </DropdownToggle>
    <DropdownMenu style={{left:'150px', visibility: `${!dropdownOpen?"hidden":"visible"}`}} className='notificationMenu'>
            
            <Row >
                <Col >
                <h3 className='notificationMenuTitle'>{t("notification")}</h3>
                </Col>
            </Row>

        <div className={`listCont ${this.state.listExpanded? 'listExpanded':''}`}>
            {notifications.map((not,i)=>{
                return <Row key={`notification-${i}`} className={`notificationCont cursor-pointer ${not.unread ? 'notificationContUnread':''}`} onClick={()=>{
                    
                    
                    if(not.type ==='newMsg'){

                    this.openNotification({idCalendar:not.on}); 
                    } else {
                    this.openNotification({idCalendar:not.on}); 

                    }
                    
                    this.toggle()
                    
                    }}>
                {not.unread ?<Col xs='1' className='notificationIcon'>
                    <div className={'greenCircle' }></div>
                </Col>:''}
                <Col className='notificationMsg' xs='8'>
                    {t(not.type)} 🎉
                </Col>
                <Col className='notificationDate'>
                    {moment(not.time).format('MM/DD')}
                </Col>
            </Row>
            })}

            {/*
            <Row className='notificationCont'>
                <Col xs='1' className='notificationIcon'>
                    <div className='greenCircle'></div>
                </Col>
                <Col className='notificationMsg' xs='8'>
                    {t("newReservation")}
                </Col>
                <Col className='notificationDate'>
                    12/51
                </Col>
            </Row>
            <Row className='notificationCont'>
                <Col className='notificationIcon'>
                    <div className='redCircle'></div>
                </Col>
                <Col className='notificationMsg' xs='8'>
                    {t("cancelReservation")}
                </Col>
                <Col className='notificationDate'>
                    12/51
                </Col>
            </Row>
            <Row className='notificationCont'>
                <Col className='notificationIcon'>
                    <div className='greenCircle'></div>
                </Col>
                <Col className='notificationMsg' xs='8'>
                    {t("newReservation")}
                </Col>
                <Col className='notificationDate'>
                    12/51
                </Col>
            </Row>
            *footer */}
        </div>
            <Row>
                <Col className='justify-content-center'>
                    {!this.state.listExpanded ? <p className='seeAllBtn m-0 cursor-pointer'
                    onClick={()=>{
                        
                        this.setState({listExpanded:true})
                        //this.toggle()
                    }}
                    style={{textDecoration: 'none'}}
                    >
                        {t("seeAll")}
                    </p>:''}
                </Col>
            </Row>
        <DropdownItem style={{padding:0}} text>
        {/* <button onClick={openConfig} className='optionsUserbtn' type='button'><div className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f527.png'></img></div> {t('appbarConfig')}</button> */}
        </DropdownItem>

    </DropdownMenu>
    </Dropdown>
    
    )
  }
}


const mapStateToProps = state => {
    return ({
        notifications: getUserNotifications(state),
        unreadNotifications: getUserUnreadNotifications(state),
})}
const mapDispatchToProps = dispatch => (
    {
        setUsersNotifications: value =>dispatch(setUsersNotifications(value)),
    })
export default withRouter(withTranslation()(connect(mapStateToProps,mapDispatchToProps)(NotificationBell)))
