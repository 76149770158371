export let colors= {
  "color-1": '#F2E8FF',
  "color-2": '#DBF9EA',
  "color-3": '#E3F2FF',
  'color-4': '#feebf0',
  "color-5": '#FFF0E8',
  "color-6": '#fffbd6',
  "color-7": '#DBF9EA',
  "color-8": '#E3F2FF',
  'color-9': '#feebf0',
  "transport": '#F2F5F8',//"rgba(70, 159, 213, 1)", negro
}
export let colorsFont= {
  "color-1": '#8F58FE',
  "color-2": '#2AA434',
  "color-3": '#0073DD',
  'color-4': '#BF1A41',
  "color-5": '#FF5757',
  "color-6": '#A74410',
  "color-7": '#2AA434',
  "color-8": '#0073DD',
  'color-9': '#BF1A41',
  "transport": '#000000',//"rgba(70, 159, 213, 1)", negro
}  
export const weekdays = [
  'Lunes', 
  'Martes', 
  'Miercoles', 
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo', 
]
export const startTimeCalendar= 0
export const startTimeActivities= 9
export const weekdaysLng = {
  es:[
    'Lunes', 
    'Martes', 
    'Miercoles', 
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo', 
  ],
  en:[
    'Monday', 
    'Tuesday', 
    'Wednesday', 
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday', 
  ]
}

export let colorsDisp= {
  morado : 'color-1',
  verde : "color-2",
  azul : "color-3", 
  rojo : "color-4",
  rosa : "color-5", 
  marron : "color-6",
}
export const typetravel ={
  car:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f697.png",
  train:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f683.png",
  bicycle:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6b2.png",
  walking:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6b6-200d-2642-fe0f.png",
  boat:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6e5-fe0f.png",
  /*airplane:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6eb.png",
  rocket:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f680.png",
  alien:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f6f8.png",
  rollerCoaster:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f3a2.png",
  horse:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f40e.png",
  ant:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f41c.png",
  imagination:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f9e0.png",*/
  custom:"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/2753.png",
}
export const namesTypetrip ={
  es:{
    car: 'En carro',
    train: 'Transporte publico',
    bicycle: 'En bicicleta',
    walking: 'Caminando',
    boat: 'En bote',
    /*airplane:"En avión",
    rocket:"En cohete",
    alien:"En O.V.N.I",
    rollerCoaster:"En montaña rusa",
    horse:"En un caballo",
    ant:"En una hormiga gigante",
    imagination:"En tus sueños",*/
    custom:"Personalizado",
  },
  en:{
    car: 'By car',
    train: 'Public transport',
    bicycle: 'By bike',
    walking: 'Walking',
    boat: 'By boat',
    /*airplane:"By plane",
    rocket:"By rocket",
    alien:"By U.F.O",
    rollerCoaster:"By roller coaster",
    horse:"By Horse",
    ant:"By giant ant",
    imagination:"in Your Dreams",*/
    custom:"Custom",
  },
}
export const categories={
  es:{
    tips:"Travel tips",
    news:"News",
    curiosities:"Curiosities",
    destination:"Destinations",
    whereStay:"Where to stay"
  },
  en:{
    tips:"Travel tips",
    news:"News",
    vuriosities:"Curiosities",
    destination:"Destinations",
    whereStay:"Where to stay"
  },
}
export const diccionarioCurrency ={
  USD: '$',
  EUR: '€'
}
export const cardsTypes = ["MASTERCARD", "VISA", "MAESTRO", "AMEX", "EURO6000", "JCB", "DINERS", "SWITCH", "SOLO_GB", "AIRPLUS", "EURO6000", "CHINAUNIONPAY", "DISCOVER"]

export const languageDic={
  es:'CAS',
  en:'ENG'
}

