import { combineReducers } from "redux";
import { calendarList } from "./calendarList";
import { calendar } from "./calendar";
import { user } from "./user";
import { adminPlaces } from "./adminPlaces";
import { usersSocket } from "./usersSocket";

export default combineReducers({
  calendar,
  user,
  calendarList,
  adminPlaces,
  usersSocket,
});

export const getUserToken = (state) => {
  const token =
    state.user.token ||
    window.sessionStorage.getItem("jwtuser") ||
    localStorage.getItem("jwtuser");
  return token;
};
export const getUserNotifications = (state) => {
  return state.user.notifications;
};
export const getUserUnreadNotifications = (state) => {
  return state.user.UnreadNotifications;
};
export const getUserShopCarList = (state) => {
  return state.user.list;
};
export const getplacesList = (state) => {
  return state.user.places;
};
export const getmarkPlacesList = (state) => {
  return state.user.markPlaces;
};
export const getNItemsList = (state) => {
  return state.user.Nitems;
};

export const getCalendarList = (state) => {
  return state.calendarList.list;
};
export const getCalendarInvList = (state) => {
  return state.calendarList.invList;
};
export const getCalendarListCount = (state) => {
  return state.calendarList.Nitems;
};

export const getUsersSocket = (state) => {
  return state.usersSocket.users;
};
export const getUsersMsg = (state) => {
  return state.usersSocket.msgs;
};

export const getIdCalendar = (state) => {
  return state.calendar.id;
};
export const getPlaces = (state) => {
  return state.calendar.toDolist;
};
export const getTours = (state) => {
  return state.calendar.toDoTourslist;
};
export const getIsdefaultToDoList = (state) => {
  return state.calendar.defaultToDoList;
};
export const getweather = (state) => {
  return state.calendar.weather;
};
export const getAgendaPlaces = (state) => {
  return state.calendar.agendaList;
};
export const getPrevAgendaPlaces = (state) => {
  return state.calendar.prevAgendaList;
};
export const getAgendadays = (state) => {
  return state.calendar.dayAgenda;
};
export const getAgendaDaysMarkers = (state) => {
  return state.calendar.itemsByDaysMarkers;
};
export const getpublic = (state) => {
  return state.calendar.public;
};
export const getIsLoading = (state) => {
  return state.calendar.isLoading;
};
export const getIsErrorLoad = (state) => {
  return state.calendar.errorLoad;
};
export const getName = (state) => {
  return state.calendar.name;
};
export const getCalendarHotel = (state) => {
  return state.calendar.hotel;
};
export const getUnregistercustomers = (state) => {
  return {
    editors: state.calendar.emailsEditInv,
    viewer: state.calendar.emailsViewInv,
  };
};
export const getDataUser = (state) => {
  return state.calendar.dataUser;
};
export const getusers = (state) => {
  return state.calendar.users;
};
export const getinv = (state) => {
  return state.calendar.inv;
};
export const getisModel = (state) => {
  return state.calendar.model;
};
export const getCity = (state) => {
  return state.calendar.city;
};
export const getCityName = (state) => {
  return state.calendar.cityName;
};
export const getCityNameEn = (state) => {
  return state.calendar.cityNameEn;
};
export const getCityEmoji = (state) => {
  return state.calendar.cityEmoji;
};
export const getCityDataCoor = (state) => {
  return state.calendar.cityDataCoor;
};
export const getMaxDate = (state) => {
  return state.calendar.maxDate;
};
export const getMinDate = (state) => {
  return state.calendar.minDate;
};
export const getStartDate = (state) => {
  return state.calendar.startDate;
};
export const getStartAtTime = (state) => {
  return state.calendar.startAtTime;
};

export const getAdminPlacesList = (state) => {
  return state.adminPlaces.places;
};
export const getAdminPlacesListPage = (state) => {
  return state.adminPlaces.page;
};
export const getNAdminPlacesList = (state) => {
  return state.adminPlaces.Nitems;
};
