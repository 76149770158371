import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function ChangeLngBtn({
  className = "",
  direction = "down",
  dropdownClassName = "",
}) {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const changeLanguage = (lng) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
    toggle();
  };
  //localStorage.getItem("lang")===""
  let flag =
    i18n.language === "es"
      ? "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1f8.png"
      : "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1f8.png";

  return (
    <>
      <Dropdown
        direction={direction}
        className={"langBtn " + className}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle
          className="titleUser pr-2"
          tag="div"
          aria-expanded={dropdownOpen}
        >
          <img src={flag} alt="en" width="17" height="17"></img>
          <div
            className="setTransition mt-0"
            style={{
              transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
              marginLeft: "10px",
            }}
          >
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 0H0L4.5 6L4.56429 5.93684L9 0Z" fill="#00264D" />
            </svg>
          </div>
        </DropdownToggle>
        <DropdownMenu className={`langBtnDropCont ${dropdownClassName}`}>
          <DropdownItem style={{ padding: 0 }} text>
            <button
              className="optionsUserbtn"
              onClick={() => changeLanguage("en")}
            >
              <img
                src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1fa-1f1f8.png"
                alt="en"
                width="17"
                height="18"
              ></img>{" "}
              English
            </button>
          </DropdownItem>

          <DropdownItem style={{ padding: 0 }} text>
            <button
              className="optionsUserbtn"
              onClick={() => changeLanguage("es")}
            >
              <img
                src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f1ea-1f1f8.png"
                alt="es"
                width="17"
                height="18"
              ></img>{" "}
              Español
            </button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ChangeLngBtn;
