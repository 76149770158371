import _ from "lodash"

 const Highlighted = ({text = '', highlight = ''}) => {
    if (!highlight.trim()) {
      return <>{text}</>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight.trim())})`, 'gi')
    const parts = text.split(regex)
    return (

         parts.filter(part => part).map((part, i) => (
             regex.test(part) ? <div className='textResalt' key={i}>{part}</div> : <>{part}</>
         ))
    )
 }
 export default Highlighted