import React, { Component } from "react";
import { withRouter } from "react-router";
import AppBar from "../components/AppBar";
import { Toast } from "../utils/sweetalert";
import withReactContent from "sweetalert2-react-content";
import { setLoginUser, setSignout, setSignupUser } from "../redux/actions/usersActions";
import { getUserToken } from "../redux/reducers";
import { connect } from "react-redux";
import LoginUser from "../components/LoginUser";
import api from "../utils/axiosApi";
import jwtDecode from "jwt-decode";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import InputsCopyModel from "../components/InputsCopyModel";
import moment from "moment";
import Routes from "../utils/Routes";
//import { getRandomColor } from '../utils/getRandomColor';
class CopyModelAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showCitiesPop: false,
      showQrPop: false,
      showSearchMobile: false,
      signUp: false,
    };
    this.handleCopyModelAgency = this.handleCopyModelAgency.bind(this);
  }
  componentDidMount() {
    document.title = "Tripendar";
  }
  handleCopyModelAgency = async (data) => {
    const { t, history } = this.props;

    if (!data.start) {
      return;
    }

    const { search } = history.location;

    const values = queryString.parse(search);
    let id = "";
    if (values.model) {
      const calendar = {
        start: data.start.replace(/-/g, "/"),
        users: [],
        inv: [],
        dataUser: {
          name: data?.name || "",
          lastName: data?.lastname || "",
          email: data?.email || "",
          telephone: data?.tel || "",
          DNI: data?.dni || "",
          dateBirth: data?.date?.replace(/-/g, "/") || "",
          nationality: data?.nacion || "",
          direction: data?.direction || "",
          numberUsers: data?.number || 1,
        },
      };
      try {
        const resp = await api.post(
          `/ModelsCalendar/clone/${values.model}`,
          calendar
        );
        if (resp.data.error) return;
        id = resp.data.data._id;
      } catch (error) {
        console.log(error);
      }
    } else {
      const msgCalendar = await api.get(
        `/agencias/getAgency/${this.props.match.params.idAgency}`
      );
      const calendar = {
        name: t("myCalendar"),
        city: undefined,
        startDate: moment(data.start, "YYYY-MM-DD").toDate(),
        minDate: moment(data.start, "YYYY-MM-DD").subtract(1, "day").toDate(),
        maxDate: moment(data.start, "YYYY-MM-DD").add(2, "years").toDate(),
        startAtTime: 6,
        agendaItems: [],
        agency: msgCalendar.data.data?._id,
        toDolist: [],
        inv: [],
        dataUser: {
          name: data?.name || "",
          lastName: data?.lastname || "",
          email: data?.email || "",
          telephone: data?.tel || "",
          DNI: data?.dni || "",
          dateBirth: data?.date?.replace(/-/g, "/") || "",
          nationality: data?.nacion || "",
          direction: data?.direction || "",
          numberUsers: data?.number || 1,
        },
      };
      try {
        let users = [];
        if (this.props.token) {
          const decUser = jwtDecode(this.props.token);
          users = [decUser.user._id];
        }
        const calendarUser = { ...calendar, users: users };
        const resp = await api.post(`/agencyCalendar/set`, calendarUser);
        id = resp.data.data._id;
      } catch (error) {
        console.log(error);
      }
    }
    this.props.history.push(
      Routes.agencyCalendar(this.props.match?.params?.idAgency, id)
    );
  };
  handleLoginCustomer = async (data) => {
    try {
      /*const MyToast = withReactContent(Toast)*/
      const respo = await this.props.setLoginUser(data);
      if (this.props.token !== "") {
        return true;
      } else {
        return respo;
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleSignUpCustomer = async (data) => {
    try {
      const { t, i18n } = this.props;
      const MyToast = withReactContent(Toast);
      let respSignUp;
      if (data.accept) {
        respSignUp = await this.props.setSignupUser({
          payload: data,
          len: i18n.language,
        });
      }
      if (respSignUp && respSignUp.email) {
        await MyToast.fire({
          title: (
            <p style={{ fontSize: "22px", zIndex: 30000 }}>
              {t("registeredMail")}
            </p>
          ),
          icon: "error",
        });
      } else if (this.props.token !== "") {
        return;
      } else {
        await MyToast.fire({
          title: (
            <p style={{ fontSize: "22px", zIndex: 30000 }}>{t("errorToast")}</p>
          ),
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className="bckColor"
        style={{
          height: "100vh",
          width: "100vw",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          className="principalBackground setPadding"
          style={{ justifyContent: "start", alignItems: "start" }}
        >
          <AppBar
            disableSearchBar
            istoken={this.props.token}
            oncitiesPop={() => {
              this.setState({
                showCitiesPop: true,
              });
            }}
            showMobileSearch={this.state.showSearchMobile}
            setShowMobile={(a) => {
              this.setState({ showSearchMobile: a });
            }}
            onCreateZeroCalendar={this.handleCopyModelAgency}
            onSignOut={this.props.setSignout}
            showSignUp={this.state.show}
            onClose={() => {
              this.setState({
                show: false,
                signUp: false,
              });
            }}
            onRegister={() => {
              this.setState({
                show: true,
                signUp: true,
              });
            }}
            onLogin={() => {
              this.setState({ show: true, signUp: false });
            }}
          ></AppBar>
          <InputsCopyModel
            onSubmit={this.handleCopyModelAgency}
          ></InputsCopyModel>
          {this.state.show && (
            <LoginUser
              signUp={this.state.signUp}
              onLogin={this.handleLoginCustomer}
              onSignUp={this.handleSignUpCustomer}
              onClose={() => {
                this.setState({
                  show: false,
                  signUp: false,
                });
              }}
            />
          )}
          {/* <Button className='doubtBtn' onClick={this.handleDoubt}>{t('iHaveDoubt')}
                </Button> */}
        </div>
      </div>
    );
  }
}
/*
<LandingBack></LandingBack>

*/

const mapStateToProps = (state) => {
  return {
    token: getUserToken(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLoginUser: (value) => dispatch(setLoginUser(value)),
  setSignupUser: (value) => dispatch(setSignupUser(value)),
  setSignout: (value) => dispatch(setSignout(value)),
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CopyModelAgency))
);
