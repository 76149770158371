import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const nav = navigator.language || navigator.userLanguage
let lng= localStorage.getItem("lang") || nav?.substring(0,2)

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: lng,
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es'];

export default i18n;