
import React, { useEffect, useState, lazy } from 'react'
import {  Col, Row } from 'reactstrap'
import withReactContent from 'sweetalert2-react-content'
import api from '../../utils/axiosApi'
import { option } from '../../utils/sweetalert'
import { emailSchema, nameSchema, passwordSchema } from '../../utils/validateForm'
import ConfirmEmailPop from '../ConfirmEmailPop'
import LoginInput from './LoginInputs'
import RecoveryPass from './RecoverPass'
//import SignUpInputs from './SignUpInputs'
import { useTranslation } from 'react-i18next'
import './styles.css'

const SignUpInputs = lazy(() => import('./SignUpInputs'));

const signupValidateObj = {
    userEmail: (e) => emailSchema.validate(e),
    userPass: (e) => passwordSchema.validate(e),
    userRepass:(e) => passwordSchema.validate(e),
    userName:(e) => nameSchema.validate(e),
    userLastname:(e) => nameSchema.validate(e),
}
const loginValidateObj = {
    userEmail: (e) => emailSchema.validate(e),
    userPass: (e) => passwordSchema.validate(e),
}

function LoginUser({
    error=false, 
    signUp= false, 
    style={},
    children,
    disableBack,
    onSignUp=() => {}, 
    onLogin=() => {}, 
    onEndRegister=() => {}, 
    onClose=() =>{} }) {
    const [isSignUp, setIsSignUp] = useState(signUp)
    const [data, setData] = useState({
        userEmail: '',
        userPass: '',
        userRepass:'',
        userName:'',
        userLastname:'',
        remenber:true,
        //cambiar luego
        accept:true,
    })
    const [repeatPassError, setRepeatPassError] = useState(false)
    const [isError, setIsError] = useState(error)
    const [terminosDeUsoError, setTerminosDeUsoError] = useState(false)
    const [emailExist, setEmailExist] = useState(false)
    const [confirmEmail, setConfirmEmail] = useState(false)
    const [recoverPass, setRecoverPass] = useState(false)
    const [emailFormat, setEmailFormat] = useState(false)
    const [emptyForm, setEmptyForm] = useState(false)
    
    const { t } = useTranslation();
    useEffect(() => {
        setIsSignUp(signUp)
        return () => {
            
        }
    }, [signUp])
    useEffect(() => {
        setData({
            userEmail: '',
            userPass: '',
            userRepass:'',
            userName:'',
            userLastname:'',
            remenber:true,
            //cambiar luego
            accept:true,
        })
        setIsError(false);
        return () => {
            
        }
    }, [isSignUp])
    useEffect(() => {
        if((data.userPass !== data.userRepass) && isSignUp) {
            setIsError(true)
            setRepeatPassError(true)
        } else {
            
            setIsError(false)
            setRepeatPassError(false)
        }
        return () => {
            
        }
    }, [data.userPass, data.userRepass,isSignUp])
    const handleValidSignup = async (e) => {
        let err= false
        setEmailFormat(false);
        setEmptyForm(false);
        
        for(const [key, value] of Object.entries(signupValidateObj)){
            const validate = value(data[key])
            if(validate.error && !err) {             
                if(key==='userName'|| key==='userLastname'|| key==='userPass'|| key==='userRepass' ){
                    setEmptyForm(true);
                }
                if(key==='userEmail'){
                    setEmailFormat(true);
                }                
                err = (true)
            }
        }

        const resExist = await api.get(`/user/exist?email=${data.userEmail}`)
          
          if(!data.accept) {
            setTerminosDeUsoError(true)
          } else if(terminosDeUsoError) {
            setTerminosDeUsoError(false)
          }
          if(data.userPass !== data.userRepass){
            setRepeatPassError(true)
          }else if(repeatPassError){
            setRepeatPassError(false)
          }
        if(resExist.data.data) {
            setEmailExist(true)
        } else if(emailExist) {
            setEmailExist(false)
        }
          if(data.userPass !== data.userRepass || !data.accept  || resExist.data.data) {
            err = (true)
        }
        return err
    }
    const handleValidLogin = async (e) => {
        let err = false
        for(const [key, value] of Object.entries(loginValidateObj)){
            const validate = value(data[key])
            if(validate.error && !isError) {
                err = (true)
            }
        }
        return err
    }
    const handleLogin = async () => {
        const objUser = {
            email: data.userEmail,
            password: data.userPass,
            remenber: data.remenber
        }

        //aca
        setEmailExist(false)
        setEmptyForm(false)
        setTerminosDeUsoError(false)
        setEmailFormat(false)
        
        const err = await handleValidLogin()
        if(err) {

            setIsError(err)
        } else {
            const resp= await onLogin(objUser)
            
            if(resp) {
                !disableBack && onClose()                
                onEndRegister()
            } else {
                
                setIsError(true)
            }
        }

    }
    const handleSignUp = async () => {
        const objUser = {
            name: `${data.userName}`,
            lastName: `${data.userLastname}`,
            email: data.userEmail,
            password: data.userPass,
            repassword: data.userRepass,
            remenber: true,
            accept: data.accept
            }
            await onSignUp(objUser,false)
            if(!disableBack){
                onClose()
                const MySwal = withReactContent(option)
                await MySwal.fire({
                    background:'#fff',
                    customClass:'confirmName',
                    confirmButtonText: '<img style="height:19px" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44d.png"></img>',
                    html:`
                    <div class='sweetPopHeader'>
                        <h2 style='font-size: 22px' class='sweetPopTittle'>Yessss
                        <div class='emojiCont d-inline-block ml-1'><img style='height:19px' alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f64c.png'></img></div>
                        </h2>
                        <p class='sweetPopSubTittle'>${t('signUpPopUpMsg')}</p>
                    </div>
                    `,
                    confirmButtonColor:'#106AD4',
                    focusConfirm: false,
                    })
                    onEndRegister()
            }
            
            
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(isSignUp) {
            //await handleSignUp()
            const err = await handleValidSignup()
            if(err) {
                setIsError(err)
            } else {
                setConfirmEmail(true)
            }
        } else {
            await handleLogin()
        }
    }
    const handleChange = (e) => {
        const {value,name} = e.target
        if(isError) setIsError(false);
        if(name=== 'remenber' || name=== 'accept') {
            setData({...data,
            [name]: value==='true'
            })
            return
        }
        setData({...data,
            [name]: value
            })
    }
    return (
        <>
            <div className='popUpBodyCont' style={style}>
            {(!confirmEmail && !recoverPass ) && <form onSubmit={handleSubmit} className='popUpLogSignCont'>
                    <Col className='headerPopUp '>
                        
                        {isSignUp ? <>
                            <Row className='justify-content-center'>
                            <h2>{t('signUpTitle')} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/270d-fe0f.png'></img></div></h2>
                            </Row>
                            <Row className='mb-1'>
                                <Col className='d-flex align-items-end' xs={8}><p>{t('signUpSubTitle')}</p></Col>
                                <Col className='p-0' xs={4}><span onClick={() => {setIsSignUp(!isSignUp)}}>{t('appbarLogin')}</span></Col>
                            </Row>
                        </> : <>
                            <Row className='justify-content-center'>
                            <h2> {t('loginTitle')} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44b.png'></img></div></h2>
                            </Row>
                            <Row className='mb-4'>
                                <Col className='d-flex align-items-end' xs={8}><p>{t('loginSubTitle')}</p></Col>
                                <Col className='p-0' xs={4}><span onClick={() => {setIsSignUp(!isSignUp)}}>{t('appbarSignUp')}</span></Col>
                            </Row>
                        </>}
                    </Col>
                    <Col className='headerBody'>
                        
                        
                        {isSignUp ? <SignUpInputs 
                        onChange={handleChange}
                        userEmail={data.userEmail}
                        userName={data.userName}
                        userLastname={data.userLastname}
                        userPass={data.userPass}
                        userRepass={data.userRepass}
                        accept={data.accept}
                        /> : <LoginInput 
                        setRecoveryPass={()=>{setRecoverPass(true)}}
                        onChange={handleChange}
                        userEmail={data.userEmail}
                        userPass={data.userPass}
                        remenber={data.remenber}
                        error={isError}

                        />}
                    </Col>
                    
                    {isError && <div className='mt-1 mb-2'> 
                        {!terminosDeUsoError && <p className='pl-3 errormsg m-0'>{isSignUp  ? ` ${repeatPassError? t('signUpErrorPass'):''}`: ''}</p>}
                        <p className='pl-3 errormsg m-0'>{terminosDeUsoError && t('signUpErrorTerms')}</p>
                        <p className='pl-3 errormsg m-0'>{emailExist && t('signUpErrorEmailRegister')}</p>
                        <p className='pl-3 errormsg m-0'>{emailFormat && t('signUpErrorEmailnotValid')}</p>
                        <p className='pl-3 errormsg m-0'>{emptyForm && t('signUpError')}</p>
                    </div>}
                    <Row className='pl-4 pr-4'>
                        <Col>
                        <button onClick={onClose} type='button' className=' btnCancelLog'>
                            {t('signUpCancel')}
                        </button>
                        </Col>
                        <Col>
                        <button className='btnCrear'>
                            {isSignUp ? <>{t('signUpGoBtn')}
                                <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f4aa.png'></img></div>
                            </>: t('appbarLogin')}
                        </button>
                        </Col>
                        
                    </Row>
                </form>}
                
                {(confirmEmail && !recoverPass ) && <>
                <div className='popUpLogSignCont popUpForceWidth'>
                        
                        <ConfirmEmailPop
                        email={data.userEmail}
                        isError={isError}
                        onClose={onClose}
                        onFailure={handleSignUp}
                        onSuccess={handleSignUp}
                        />
                    </div>
                </>}
                {(recoverPass ) && <>
                <div className='popUpLogSignCont popUpForceWidth'>
                        
                        <RecoveryPass
                        isError={isError}
                        onClose={onClose}
                        />
                    </div>
                </>}
            </div>
            {!disableBack &&  <div onClick={() => {onClose()}} className={'backgroundPopUp d-none d-md-block'}></div>}
        </>
        
    )
}

export default LoginUser

