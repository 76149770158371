import React from 'react'
import { Col, Row } from 'reactstrap'
import './styles.css'
import red_signal from '../../img/red_signal.avif'
import { useTranslation } from 'react-i18next';

export default function FooterPrincipal() {
    const { t } = useTranslation();
    return (
        <div className='footerCont d-flex'>{/*
            <Row >
                <Col className='footerBcont' xs={12}  md='auto'><a href='#l'> {t("terms")}</a></Col>
                <div className='line'> </div>
                <Col className='footerBcont' xs={12}  md='auto'><a href='#l'> {t("privacyPolities")}</a></Col>
                
            </Row>
            <div>
                <a href='#l'><img src={red_signal} alt='red_signal'></img>{t("areYouAdmin")}</a>
    </div>}*/}
        </div>
    )
}
