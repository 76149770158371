import {SET_USERS_MSG_SOCKET, SET_USERS_SOCKET} from '../const'

export const usersSocket = (state = {},action) =>{
    switch (action.type) {
        case SET_USERS_MSG_SOCKET:
            return {...state, msgs: action.value};
        case SET_USERS_SOCKET:
            return {...state, users: action.value};
        default:
            return state;
    }
}