import React, {useState} from 'react'
import UserCircle from '../UserCircle'
import jwt_decode from 'jwt-decode'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Container } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import "./styles.css"

function UserCircleLog({
    simpleLogin= false,
    disableLogin,
    fadeinitial = 0,user, 
    onLogin = () => {}, 
    openConfig = () => {}, 
    onRegister = () => {}, onSignOut = () => {}}) {
    const [fade, setFade] = useState(fadeinitial)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const ShowPopUp = () => {
        fade === 0 ? setFade(1)
        :setFade(0)
    }
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const { t } = useTranslation();
    const userLog = user ? <> 
    
    <Dropdown className='landingWhite'  isOpen={dropdownOpen} toggle={toggle}>
    <DropdownToggle className='titleUser pr-2' tag="div"

        aria-expanded={dropdownOpen}>
        <UserCircle className='text-uppercase' borderGray action={ShowPopUp} diametro='30px'>
        {user.name?.split(' ')[0][0]}{user?.lastName?.split(' ')[0][0]}
        </UserCircle>
        <Col className='pl-0'>
            <p className='userDes text-truncate ml-2'>{user?.name.split(" ")?.[0]}</p>
        </Col>
        <div className='setTransition' style={{transform:dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}>
            <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0H0L4.5 6L4.56429 5.93684L9 0Z" fill="#00264D"/>
            </svg>
        </div>
    </DropdownToggle>
    <DropdownMenu style={{left:'150px'}} className='optionCont'>
        <DropdownItem style={{padding:0}} text>
        <button disabled={disableLogin} onClick={openConfig} className='optionsUserbtn' type='button'><div className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f527.png'></img></div> {t('appbarConfig')}</button>
        </DropdownItem>

        <DropdownItem style={{padding:0}} text >
        <button disabled={disableLogin} onClick={() => {onSignOut()}} className='optionsUserbtn' type='button'> <div className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/270c-fe0f.png'></img></div>  {t('appbarLogOut')}</button>
        </DropdownItem>
    </DropdownMenu>
    </Dropdown>
    </>: 
    <>
    <Container className='pl-0 landingWhite'>
    


        {simpleLogin ? <>
        <Row className='m-0  w-100'>
            <Col xs='12' className='p-0'><p className='mt-1 mb-1 signuptitle'>{t('appbarUserLogMsg')}</p></Col> 
            <Col className='p-0 '><p className='m-0 text-left signupcoment w-100' style={{display:'block'}}>
            <Trans i18nKey={'appbarUserLogSubMsg'}>
                <span className={`loginbtn d-inline-block ${disableLogin && 'loginSignbtnDisabled'}`} xs={4} onClick={!disableLogin ? onLogin: ()=>{}}></span> 
                <span className={`ml-1 signupbtn d-inline-block ${disableLogin && 'loginSignbtnDisabled'}`} xs={3} onClick={!disableLogin ? onRegister: ()=>{}}></span>
            </Trans>
            </p>
            </Col>
        </Row>
        </> : <Row className='m-0 justify-content-around  w-100'>
            <Col className='p-0 signupbtnSimple ' xs={5} onClick={onLogin}><div><p className='m-0'>{t('appbarLogin')}</p></div></Col>
            <Col className='p-0 loginbtnSimple ' xs={5} onClick={onRegister}><div><p className='m-0'>{t('appbarSignUp')}</p></div></Col>
        </Row>}
    </Container>
    </>
    return userLog
    
}
export default React.memo(UserCircleLog)
