import html2canvas from "html2canvas";
import jsPDF from "jspdf";


const exportAsImage = async (el, imageFileName='download') => {
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    download(image, imageFileName);
    };
    
const download = (blob, fileName) => {
const fakeLink = window.document.createElement("a");
fakeLink.style = "display:none;";
fakeLink.download = fileName;

fakeLink.href = blob;

document.body.appendChild(fakeLink);
fakeLink.click();
document.body.removeChild(fakeLink);

fakeLink.remove();
};
export const exportImgAsPDF = async (el, imageFileName) => {
    let doc = new jsPDF("p", "mm", "a4");
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    doc.addImage(image, 'png',0,0,width,height);
    doc.save('QR.pdf');
    };
    

export default exportAsImage;