import _ from "lodash";
import moment from "moment";
import api from "../axiosApi";
import { reversetravel, travelMode } from "../fixItems";
import { SortDateStart } from "../sortDate";
import { findActualTemporada } from "./findDaysRows";

/**
 * this function get a date and it give a format hour and day
 * @param {Date} date the date that going to get the time formated
 * @returns {String}
 */
export function formatHours(date) {
  //
  let [hours, minutes] = moment(date)
    .format("HH:mm")
    .split(":")
    .map((e) => parseInt(e));
  let day = moment(date).format("YYYY/MM/DD");
  let finalTime;
  if (0 <= minutes && minutes <= 8) {
    finalTime = `${hours}:00`;
  } else if (8 < minutes && minutes <= 23) {
    finalTime = `${hours}:15`;
  } else if (23 < minutes && minutes <= 38) {
    finalTime = `${hours}:30`;
  } else if (38 < minutes && minutes <= 53) {
    finalTime = `${hours}:45`;
  } else if (53 < minutes && minutes < 60) {
    hours = hours + 1;
    finalTime = `${hours}:00`;
    if (hours >= 24) {
      finalTime = `00:00`;
      day = moment(day).add(1, day).format("YYYY/MM/DD");
    }
  }
  return {
    day,
    time: finalTime,
  };
}

class CalendarMotorError extends Error {
  constructor(error, code = 500) {
    if (error.message) {
      super(error.message);
    } else {
      super(error);
    }
    this.name = "CalendarMotorError";
    this.code = code;
  }
}

/**
 * Abstraction class to check calendars behaviors.
 */
class CalendarMotor {
  #cellHeight = 15;
  #virtualCalendar;
  #listDic = {};
  #daysDic = {};
  #hoursDic = {
    "00:00": 0,
    "00:15": 1,
    "00:30": 2,
    "00:45": 3,
    "01:00": 4,
    "01:15": 5,
    "01:30": 6,
    "01:45": 7,
    "02:00": 8,
    "02:15": 9,
    "02:30": 10,
    "02:45": 11,
    "03:00": 12,
    "03:15": 13,
    "03:30": 14,
    "03:45": 15,
    "04:00": 16,
    "04:15": 17,
    "04:30": 18,
    "04:45": 19,
    "05:00": 20,
    "05:15": 21,
    "05:30": 22,
    "05:45": 23,
    "06:00": 24,
    "06:15": 25,
    "06:30": 26,
    "06:45": 27,
    "07:00": 28,
    "07:15": 29,
    "07:30": 30,
    "07:45": 31,
    "08:00": 32,
    "08:15": 33,
    "08:30": 34,
    "08:45": 35,
    "09:00": 36,
    "09:15": 37,
    "09:30": 38,
    "09:45": 39,
    "10:00": 40,
    "10:15": 41,
    "10:30": 42,
    "10:45": 43,
    "11:00": 44,
    "11:15": 45,
    "11:30": 46,
    "11:45": 47,
    "12:00": 48,
    "12:15": 49,
    "12:30": 50,
    "12:45": 51,
    "13:00": 52,
    "13:15": 53,
    "13:30": 54,
    "13:45": 55,
    "14:00": 56,
    "14:15": 57,
    "14:30": 58,
    "14:45": 59,
    "15:00": 60,
    "15:15": 61,
    "15:30": 62,
    "15:45": 63,
    "16:00": 64,
    "16:15": 65,
    "16:30": 66,
    "16:45": 67,
    "17:00": 68,
    "17:15": 69,
    "17:30": 70,
    "17:45": 71,
    "18:00": 72,
    "18:15": 73,
    "18:30": 74,
    "18:45": 75,
    "19:00": 76,
    "19:15": 77,
    "19:30": 78,
    "19:45": 79,
    "20:00": 80,
    "20:15": 81,
    "20:30": 82,
    "20:45": 83,
    "21:00": 84,
    "21:15": 85,
    "21:30": 86,
    "21:45": 87,
    "22:00": 88,
    "22:15": 89,
    "22:30": 90,
    "22:45": 91,
    "23:00": 92,
    "23:15": 93,
    "23:30": 94,
    "23:45": 95,
  };
  #criticsPointsOverlaping = [];
  #criticsPointsTransports = [];
  /*
   */

  constructor(data) {
    data = SortDateStart(_.cloneDeep(data));
    if (data.length !== 0) {
      this.getVirtualCalendarEmpty(
        moment(data[0].startDateTime).toDate(),
        moment(data[data.length - 1].endDateTime).toDate()
      );
    } else {
      this.#virtualCalendar = [new Array(96)];
    }
    data.forEach((element) => {
      this.SetOneActivityInCalendar(element);
    });
    //console.log('fromConstructor',_.cloneDeep(this.#virtualCalendar))
  }

  /**
   *
   * @param {Date} start the start day of the calendar that will be the third date of the virtual calendar
   * @param {Date} end the end day of the calendar that will be the third date of the virtual calendar
   */
  getVirtualCalendarEmpty(start = Date(), end = Date()) {
    const startCalendar = moment(start)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .subtract(5, "day"),
      endCalendar = moment(end)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .add(5, "day");

    const newDaysDic = {};
    const virtualCalendar = [];

    const numberOfDays = Math.abs(endCalendar.diff(startCalendar, "days")) + 2;
    const day = moment(startCalendar).subtract(1, "day");

    for (let i = 0; i <= numberOfDays; i++) {
      const calendarDay = moment(day).add(i, "day");
      newDaysDic[calendarDay.format("YYYY/MM/DD")] = i;
      virtualCalendar.push(new Array(96));
    }
    this.#virtualCalendar = virtualCalendar;
    this.#daysDic = newDaysDic;
    return virtualCalendar;
  }

  SetOneActivityInCalendar(element = {}) {
    const formatStart = formatHours(element.startDateTime),
      formatEnd = formatHours(element.endDateTime);

    const timeIterator = moment(
        `${formatStart.day} ${formatStart.time}`,
        "YYYY/MM/DD HH:mm"
      ),
      timeEnd = moment(
        `${formatEnd.day} ${formatEnd.time}`,
        "YYYY/MM/DD HH:mm"
      );
    let cells = 0;
    while (timeIterator < timeEnd) {
      const selectorHour = timeIterator.format("HH:mm"),
        selectorDay = timeIterator.format("YYYY/MM/DD");
      if (
        this.#virtualCalendar[this.#daysDic[selectorDay]][
          this.#hoursDic[selectorHour]
        ] &&
        !this.#virtualCalendar[this.#daysDic[selectorDay]][
          this.#hoursDic[selectorHour]
        ].includes(element._id)
      ) {
        this.#virtualCalendar[this.#daysDic[selectorDay]][
          this.#hoursDic[selectorHour]
        ].push(element._id);
        this.#criticsPointsOverlaping.push({
          dayIndex: this.#daysDic[selectorDay],
          hourIndex: this.#hoursDic[selectorHour],
        });
      } else {
        this.#virtualCalendar[this.#daysDic[selectorDay]][
          this.#hoursDic[selectorHour]
        ] = [element._id];
      }
      cells++;
      this.#listDic[element._id] = { ...element };
      if (!this.#listDic[element._id].meta) {
        this.#listDic[element._id].meta = {};
      }
      if (element.customTravel) {
        this.#listDic[element._id].typesTravels = [];
      } else {
        this.#listDic[element._id].typesTravels = this.#listDic[element._id]
          .typesTravels || ["car", "walking", "train", "bicycle"];
      }
      this.#listDic[element._id].meta.numberCells = cells;
      timeIterator.add(this.#cellHeight, "minutes");
    }
  }
  processOverlapingCalendar() {
    const copyVirtualCalendar = _.cloneDeep(this.#virtualCalendar);
    //console.log('fromOverlaping:',copyVirtualCalendar)
    //console.log("puntos criticos solapamiento", this.#criticsPointsOverlaping);
    const copyCriticsPointsOverlaping = _.cloneDeep(
      this.#criticsPointsOverlaping
    );
    const listPendings = [];
    const listCheck = [];
    const restCells = [];
    while (copyCriticsPointsOverlaping.length > 0) {
      const point = copyCriticsPointsOverlaping.shift();
      let clearOverlaping = false;
      let initialHour = point?.hourIndex
      for (let i = point?.dayIndex; i < copyVirtualCalendar.length; i++) {
        const dayList = copyVirtualCalendar[i];
        for (let j = initialHour ; j < dayList.length; j++) {
          const activities = dayList[j];
          let blocked = false;
          //aqui se guardan en la cola las actividades de la celda y se determina si alguna de ella esta bloqueada
          if (activities) {
            activities.forEach((activity) => {
              const existInTheList = listPendings.includes(activity);
              const alreadyPlaced = listCheck.includes(activity);
              if (!blocked) {
                blocked =
                  this.#listDic[activity]?.blocked &&
                  this.#listDic[activity]?._id;
              }
              if (
                !existInTheList &&
                !this.#listDic[activity]?.blocked &&
                !alreadyPlaced
              ) {
                listPendings.push(activity);
                let numberCells = this.#listDic[activity]?.meta?.numberCells;
                // a continuacion revisamos si en las celdas anteriores ya se ha colocado la actividad
                let indicatorDay = i;
                let indicatorHour = j - 1;
                if (indicatorHour < 0) {
                  indicatorDay--;
                  indicatorHour = 95;
                }
                while (
                  copyVirtualCalendar[indicatorDay][indicatorHour]?.includes(
                    activity
                  )
                ) {
                  numberCells--;
                  indicatorHour--;
                  if (numberCells <= 0) {
                    numberCells = this.#listDic[activity]?.meta?.numberCells;
                    break;
                  }
                  if (indicatorHour < 0) {
                    indicatorDay--;
                    indicatorHour = 95;
                  }
                }
                restCells.push(numberCells);
              }
            });
          }
          //si no existe ninguna bloqueada y hay actividades en la cola se coloca

          if (listPendings[0] && !blocked) {
            copyVirtualCalendar[i][j] = [listPendings[0]];
            restCells[0] = restCells[0] - 1;
            if (restCells[0] <= 0) {
              listCheck.push(listPendings[0]);
              listPendings.shift();
              restCells.shift();
            }
          } else if (blocked) {
            //en caso que si exista una bloqueada se determina si las actividades anteriores
            //pertenecen a la de la cola y en caso de que si se eliminan
            copyVirtualCalendar[i][j] = [blocked];
            let indicatorDay = parseInt(`${i}`);
            let indicatorHour =  parseInt(`${j}`);
            let shouldContinue,
              listCells = [];
            do {
              indicatorHour--;
              if (indicatorHour < 0) {
                indicatorDay--;
                indicatorHour = 95;
              }

              shouldContinue =
                copyVirtualCalendar[indicatorDay][indicatorHour] &&
                copyVirtualCalendar[indicatorDay][indicatorHour].includes(
                  listPendings[0]
                );
              if (
                copyVirtualCalendar[indicatorDay][indicatorHour]?.includes(
                  listPendings[0]
                )
              ) {
                listCells.push({
                  day: indicatorDay,
                  hour: indicatorHour,
                });
              }
              /*
                            _.remove(copyVirtualCalendar[indicatorDay][indicatorHour],(item)=>{
                                return item === listPendings[0]
                            })
                            */
            } while (shouldContinue);

            if (this.#listDic[listPendings[0]]?.nextPlace?._id === blocked) {
              const cellsTransport = parseInt(
                this.#listDic[listPendings[0]].traveltime / this.#cellHeight
              );
              const CanFit = listCells.length - cellsTransport;
              if (CanFit >= 2) {
                const newAct = { ...this.#listDic[listPendings[0]] };
                newAct.meta.numberCells = listCells.length;
                this.#listDic[listPendings[0]] = newAct;
                listCheck.push(listPendings[0]);
                listPendings.shift();
                restCells.shift();
              } else {
                listCells.forEach((cell) => {
                  _.remove(copyVirtualCalendar[cell.day][cell.hour], (item) => {
                    return item === listPendings[0];
                  });
                  if (
                    copyVirtualCalendar[indicatorDay][indicatorHour]?.length ===
                    0
                  ) {
                    copyVirtualCalendar[indicatorDay][indicatorHour] =
                      undefined;
                  }
                });
                if (this.#listDic[listPendings[0]]?.meta?.numberCells)
                  restCells[0] =
                    this.#listDic[listPendings[0]]?.meta?.numberCells;
              }
            } else {
              listCells.forEach((cell) => {
                _.remove(copyVirtualCalendar[cell.day][cell.hour], (item) => {
                  return item === listPendings[0];
                });
                if (
                  copyVirtualCalendar[indicatorDay][indicatorHour]?.length === 0
                ) {
                  copyVirtualCalendar[indicatorDay][indicatorHour] = undefined;
                }
              });
              if (this.#listDic[listPendings[0]]?.meta?.numberCells)
                restCells[0] =
                  this.#listDic[listPendings[0]]?.meta?.numberCells;
            }
          } else {
            copyVirtualCalendar[i][j] = undefined;
          }
          //revisamos si el punto ya solucionado era parte de algun punto critico y si es asi lo eliminamos
          const find = copyCriticsPointsOverlaping.findIndex((pto) => {
            return pto.dayIndex === i && pto.hourIndex === j;
          });
          if (find >= 0) {
            copyCriticsPointsOverlaping.splice(find, 1);
          }
          if (listPendings.length <= 0 && !copyVirtualCalendar[i][j]) {
            clearOverlaping = true;
            break;
          }
        }
        initialHour=0
        if (clearOverlaping) {
          break;
        }
      }
    }
    this.#virtualCalendar = copyVirtualCalendar;
    this.#criticsPointsOverlaping = copyCriticsPointsOverlaping;
  }

  detectNewTravels(dayIndex, hourIndex) {
    const copyVirtualCalendar = _.cloneDeep(this.#virtualCalendar);

    //console.log('fromNewTrvels:',copyVirtualCalendar)
    let actualActivity = "";
    let count = 0;
    const activitiesNewTravel = [];
    for (let i = 0; i < copyVirtualCalendar.length; i++) {
      const dayList = copyVirtualCalendar[i];
      for (let j = 0; j < dayList.length; j++) {
        const activities = dayList[j];
        //aqui evaluan cuando hace falta hacer una nueva peticion
        if (!activities || activities?.length === 0) {
          count++;
          if (count >= 2 && actualActivity) {
            delete this.#listDic[actualActivity].nextPlace;
            count = 0;
            actualActivity = "";
          }
        } else if (activities.length >= 2) {
          throw new CalendarMotorError({ message: "overlaping cell" });
        } else if (activities?.length === 1) {
          if (
            actualActivity &&
            actualActivity !== activities[0] &&
            this.#listDic[actualActivity]?.nextPlace?._id !== activities[0]
          ) {
            activitiesNewTravel.push({
              dayPosition: i,
              hourPosition: j,
              from: actualActivity,
              to: activities[0],
            });
          }
          actualActivity = activities[0];
          count = 0;
        }
      }
    }
    this.#criticsPointsTransports = activitiesNewTravel;
    //console.log("puntos criticos transportes", activitiesNewTravel);
  }

  async resolveCriticsPointsTransports() {
    const criticsPoints = _.cloneDeep(this.#criticsPointsTransports);
    const calendarDays = Object.keys(this.#daysDic);
    const calendarHours = Object.keys(this.#hoursDic);
    for (let i = 0; i < criticsPoints.length; i++) {
      const point = criticsPoints[i],
        from = { ...this.#listDic[point.from] },
        to = { ...this.#listDic[point.to] };
      let indicatorDay = point.dayPosition;
      let indicatorHour = point.hourPosition - from.meta.numberCells - 3;
      while (indicatorHour < 0) {
        indicatorDay--;
        indicatorHour = indicatorHour + 96;
      }
      let shouldContinue,
        cellsFrom = [],
        cellsTo = [];
      do {
        indicatorHour++;
        if (indicatorHour >= 96) {
          indicatorDay++;
          indicatorHour = 0;
        }
        const includeFrom = this.#virtualCalendar?.[indicatorDay][
          indicatorHour
        ]?.includes(point.from);
        const includeTo = this.#virtualCalendar?.[indicatorDay][
          indicatorHour
        ]?.includes(point.to);
        if (includeFrom || includeTo) {
          if (includeFrom) {
            cellsFrom.push({
              dayPosition: indicatorDay,
              hourPosition: indicatorHour,
            });
          }
          if (includeTo) {
            cellsTo.push({
              dayPosition: indicatorDay,
              hourPosition: indicatorHour,
            });
          }
          _.remove(
            this.#virtualCalendar[indicatorDay][indicatorHour],
            (item) => {
              return item === point.from || item === point.to;
            }
          );
          if (
            this.#virtualCalendar[indicatorDay][indicatorHour]?.length === 0
          ) {
            this.#virtualCalendar[indicatorDay][indicatorHour] = undefined;
          }
        }
        shouldContinue =
          cellsFrom.length < from.meta.numberCells ||
          cellsTo.length < to.meta.numberCells;
      } while (shouldContinue);
      //nos preparamos para hacer la nueva peticion
      let startFrom = cellsFrom[0]; //,endFrom =  cellsFrom[cellsFrom.length - 1]
      const newStartDateTimeFrom = `${calendarDays[startFrom.dayPosition]} ${
        calendarHours[startFrom.hourPosition]
      }`;
      from.startDateTime = moment(
        newStartDateTimeFrom,
        "YYYY/MM/DD HH:mm"
      ).format("YYYY-MM-DD HH:mm:ss");
      from.nextPlace = {
        lat: to.lat,
        lon: to.lon,
        name: to.name,
        _id: to._id,
      };
      let transport = travelMode[from.typeTrip];
      let durationTransport = 0,
        poly = "", customTravel = false;
      do {
        const resp = await api.get(
          `/MapRoute/routes?origin=${from.lat},${from.lon}
                &destination=${from.nextPlace.lat},${from.nextPlace.lon}
                &key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=${transport}`
        );
        if (
          resp.data.data.available_travel_modes &&
          resp.data.data.available_travel_modes.length > 0
        ) {
          from.typesTravels = resp.data.data.available_travel_modes.map(
            (el) => reversetravel[el.toLowerCase()]
          );
          from.typeTrip = from.typesTravels[0];
          transport = resp.data.data.available_travel_modes[0];
        } else {
          durationTransport =
            (resp.data.data.routes?.[0]?.legs[0].duration.value  || 0) / 60.0; // resp.data.routes?.[0]?.duration
          poly = resp.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
          transport = undefined;
          if(resp.data.data.status === "ZERO_RESULTS") {
            customTravel=true
            durationTransport=0.0001
          }
        }
      } while (transport);

      from.polyline = poly;
      const oldTravelTime = from.traveltime;
      from.traveltime = durationTransport;
      from.customTravel = customTravel
      if (durationTransport > oldTravelTime) {
        from.endDateTime = moment(from.startDateTime)
          .add(from.dur, "hour")
          .add(from.traveltime, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      this.SetOneActivityInCalendar(from);

      /*
            por si se necesita recolocar el de abajo
            */
      let startTo = cellsTo[0],
        endTo = cellsTo[cellsTo.length - 1];
      const newStartDateTimeTo = `${calendarDays[startTo.dayPosition]} ${
        calendarHours[startTo.hourPosition]
      }`;
      const newEndDateTimeTo = `${calendarDays[endTo.dayPosition]} ${
        calendarHours[endTo.hourPosition]
      }`;
      to.startDateTime = moment(newStartDateTimeTo, "YYYY/MM/DD HH:mm").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      to.endDateTime = moment(newEndDateTimeTo, "YYYY/MM/DD HH:mm")
        .add(this.#cellHeight, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      this.SetOneActivityInCalendar(to);
    }
    this.#criticsPointsTransports = [];
  }

  /**
   * the function read the process virtual calendar and return the decode calendar
   * @returns {Array} return the list of the new Positions of the activities
   */
  decodeToArray() {
    if (Object.keys(this.#daysDic).length <= 0) {
      return {
        list: [],
        days: [],
      };
    }
    const copyVirtualCalendar = _.cloneDeep(this.#virtualCalendar);
    //console.log('fromDecode:',copyVirtualCalendar)
    const calendarDays = Object.keys(this.#daysDic);
    const calendarHours = Object.keys(this.#hoursDic);
    let actualActivity = "";
    let cells = [];
    const listOfDecodeTimes = [];
    let finalLength = 0;
    const dayObject = {};
    for (let i = 0; i < copyVirtualCalendar.length; i++) {
      const dayList = copyVirtualCalendar[i];
      let shouldSave = false;
      for (let j = 0; j < dayList.length; j++) {
        const activities = dayList[j];
        let indicatorDay = i,
          indicatorHour = j;
        indicatorHour++;
        if (indicatorHour >= 96) {
          indicatorDay++;
          indicatorHour = 0;
        }
        if (
          !activities &&
          this.#virtualCalendar?.[indicatorDay]?.[indicatorHour] &&
          actualActivity
        ) {
          cells.push({
            dayPosition: i,
            hourPosition: j,
          });
        } else if (cells.length > 0 && actualActivity !== activities?.[0]) {
          cells.push({
            dayPosition: i,
            hourPosition: j,
          });
          listOfDecodeTimes.push([actualActivity, _.cloneDeep(cells)]);
          cells = [];
          finalLength++;
          actualActivity = undefined;
          shouldSave = true;
        }
        if (activities?.length >= 2) {
          throw new CalendarMotorError({ message: "overlaping cell" });
        } else if (activities?.length === 1) {
          actualActivity = activities[0];
          cells.push({
            dayPosition: i,
            hourPosition: j,
          });
        }
      }
      if (shouldSave) dayObject[calendarDays[i]] = finalLength;
    }
    const finalList = [];
    listOfDecodeTimes.forEach((idCells) => {
      const [id, cells] = idCells;
      let start = cells[0],
        end = cells[cells.length - 1];
      const newStartDateTime = moment(
          `${calendarDays[start.dayPosition]} ${
            calendarHours[start.hourPosition]
          }`
        ),
        newEndDateTime = moment(
          `${calendarDays[end.dayPosition]} ${calendarHours[end.hourPosition]}`
        );

      const activity = this.#listDic[id];
      const newTotalDur = newEndDateTime.diff(newStartDateTime, "hours", true);
      if (!activity.nextPlace) {
        activity.traveltime = 0;
      }
      const newDur = newTotalDur - (activity?.traveltime ||0) / 60;
      
      activity.dur = newDur;
      activity.startDateTime = newStartDateTime.format("YYYY-MM-DD HH:mm:ss");
      activity.endDateTime = newEndDateTime.format("YYYY-MM-DD HH:mm:ss");

      delete activity.meta.numberCells;
      delete activity.drag;
      delete activity.duration;
      activity.meta.arrayIndex = finalList.length;
      const actualTemp = findActualTemporada({
        startDate: activity.startDateTime,
        workingH: activity?.adminRef?.workingH || activity?.workingH,
      });

      activity.actualTemp = actualTemp;
      finalList.push(activity);
    });
    return {
      list: finalList,
      days: dayObject,
    };
  }

  /**
   * Function that start the fix of the wrong behaviors
   * @returns
   */
  async process() {
    let counter = 0;
    try {
      do {
        //console.log('fromStartProcess',_.cloneDeep(this.#virtualCalendar))
        this.processOverlapingCalendar();
        this.detectNewTravels();
        await this.resolveCriticsPointsTransports();
        counter++;
      } while (
        (this.#criticsPointsOverlaping.length > 0 ||
          this.#criticsPointsTransports.length > 0) &&
        counter < 100
      );
    } catch (error) {
      return new CalendarMotorError(error);
    }
    return;
  }
}

export default CalendarMotor;
