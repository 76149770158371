import React from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'

function CityCard({city, onClick=()=>{}}) {
  const {i18n, t} = useTranslation()
  const cityName = i18n.language==='es' ? city.city:  city.cityEn
  const state = i18n.language==='es' ? city.state:  city.stateEn
  return (
    <div className='CityCardCont' onClick={onClick}>
      <div className='CityCardHeader'>
        {city.cityActivities[0]&& <div className='principalActi' style={{backgroundImage:`url('${city.cityActivities[0]}')`}}></div>}
        <div className='secundaryActi' style={{
          width:city.cityActivities.length===0&& '100%',
          backgroundSize:city.cityActivities.length===0&& 'contain',
        }}>
          {city.cityActivities[1]&& <div style={{backgroundImage:`url('${city.cityActivities[1]}')`}}></div>}
          {city.cityActivities[2]&& <div style={{backgroundImage:`url('${city.cityActivities[2]}')`}}></div>}
        </div>
      </div>
      <div className='CityCardBody'>
        <h3>
        {cityName || state}
        {city?.emoji?.url && <div className='emojiCont d-inline ml-1'>
                                <img style={{height:'20px'}} alt={city?.emoji?.emoji} src={city?.emoji?.url}></img>
                                </div>}
        </h3>
        <p className='cityCardCountry d-flex'>
        
        {!!cityName && state }
        </p>
        <p className='cityCardnumberItems'>{t('CitySearchDescription', {nombre: city.numberActivities}) }</p>
      </div>
    </div>
  )
}

export default CityCard