import {createStore, applyMiddleware, compose,} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers'
import {startTimeCalendar } from '../utils/const';
const initialState = {
    calendar: {
        dataUser:undefined,
        users: [],
        inv: [],
        emailsEditInv: [],
        emailsViewInv: [],
        list: [],
        toDolist: [],
        agendaList: [],
        dayAgenda: {},
        itemsByDaysMarkers: {
            days:{},
            boundsDays:{},
            colorDays:{},
            daysArray:[],
        },
        prevAgendaList:[],
        city:'',
        cityName:'',
        cityNameEn:'',
        cityEmoji:{
            emoji: "🇺🇲",
            url: "https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f1fa-1f1f2.png"
        },
        cityDataCoor:{
            lat:10,
            lon:10,
            radius:0,
        },
        code:'',
        public:false,
        errorLoad:false,
        isLoading:true,
        name: '',
        id:'',
        startAtTime: startTimeCalendar,
        startDate: new Date(),
        minDate: new Date(),
        maxDate: new Date(new Date().getFullYear(), new Date().getMonth()+3),
    },
    calendarList:{
        list:[],
        invList:[],
        page:0,
        Nitems:0
    },
    user: {
        token:'',
        query:'',
        bounds:undefined,
        notifications:[],
        UnreadNotifications:0,
        markPlaces:[],
        list:[],
        places:[],
        Nitems:0,
    },
    blog: {
        query:'',
        author:'',
        blogs:[],
        Nitems:0,
        page:0
    },
    adminPlaces: {
        query:'',
        hidden:'',
        incomplete:'',
        city:'',
        places:[],
        Nitems:0,
        page:0
    },
    usersSocket: {
        users:[],
        msgs:[],
    }
}

const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunk)));