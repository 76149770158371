import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import _ from 'lodash'
//import moment from 'moment'
import React, {useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Collapse, Row } from 'reactstrap'
import api from '../../../utils/axiosApi'
import {emojiFlagsDic} from '../../../utils/emojicities'
import Highlighted from '../../../utils/Highlighted'
import { nameSchema } from '../../../utils/validateForm'
import './styles.css'
let timeout


export default function LandingForm({
    handleSubmit, 
    showOptions=false, 
    setShowOptions=()=>{},
    onCreateZeroCalendar=()=>{},
    oncitiesPop=()=>{},
}) {
    const [city, setCity] = useState({
        name:'',
        id:''
    })
    const [disabled, setDisabled] = useState(true)
    const refcollapse = useRef(undefined)
    const [loading, setLoading] = useState(false)
    const [cities, setCities] = useState([])
    const [numberCities, setNumberCities] = useState(0)
    //const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const createCalendar = async (e)=>{
        e.preventDefault()
        onCreateZeroCalendar(city)
    }
    useEffect(() => {
      if(!showOptions && refcollapse?.current){
          refcollapse.current.scrollTop = 0
      }
    
      return () => {
        
      }
    }, [showOptions])
    
    const handleSearch = async (e) => {
        if(!disabled) {setDisabled(true)}
        setCity({id:'', name:e.target.value})
        setShowOptions(true)
        let isloading= false
        if(cities.length === 0) {
            setLoading(true)
            isloading =true
        } else if(loading) {
            setLoading(false)
        }
        if(timeout){
            clearTimeout(timeout)
        }
        timeout = setTimeout(async ()=>{
            
            try {
                //setLoading(true)
                const resp = await api.get(`/ciudades/getcities?city=${e.target.value}`)
                
                const {data,error,meta} = resp.data
                if(error) return
                if(meta.query === e.target.value) {
                    setCities(data)
                    setNumberCities(meta.totalCities)
                    isloading && setLoading(false)
                }
            } catch (error) {
                setCities([])
                //setLoading(false)
                //console.log(error)
            }
            timeout=undefined
        },300)
    }
    
    const Handleclick = (name,id) => {
        const cityValidation = nameSchema.validate(id);
        if(!(cityValidation.error && id!=='')){
            onCreateZeroCalendar({id, name})
        }
        // setCity({name,id})
        // setShowOptions(false)
        // setDisabled(false)
    }
    /*
    const handledate = (e) => {
        setDate(e.target.value)
    }*/
    useEffect(() => {
    }, [cities, /*date,*/ loading])
    const handleSumbitting = async (e) => {
        e.preventDefault()
        const cityValidation = nameSchema.validate(city.id);
        if(!(cityValidation.error && city.id!=='')){
            handleSubmit(city.id, city.name)
        }
        
    }
    const { t, i18n } = useTranslation();
    return (
        <>
        <form className='p-0 d-none d-md-block' onSubmit={(e) => handleSumbitting(e)}> 
            <div className='inputCont mb-4'>
                <Row className='searchCont align-items-center m-0' style={{width: '100%'}}>
                    <Col xs={1} md={1} className='p-0 d-flex align-items-center justify-content-center'><FontAwesomeIcon icon='search'/></Col>
                    <Col  className='p-0'><input onClick={handleSearch} onChange={handleSearch} value={city.name} placeholder={t('placeholderSearchLanding')} className='inputCity' type='text'></input></Col>
                    {/* <Col xs={0} md={0} className='p-0 d-flex align-items-center'><input value={date} onChange={handledate} className='inputDate d-none' type='date'></input></Col>*/}
                    <Col xs={3} sm={2} className='p-0' style={{maxWidth:'150px '}}><button type='button' disabled={(city.name==='')} className='btnCreateCalendar'  onClick={createCalendar}>{t('btnSearchLanding')}</button></Col>
                </Row>
                <Collapse className='optionsSearchCollapse' isOpen={(showOptions)}>
                        <ul ref={refcollapse} style={{zIndex: 300}} className='optionsSearch'> 
                        {(!loading) && cities.map(e => {
                            let cityString = e.city
                            let country = e.country
                            let state = e.state
                            if(i18n.language==='en') {
                                cityString = e.cityEn
                                country = e.countryEn
                                state = e.stateEn
                            }
                            if(!cityString){
                                cityString = state
                            }
                            /*const citiesnames =cities.map(e=>{return e.city})
                            const firstInd = citiesnames.indexOf(e.city)
                            const lastIndexOf = citiesnames.lastIndexOf(e.city)*/
                            const place = `${cityString}, ${country}`
                            const cityText = cityString? Highlighted({text:cityString, highlight:city.name}) :''
                            //const countrytext = country? Highlighted({text:country, highlight:city.name}) :''
                            const statetext = state? Highlighted({text:state, highlight:city.name}) :''
                            //const showState = (firstInd!==lastIndexOf) 
                            return (
                            <li className='optionAutoComplete optionAutoCompleteCity d-flex' onClick={() => {Handleclick(place,e._id)}}>
                                {e?.emoji?.url && <div className='emojiCont d-inline-flex justify-content-center align-items-center mr-2'>
                                    <img style={{height:'25px'}} alt={e?.emoji?.emoji} src={e?.emoji?.url}></img>
                                </div>}
                                {(!e?.emoji?.url && emojiFlagsDic[e?.emoji?.emoji]) && <div className='emojiCont d-inline-flex justify-content-center align-items-center mr-2'>
                                    <img style={{height:'25px'}} alt={e?.emoji?.emoji} src={emojiFlagsDic[e?.emoji?.emoji]}></img>
                                </div>}
                                <p className='m-0'>
                                    {cityText} <span className='d-block'>{(state!==cityString) && statetext}</span>
                                </p>
                            </li>)
                        })}
                    {(cities.length!==0 && !loading ) && <li onClick={oncitiesPop} className='optionAutoComplete d-flex justify-content-between align-items-center' style={{textTransform:'none',color:'#156cd5'}}>
                    {t('LandingSeeAll',{nombre: numberCities})} </li> 
                    }
                    {(cities.length===0 && !loading ) && <li className='noOptionAutoComplete d-flex justify-content-between align-items-center' style={{textTransform:'none'}}>
                    <div>{t('noCityMsg')}<img alt='😔' className='ml-1' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f614.png' style={{width:'19px'}}/></div>  <button onClick={onCreateZeroCalendar} className='createZeroCalendar' type='button'>{t('noCitybtn')} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44f.png'></img></div></button> </li> 
                    }
                    {(!loading) ? <></> : 
                        <div className='d-flex justify-content-center align-items-center' style={{color: '#E5E9F2' ,fontSize: '50px', width:'100%', height: '70px'}}>
                        <FontAwesomeIcon spin icon='circle-notch'/>
                    </div>
                    }
                    </ul>
                </Collapse>
            </div>
        </form>

        </>

    )
}
/*
<Col xs={1} md={1} className='p-0 d-flex align-items-center justify-content-center'><FontAwesomeIcon icon='search'/></Col>
                    <Col xs={5} md={6} className='p-0'><input onChange={handleSearch} value={city} placeholder='Busque su ciudad destino' className='inputCity' type='text'></input></Col>
                    <Col xs={4} md={3} className='p-0 d-flex align-items-center'><input value={date} onChange={handledate} className='inputDate d-none' type='date'></input></Col>
                    <Col xs={2} md={2} className='p-0'><button disabled={disabled} className='btnCreateCalendar'>Explorar</button></Col>
*/
