export const SET_PLACES ='SET_PLACES';
export const SET_AGENDA_PLACES ='SET_AGENDA_PLACES';
export const SET_PREV_AGENDA_PLACES ='SET_PREV_AGENDA_PLACES';
export const SET_NEW_USER_AGENDA ='SET_NEW_USER_AGENDA';
export const SET_UNREGISTER_USER_AGENDA ='SET_UNREGISTER_USER_AGENDA';
export const SET_NEW_INV_AGENDA ='SET_NEW_INV_AGENDA';
export const SET_NEW_USER_INV_AGENDA ='SET_NEW_USER_INV_AGENDA';

export const SET_CALENDAR_LIST ='SET_CALENDAR_LIST';
export const SET_CALENDAR_LIST_PAGE ='SET_CALENDAR_LIST_PAGE';
export const SET_CALENDAR_COUNT_ITEMS ='SET_CALENDAR_COUNT_ITEMS';
export const SET_CALENDAR_NAME ='SET_CALENDAR_NAME';
export const SET_CALENDAR_INV_LIST ='SET_CALENDAR_INV_LIST';
export const SET_CALENDAR_PUBLIC ='SET_CALENDAR_PUBLIC';
export const SET_CALENDAR_LOADING ='SET_CALENDAR_LOADING';

export const INITIAL_SET ='INITIAL_SET';
export const SAVE_AGENDA ='SAVE_AGENDA';

export const SET_BLOGS_LIST_QUERY ='SET_BLOGS_LIST_QUERY';
export const SET_PLACES_ADMIN_LIST_QUERY ='SET_PLACES_ADMIN_LIST_QUERY';
//export const SAVE_AGENDA ='SAVE_AGENDA';

export const LOGIN_USER ='LOGIN_USER';
export const SIGNUP_USER ='SIGNUP_USER';
export const SIGNOUT_USER ='SIGNOUT_USER';
export const NOTIFICATIONS_USER ='NOTIFICATIONS_USER';

export const SET_USERS_SOCKET= 'SET_USERS_SOCKET'
export const GET_USERS_SOCKET= 'GET_USERS_SOCKET'
export const SET_USERS_MSG_SOCKET= 'SET_USERS_MSG_SOCKET'
export const GET_USERS_MSG_SOCKET= 'GET_USERS_MSG_SOCKET'

export const SET_SHOP_LIST ='SET_SHOP_LIST';
export const SET_PLACE_IN_LIST ='SET_PLACE_IN_LIST';