import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {  Col, Row } from 'reactstrap'
import api from '../../utils/axiosApi'

function ConfirmEmailPop({
    email='',
    forgetPass= false,
    isError=false,
    onClose=()=> {},
    onFailure=()=> {},
    onSuccess= async ()=> {}
}) {
    
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [codigo, setCodigo] = useState('')
    const { t,i18n } = useTranslation();

    useEffect(() => {
        const getCode = async () => {
            try {
                let resp
                
                if(forgetPass){

                    resp = await api.get(`/email/GetCodeRecoveryPass?email=${email}&len=${i18n.language}`)
                } else {
                    resp = await api.get(`/email/GetCode?email=${email}&len=${i18n.language}`)
                }
                const {data,error} = resp.data
                if (error) return
                setCodigo(data?.codigo.toLowerCase() || 'asdfasfaff98uy8tyq4uinjcq')
            } catch (error) {
                //console.log(error)
                onFailure()
            }
        }
        getCode()
        return () => {
            
        }
    }, [])
    const handleConfirm = async () => {
        setDisabled(true)
        if(inputValue.toLowerCase() === codigo){

            await onSuccess()
        } else {
            setError(true)
        }
        setDisabled(false)
    }
    return (
        <>
            <Col className='headerBody'>
                <div className='confirmHeader'>
                    <h2>{t('confirmEmailTitle')} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f605.png'></img></div></h2>
                    <p>{t('confirmEmailSubTitle')}</p>
                </div>
                <div className='confirmHeader pt-3' >
                    <h2 style={{fontSize:'15px'}}>{t('confirmEmailPlaceHolder')}</h2>
                    <input
                        onChange={(e)=>{setInputValue(e.target.value); error && setError(false)}} 
                        value={inputValue}
                        name='code' 
                        maxLength='6'
                        placeholder=''//{t('confirmEmailPlaceHolder')}
                        className=' inputLogSign inputLogSignCont btnCodeRecovery justify-content-center ' 
                        style={{width:'50%',textAlign:'center',minHeight:'85px',borderRadius:'20px',fontSize:'55px', marginLeft:'25%'}}
                        type='password'></input>
                </div>
            </Col>

                    {(error || isError) && <div>
                        <p className='pl-3 errormsg m-0'>*{t('confirmEmailError')}</p>
                        
                    </div>}

                    <Row className='pl-4 pr-4 mt-3'>
                    <Col>
                        <button disabled={disabled} onClick ={onClose} type='button' className='btnCancel'>
                            
                            {t('signUpCancel')}
                        </button>
                    </Col>
                    <Col>
                        <button disabled={disabled} type='button' onClick={handleConfirm} className='btnCrear'>
                            {t('confirmEmailSend')}
                        </button>
                    </Col>
                        
                    </Row>
        </>
    )
}

export default ConfirmEmailPop
