import React, { Component } from 'react';
import { withRouter } from 'react-router';
import AppBar from '../../components/AppBar';
import LandingDiv from '../../components/LandingDiv'
import FooterPrincipal from '../../components/FooterPrincipal'
import {option, Toast} from '../../utils/sweetalert'
import withReactContent from 'sweetalert2-react-content'
import { setLoginUser, setSignout, setSignupUser} from '../../redux/actions/usersActions';
import { getUserToken } from '../../redux/reducers';
import { connect } from 'react-redux';
import LoginUser from '../../components/LoginUser';
import api from '../../utils/axiosApi';
import jwtDecode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import CitiesPopUp from '../../components/CitiesPopUp';
import QRdownloadPopUp from '../../components/QRdownloadPopUp';
//import { getRandomColor } from '../utils/getRandomColor';
import ModalContactBusiness from '../../components/ModalContactBusiness';
//import RegisterAgency from '../../components/RegisterAgency';
import Routes from '../../utils/Routes';

class CreateCalendar extends Component {
    constructor(props) {
        super(props)
        this.state ={
            show: false,
            showCitiesPop:false,
            showQrPop:false,
            showAgencyPop:false,
            showAgencyRegister:false,
            showSearchMobile:false,
            signUp:false
        }
        this.handleCreateCalendar = this.handleCreateCalendar.bind(this)
    }
    componentDidMount(){
        document.title='Tripendar'
    }
    handleCreateCalendar = async (city) => {
        const {t} = this.props
        const calName =city?.name ? `${t("myTripTo")} ${ city.name.split(',')[0]}`: t("myCalendar")
        const cityId = `${city?.id ? city.id: ''}`;
        const datecal= new Date()
            const calendar = {
                name: calName ,
                city:cityId,
                startDate: new Date(datecal.getFullYear(), datecal.getMonth(),datecal.getDate(), 6,0),
                minDate: new Date(datecal.getFullYear(), datecal.getMonth(),datecal.getDate(), 6, 0),
                maxDate: new Date(datecal.getFullYear()+2, datecal.getMonth()+1,datecal.getDate(), 6, 0),
                startAtTime: 6,
                agendaItems: [],
                toDolist: [],
                inv: []
            }
        try {
            let id=''
            if(this.props.token) {
                const decUser= jwtDecode(this.props.token)
                const calendarUser = {...calendar, users: [decUser.user._id]}
                const resp = await api.post(`/agendacalendar/set`, calendarUser)
                id = resp.data.data._id
            } else {
                const calendarUser = {...calendar, users: []}
                const resp = await api.post(`/agendacalendar/set`, calendarUser)
                id = resp.data.data._id
                let localCalendars= (localStorage.getItem('calendars') && JSON.parse(localStorage.getItem('calendars')))||[]
                
                localCalendars.push({
                    _id:id,
                    name: resp.data.data.name,
                    city: cityId,
                    createdAt: new Date()
                })
                localStorage.setItem('calendars', JSON.stringify(localCalendars))
            }/*
            await this.props.clearShopCarList(cityId) 
            if(localStorage.getItem(this.state.idCities)) {
                localStorage.removeItem(this.state.idCities)
            }*/
            this.props.history.push(Routes.calendar(id))
        } catch (error) {
            //console.log(error)
        }
    } 
    handleSubmit = async (id, name) => {
        /*if (!this.props.token || this.props.token === '') {
                await this.handleRegister()
        }*/
        try {
            this.props.history.push({
                pathname: `/${name.split(',')[0].replaceAll(' ','-')}`,
                state: {
                }
            })
            
        } catch (error) {
            //console.log(error)
        }
    }
    
    handleDoubt= async(data) =>{
        try{
            const {t} = this.props
            const MySwal = withReactContent(option)
            /*const resp=*/ await MySwal.fire({
                title:  `${t("iHaveDoubt")} `,
                text: "<textarea id='text'></textarea>",
                // --------------^-- define html element with id
                html: true,
                showCancelButton: true,
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
                animation: "slide-from-top",
                inputPlaceholder: "Write something"
                }, function(inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    return false
                }
                // get value using textarea id
                //let val = document.getElementById('text').value;
                });
        } catch(error){
            //console.log(error)
        }

    }
    handleLoginCustomer = async (data) => {
        try {
            /*const MyToast = withReactContent(Toast)*/
            const respo = await this.props.setLoginUser(data)
            if(this.props.token !== '') {
                const {user}= jwtDecode(this.props.token)
                if(user.agency){
                    this.props.history.push(
                        {
                            pathname: Routes.Bussiness('/')
                        }
                    )
                }
                    return true
            } else {
                
                    return respo
            }
        } catch (error) {
            //console.log(error)
        }
    }
    handleSignUpCustomer = async (data, isAgency) => {
        try {
            const {t,i18n}=this.props
            const MyToast = withReactContent(Toast)
            let respSignUp
            if(data.accept) {

                respSignUp =await this.props.setSignupUser({payload:data, len: i18n.language, isAgency})
                
            }
            if(respSignUp && respSignUp.email) {
                await MyToast.fire({
                    title: <p style={{fontSize: '22px', zIndex: 30000}}>{t("registeredMail")}</p>,
                    icon: 'error',
                    })
            }else if(this.props.token !== '') {
                
            return
            } else {
                await MyToast.fire({
                    title: <p style={{fontSize: '22px', zIndex: 30000}}>{t("errorToast")}</p>,
                    icon: 'error',
                    })
            }
        } catch (error) {
            //console.log(error)
        }
    }


    render() {
        
        const { t } = this.props;
        return (
            <div className='bckColor' style={{height: '100vh', width: '100vw',position: 'relative', overflow: 'hidden'}}>
            <div className='principalBackground setPadding' style={{justifyContent: 'start', alignItems:'start'}}>
                <AppBar 
                disableSearchBar
                istoken={this.props.token}
                oncitiesPop={()=>{this.setState({
                            showCitiesPop:true,
                        })}}
                showMobileSearch ={this.state.showSearchMobile}
                setShowMobile={(a)=>{
                                this.setState({showSearchMobile: a})
                            }}
                mobileSearchSubmit={this.handleSubmit}
                onCreateZeroCalendar={this.handleCreateCalendar}
                onSignOut={this.props.setSignout} 
                showSignUp={this.state.show} 
                onClose = {() => {
                        this.setState({
                            show:false,
                            signUp: false,
                        })
                    }}
                onRegister={() => {
                    this.setState({
                        show:true,
                        signUp: true
                    })
                }} 
                onLogin={() => {
                    this.setState({show:true,signUp: false})
                    }} 
                ></AppBar>
                <LandingDiv 
                onCreateZeroCalendar={this.handleCreateCalendar}
                oncitiesPop={()=>{this.setState({
                            showCitiesPop:true,
                        })}}
                onSubmit={this.handleSubmit}>
                        <div
                            onClick={()=>{
                                this.setState({showSearchMobile: !this.state.showSearchMobile})
                            }}
                            className='SearchInfoCont w-100 mr-2 cursor-pointer d-md-none' >
                            <div
                            style={{
                                backgroundColor:'white',
                                fontSize:'16px',
                                height:'40px',
                                color:'#7E96AE',
                                borderRadius: '10px'
                            }}
                            className='searchinput d-flex align-items-center justify-content-center'>
                                <div
                                style={{
                                    height:'22px',
                                    width:'22px',
                                    fontSize:'20px',
                                    background: 'white',
                                    color:'black'
                                }} className=' rounded-circle d-flex justify-content-center align-items-center' type='submit'>
                                <FontAwesomeIcon icon='search' ></FontAwesomeIcon>
                                </div>&nbsp; {t('mobilePlaceholderSearchLanding')}  
                            </div>
                        </div>
                        
                
                </LandingDiv>
                
                {/* <div style={{position:'absolute',left:'50%', height:'500px', overflow:'scroll', zIndex:6000}}>
                getRandomColor
                {[1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99,1,2,3,4,5,6,7,8,9,0,11,22,33,44,55,66,77,88,99].map(()=>{
                    const color = getRandomColor()
                    return <div style={{backgroundColor:color, color:'white'}}>{color}</div>
                })}
                
                </div> */}
                {this.state.show && <LoginUser 
                    signUp={this.state.signUp} 
                    onLogin={this.handleLoginCustomer} 
                    onSignUp={this.handleSignUpCustomer}
                    onClose={() => {
                        this.setState({
                            show:false,
                            signUp: false
                        })
                    }}/>}
                {/* <Button className='doubtBtn' onClick={this.handleDoubt}>{t('iHaveDoubt')}
                </Button> */}
                
                {
                    /*
                    this.state.showAgencyRegister && <RegisterAgency 
                    signUp={!this.state.signUp} 
                    onLogin={this.handleLoginCustomer} 
                    onSignUp={this.handleSignUpCustomer}
                    onClose={() => {
                        this.setState({
                            showAgencyRegister:false,
                            signUp: false
                        })
                    }}/>
                    */}
                {this.state.showCitiesPop && <CitiesPopUp
                    onselectCity={this.handleCreateCalendar}
                    onClose={() => {
                        this.setState({
                            showCitiesPop:false,
                        })
                    }}
                    ></CitiesPopUp>}
                {this.state.showQrPop && <QRdownloadPopUp
                    onClose={() => {
                        this.setState({
                            showQrPop:false,
                        })
                    }}
                    />}
                    

                    {this.state.showAgencyPop && <ModalContactBusiness
                    onClose={() => {
                        this.setState({
                            showAgencyPop:false,
                        })
                    }}
                    />}
                        <div className='qrButton'>
                        <h4>{t('LandingQRCode1')}</h4>
                        <p onClick={()=>{this.setState({
                            showQrPop:true,
                        })}}>{t('LandingQRCode2')}</p>
                        </div>
                        {!localStorage.getItem('jwtuser') && <div className='agencyRegister' >
                            <p onClick={()=>{ this.props.history.push(Routes.bussinessLanding())}}>{t('CreateAgency')}</p>
                        </div>}
                    
            </div>
                <FooterPrincipal></FooterPrincipal>
                </div>  
        );
    }
}
/*
<LandingBack></LandingBack>

*/

const mapStateToProps = state => {
    return ({
        token:getUserToken(state)
})}
const mapDispatchToProps = dispatch => (
    {
        setLoginUser: value =>dispatch(setLoginUser(value)),
        setSignupUser: value =>dispatch(setSignupUser(value)),
        setSignout: value =>dispatch(setSignout(value)),
    })

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(CreateCalendar)));