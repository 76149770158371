import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { /*useEffect,*/ useEffect, useRef, useState } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import '../SearchCalendarHeader/styles.css'
import '../SearchCalendarBody/styles.css'
import { /*Button,*/ Col, Row } from 'reactstrap'
import ChangeLngBtn from '../ChangeLgnBtn'
//import QRCode from 'react-qr-code'
import {logoW} from '../../utils/imgImports'
import/* exportAsImage,*/{exportImgAsPDF} from '../../utils/exportAsImg'
import QRCode from 'react-qr-code'
import api from '../../utils/axiosApi'
let timeOutName
let timeOutPass

function QRdownloadPopUp({
    cityQuery='',
    onselectCity=()=>{},
    onClose=()=>{},
}) {const {t} = useTranslation()
const qrContainer = useRef(null)
const [nameWifi, setNameWifi] = useState('')
const [passWifi, setPassWifi] = useState('')
useEffect(() => {
    api.post('/metrics/setMetric',{
        type:'OpenQrPop',
        urlDestiny:'',
        value:``
    })

  return () => {
    
  }
}, [])

    return (
        <> 

            <div className='totalPopUpUpperCont'>
                <Row className='popUpQrCont position-relative'>
                            <button 
                            style={{fontSize:'15px'}}
                            onClick={onClose} type='button' className=' btnCancelLog btnCloseCities d-md-none'>
                                <FontAwesomeIcon color={`${'#001A72'}`} icon='times'/>
                            </button>
                    <Col className='d-flex flex-column justify-content-between align-items-center mb-4 mb-md-0'>
                        <div className='infoQrcont w-100'>
                            <h2>{t('QRFormTitle')} <span className='ml-1'> {t('QRFormTitle2')}</span>  
                                <div className='emojiCont d-inline-block mr-2'>
                                <img style={{height:'19px'}} alt='🤔' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f914.png'></img>
                                </div>
                            </h2>
                            <p>{t('QRFormDescription1')}</p>
                            <p>{t('QRFormDescription2')}</p>
                        </div>
                        

                        
                        <div className='formQrCont w-100'>
                            <div className='inputQrCont'>
                                <label>{t('QRFormName')}</label>
                                <div className='d-flex'>
                                    <div className='emojiQrCont mr-2 ml-2'>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.6825 8.88007C6.9075 6.39007 11.1 6.39007 14.325 8.88007" stroke="#7E96AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.5 6.27007C6.045 2.76007 11.955 2.76007 16.5 6.27007" stroke="#7E96AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.09253 11.6176C7.45503 9.78762 10.5375 9.78762 12.9 11.6176" stroke="#7E96AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.05005 14.3626C8.23505 13.4476 9.77255 13.4476 10.9576 14.3626" stroke="#7E96AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                    </div>
                                    <input onChange={(e)=>{
                                        
                                        if(timeOutName){
                                            clearTimeout(timeOutName)
                                        }
                                        timeOutName= setTimeout(()=>{setNameWifi(e.target.value)},500)
                                        
                                    }} type='text' placeholder={t('QRFormName')}></input>
                                </div>
                            </div>
                            <div className='inputQrCont'>
                                <label>{t('QRFormPass')}</label>
                                <div className='d-flex'>
                                    <div className='emojiQrCont mr-2 ml-2'>
                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.4375 5.87506H8.625V3.50006C8.625 2.80387 8.34844 2.13619 7.85616 1.64391C7.36387 1.15162 6.69619 0.875061 6 0.875061C5.30381 0.875061 4.63613 1.15162 4.14384 1.64391C3.65156 2.13619 3.375 2.80387 3.375 3.50006V5.87506H2.5625C2.11495 5.87506 1.68572 6.05285 1.36926 6.36932C1.05279 6.68579 0.875 7.11501 0.875 7.56256V11.9376C0.875 12.3851 1.05279 12.8143 1.36926 13.1308C1.68572 13.4473 2.11495 13.6251 2.5625 13.6251H9.4375C9.88505 13.6251 10.3143 13.4473 10.6307 13.1308C10.9472 12.8143 11.125 12.3851 11.125 11.9376V7.56256C11.125 7.11501 10.9472 6.68579 10.6307 6.36932C10.3143 6.05285 9.88505 5.87506 9.4375 5.87506ZM4.25 3.50006C4.25 3.03593 4.43437 2.59081 4.76256 2.26262C5.09075 1.93444 5.53587 1.75006 6 1.75006C6.46413 1.75006 6.90925 1.93444 7.23744 2.26262C7.56563 2.59081 7.75 3.03593 7.75 3.50006V5.87506H4.25V3.50006ZM10.25 11.9376C10.25 12.153 10.1644 12.3597 10.012 12.5121C9.85965 12.6645 9.65299 12.7501 9.4375 12.7501H2.5625C2.34701 12.7501 2.14035 12.6645 1.98798 12.5121C1.8356 12.3597 1.75 12.153 1.75 11.9376V7.56256C1.75 7.34707 1.8356 7.14041 1.98798 6.98804C2.14035 6.83566 2.34701 6.75006 2.5625 6.75006H9.4375C9.65299 6.75006 9.85965 6.83566 10.012 6.98804C10.1644 7.14041 10.25 7.34707 10.25 7.56256V11.9376Z" fill="#7E96AE" stroke="#7E96AE" strokeWidth="0.25"/>
                                        </svg>
                                    </div>
                                    <input onChange={(e)=>{
                                        if(timeOutPass){
                                            clearTimeout(timeOutPass)
                                        }
                                        timeOutPass= setTimeout(()=>{setPassWifi(e.target.value)},500)
                                        
                                    }} ttype='text' placeholder={t('QRFormPass')}></input>
                                </div>
                            </div>
                        </div>

                        <Row className='footerQrcont w-100 mt-2'>
                            <Col  md='8'  className='pl-0 mb-2'>
                                <p className='w-100'>{t('QRFormPrint')}</p>
                                <div className='d-inline-block'><ChangeLngBtn direction='up' className='m-0'></ChangeLngBtn></div>
                            </Col>
                            <Col xs='12' md='4'>
                                <button className='dwnlBtn' type='button' onClick={()=>{
                                    if(qrContainer.current) {
                                        exportImgAsPDF(qrContainer.current,'QRImg')
                                        api.post('/metrics/setMetric',{
                                            type:'QrDownload',
                                            urlDestiny:'',
                                            value:`WIFI:T:${passWifi.length===0? '': 'WPA'};S:${nameWifi};P:${passWifi};H:;`
                                        })
                                    }
                                }}>{t('QRFormDownload')}</button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md='4' className='d-flex align-items-center justify-content-center'>
                        <div className='qrContainer'>
                            <div className='pl-3 pr-3'>

                                <div className='qrImg'>
                                <QRCode size={'140'} value="https://www.tripendar.com/" />
                                <h3>{t('QRScan')}</h3>
                                </div>
                            </div>
                            <h3>{t('QRSearchWifi')}</h3>
                            <div className='logoTripQr'>
                                <img 
                                width= '25px'
                                height= '30px' alt='logo' src={logoW}></img>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='position-absolute' style={{padding:'50px', top:'200%'}} ref={qrContainer}>
                    <div className='bigQrContainer'>
                        <div className='bigQrImg'>
                        <QRCode size={'300'} value={`WIFI:T:${passWifi.length===0? '': 'WPA'};S:${nameWifi};P:${passWifi};H:;`} />
                            <h3>{t('QRScan')}</h3>
                        </div>
                        <h3>{t('QRSearchWifi')}</h3>
                        <div className='bigLogoTripQr'>
                            <img width='45px' height='60px' alt='logo' src={logoW}></img>
                        </div>
                    </div>
                </div>
                
            </div>
            <div onClick={onClose} className='backgroundPopUp'></div>
        </>
        
    )
}
export default React.memo(QRdownloadPopUp)
