/**
 * Object of URls
 */
const Routes = {}
Routes.processPayment = (idPayment=":idPayment", querys) =>{
    let url = `/payment/`+idPayment
    if(querys) {
        url = url + `${querys}`
    }
    return url
}
/**
 * It returns the Route for blog page, or information blog page
 * @param {String} idBlog - Blog ID (optional)
 * @returns {String} 
 */
Routes.blog = (idBlog)=>{
    let url = `/blog`
    if(idBlog) {
        url = url + `/${idBlog}`
    }
    return url
}
/**
 * It returns the url of list of the blogs of the author
 * @returns {String}
 */
Routes.myBlogs = ()=>`/myblogs`
/**
 * It returns the route for create a blog
 * @returns {String}
 */
Routes.createBlogs = ()=>`/createblog`
/**
 * It returns the url of the landing of cheaper flights
 * @returns {String}
 */
Routes.cheaperFlights = ()=>`/flights`
/**
 * It returns the url of the landing of cheaper Business
 * @returns {String}
 */
Routes.bussinessLanding = ()=>`/my-business`
/**
 * It returns the url of a booking hotel in the calendar
 * @param {String} type it could be create or editing
 * @returns {String}
 */
Routes.bookingHotel = (type=':type')=>`/bookinghotel/${type}`
/**
 * It returns the url of activities page 
 * @param {String} nameCity The name or Id of the city
 * @param {String} idPlace ID of a activity to show
 * @returns {String}
 */ 
Routes.activities =  (nameCity=':nameCity',idPlace)=>{
    let url = `/activities/${nameCity}`
    if(idPlace) {
        url = url + `/${idPlace}`
    }
    return url
}
/**
 * It return the url of Calendar List
 * @returns {String}
 */
Routes.calendars = ()=>`/calendars`
/**
 * 
 * @param {String} id - ID of the calendar to share
 * @returns {String}
 */
Routes.viewCalendar = (id=':id')=>`/viewcalendar/${id}`
/**
 * It returns the url of a calendar
 * @param {String} id - Calendar ID
 * @param {String} idcity - City Name or Id
 * @param {String} idPlace - Activity Id
 * @returns {String}
 */
Routes.calendar = (id=':id', idcity, idPlace)=>{
    let url = `/calendar/${id}`
    if(idcity) {
        url = url + `/${idcity}`
        if(idPlace) {
            url = url + `/${idPlace}`
        }
    }
    return url
}
/**
 * It returns the url of the page of hotel reservation details 
 * @param {String} id - Id of a hotel Reservation 
 * @returns {String}
 */
Routes.reservation = (id=':id')=>`/reservation/${id}`
/** 
 * it returns the url of a sharecalendar page
 * @param {String} id - Share Code
 * @returns {String}
 */
Routes.shareCalendar = (id=':id')=>`/CustomerTocalendar/${id}`
/**
 * It return the url of a bussiness pages
 * @param {String} section - The section of the bussiness page
 * @returns {String}
 */
Routes.Bussiness =  (section)=>{
    let url = `/bussiness`
    if(section) {
        url = url + `/${section}`
    }
    return url
}
Routes.BussinessInfluencer =  (section)=>{
    let url = `/my-space`
    if(section) {
        url = url + `/${section}`
    }
    return url
}
Routes.CalendarInfluencer =  (idInfluencer,section)=>{
    let url = `/space/`+idInfluencer
    if(section) {
        url = url + `/${section}`
    }
    return url
}
/**
 * It returns the urls of calendars associated of a agency
 * @param {String} idAgency - Agency ID
 * @param {String} id - Calendar ID
 * @param {String} idcity - city Name or Id (optional)
 * @param {String} idPlace - Activity ID (optional)
 * @returns {String}
 */
Routes.agencyCalendar = (idAgency=':idAgency',id, idcity, idPlace)=>{
    let url = `/agency/${idAgency}`
    if(id){ 
        url = url + `/calendar/${id}`
    }
    if(idcity) {
        url = url + `/${idcity}`
        if(idPlace) {
            url = url + `/${idPlace}`
        }
    }
    return url
}
/**
 * It returns the urls of calendars associated of a agency
 * @param {String} idAgency - Agency ID
 * @param {String} id - Calendar ID
 * @param {String} idcity - city Name or Id (optional)
 * @param {String} idPlace - Activity ID (optional)
 * @returns {String}
 */
Routes.agencyCalendarIframe = (idAgency=':idAgency',id, idcity, idPlace)=>{
    let url = `/agency-iframe/${idAgency}`
    if(id){ 
        url = url + `/calendar/${id}`
    }
    if(idcity) {
        url = url + `/${idcity}`
        if(idPlace) {
            url = url + `/${idPlace}`
        }
    }
    return url
}
/**
 * It returns the urls of calendars associated of a agency Iframe
 * @param {String} idAgency - Agency ID
 * @param {String} id - Calendar ID
 * @param {String} idcity - city Name or Id (optional)
 * @param {String} idPlace - Activity ID (optional)
 * @returns {String}
 */
Routes.activitiesCalendarIframe = (idAgency=':idAgency',id=':id', idcity, idPlace)=>{
    let url = `/activities-calendar-iframe/${idAgency}`
    if(id){ 
        url = url + `/calendar/${id}`
    }
    if(idcity) {
        url = url + `/${idcity}`
        if(idPlace) {
            url = url + `/${idPlace}`
        }
    }
    return url
}
/**
 * It returns the url of things to do
 * @param {String} nameCity - City name or Id 
 * @param {String} idPlace - Activity ID (optional)
 * @returns {String}
 */
Routes.thingsToDo =  (nameCity=':nameCity',idPlace)=>{
    let url = `/ThingsToDo/${nameCity}`
    if(idPlace) {
        url = url + `/${idPlace}`
    }
    return url
}
/**
 * It returns the url of city simple
 * @param {String} nameCity - City name or Id 
 * @param {String} idPlace - Activity ID (optional)
 * @returns {String}
 */
Routes.city =  (nameCity=':nameCity',idPlace)=>{
    let url = `/${nameCity}`
    if(idPlace) {
        url = url + `/${idPlace}`
    }
    return url
}
export default Routes