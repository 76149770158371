import React from 'react'
import { useTranslation } from 'react-i18next';
import {  Col, Row } from 'reactstrap'

function LoginInput({
    onChange, 
    userEmail, 
    userPass, 
    remenber,
    setRecoveryPass,
    error
}) {
    const { t } = useTranslation();
    return (
        <>
                        <div>
                            <label className='labelLogSign '>{t('signUpEmail')}:</label>
                            
                            <Row className='inputLogSignCont position-relative justify-content-center align-items-center m-0'>
                                
                                <div style={{left:'7px', top:'0px',bottom:'0px'}} className="position-absolute p-0 d-flex justify-content-center align-items-center" >
                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0005 1.175H10L2.5 1.175L2.49952 1.175C2.22337 1.17679 1.95469 1.26481 1.73101 1.42675L1.64858 1.48644L1.73 1.5475L6.205 4.90375L6.25 4.9375L6.295 4.90375L10.77 1.5475L10.8514 1.48644L10.769 1.42675C10.5453 1.26481 10.2766 1.17679 10.0005 1.175ZM2.27372 8.69003L2.10949 8.81334L2.31454 8.82488C2.37704 8.8284 2.43968 8.82844 2.50218 8.825L9.99801 8.80626C10.0604 8.80969 10.123 8.80965 10.1855 8.80613L10.3905 8.79459L10.2263 8.67128L8.42003 7.31502L8.42 7.31499C8.3033 7.22747 8.22615 7.09718 8.20552 6.95278C8.18489 6.80838 8.22248 6.6617 8.31001 6.545C8.39753 6.4283 8.52782 6.35115 8.67222 6.33052C8.81662 6.30989 8.9633 6.34748 9.08 6.43501L11.13 7.9725L11.2115 8.03363L11.2457 7.93764C11.2955 7.79746 11.3223 7.65011 11.325 7.50134H11.325V7.5V2.65625V2.50625L11.205 2.59625L6.58 6.06499C6.4848 6.1364 6.369 6.175 6.25 6.175C6.131 6.175 6.0152 6.1364 5.92 6.06499L1.295 2.59625L1.175 2.50625V2.65625L1.175 7.5L1.175 7.50023C1.17547 7.65566 1.20229 7.80989 1.25432 7.95636L1.28845 8.05241L1.37 7.99125L3.42 6.45376C3.5367 6.36623 3.68338 6.32864 3.82778 6.34927C3.97218 6.3699 4.10248 6.44705 4.19 6.56375C4.27752 6.68044 4.3151 6.82712 4.29447 6.97153C4.27384 7.11593 4.1967 7.24622 4.08 7.33374L4.07997 7.33377L2.27372 8.69003ZM2.5 0.075H10C10.6432 0.075 11.26 0.330489 11.7147 0.785264C12.1695 1.24004 12.425 1.85685 12.425 2.5V7.5C12.425 8.14315 12.1695 8.75996 11.7147 9.21474C11.26 9.66951 10.6432 9.925 10 9.925H2.5C1.85685 9.925 1.24004 9.66951 0.785264 9.21474C0.330489 8.75996 0.075 8.14315 0.075 7.5V2.5C0.075 1.85685 0.330489 1.24004 0.785264 0.785264C1.24004 0.330489 1.85685 0.075 2.5 0.075Z" fill="#7E96AE" stroke="white" strokeWidth="0.15"/>
                                    </svg>
                                </div>
                                <input 
                                onChange={onChange} 
                                name='userEmail' 
                                placeholder={t('loginEmailPlaceHolder')}
                                value={userEmail}
                                className='inputLogSign pl-4' 
                                type='text'></input>
                            </Row>
                        </div>
                        <div>
                            <label className='labelLogSign'>{t('signUpPass')}:</label>
                            
                            <Row className='inputLogSignCont justify-content-center align-items-center position-relative m-0'>
                                
                                <div style={{left:'7px', top:'0px',bottom:'0px'}} className="position-absolute p-0 d-flex justify-content-center align-items-center" xs={12}>
                                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.75 4.875H7.125V3C7.125 2.43642 6.90112 1.89591 6.5026 1.4974C6.10409 1.09888 5.56359 0.875 5 0.875C4.43641 0.875 3.89591 1.09888 3.4974 1.4974C3.09888 1.89591 2.875 2.43641 2.875 3V4.875H2.25C1.88533 4.875 1.53559 5.01987 1.27773 5.27773C1.01987 5.53559 0.875 5.88533 0.875 6.25V9.75C0.875 10.1147 1.01987 10.4644 1.27773 10.7223C1.53559 10.9801 1.88533 11.125 2.25 11.125H7.75C8.11467 11.125 8.46441 10.9801 8.72227 10.7223C8.98013 10.4644 9.125 10.1147 9.125 9.75V6.25C9.125 5.88533 8.98013 5.53559 8.72227 5.27773C8.46441 5.01987 8.11467 4.875 7.75 4.875ZM3.625 3C3.625 2.63533 3.76987 2.28559 4.02773 2.02773C4.28559 1.76987 4.63533 1.625 5 1.625C5.36467 1.625 5.71441 1.76987 5.97227 2.02773C6.23013 2.28559 6.375 2.63533 6.375 3V4.875H3.625V3ZM8.375 9.75C8.375 9.91576 8.30915 10.0747 8.19194 10.1919C8.07473 10.3092 7.91576 10.375 7.75 10.375H2.25C2.08424 10.375 1.92527 10.3092 1.80806 10.1919C1.69085 10.0747 1.625 9.91576 1.625 9.75V6.25C1.625 6.08424 1.69085 5.92527 1.80806 5.80806C1.92527 5.69085 2.08424 5.625 2.25 5.625H7.75C7.91576 5.625 8.07473 5.69085 8.19194 5.80806C8.30915 5.92527 8.375 6.08424 8.375 6.25V9.75Z" fill="#7E96AE" stroke="#7E96AE" strokeWidth="0.25"/>
                                    </svg>
                                </div>
                                <input 
                                onChange={onChange} 
                                className='inputLogSign pl-4' 
                                value={userPass}
                                name='userPass' 
                                placeholder='************'
                                type='password'>
                                </input>
                            </Row>
                                <Col className="p-0 d-flex justify-content-between mt-1" xs={12}>
                                <div className='mt-1 mb-2'> 
                                    {error && <p className='errormsg m-0'>* {t('loginErrorMsg')}</p>}
                                </div>
                                <button onClick={setRecoveryPass} type='button' className='forgetPasswordBtn'>{t('loginForgetPass')}</button>
                                </Col>
                        </div>
                        <Row className='mt-3 mb-3'>
                            {/* <Col className='d-flex justify-content-center align-items-center pr-0' xs={1}>
                            <input 
                            onChange={onChange} 
                            name='remenber'
                            checked={remenber}
                            value={!remenber} 
                            className='checkboxLogSign' 
                            type='checkbox'></input></Col>
                            <Col xs={11}><label className='labelChekLogSign'> {t('loginRememberPass')} </label></Col> */}
                        </Row>
        </>
    )
}

export default React.memo(LoginInput)
