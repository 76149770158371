import React, { useEffect, useState } from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import SearchPopUp from '../SearchPopUp'
import {Col,  Row,/* Container, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
DropdownItem */} from 'reactstrap';
//import UserCircle from '../UserCircle'
import NavSection from './NavSection'
import './styles.css'
import jwt_decode from 'jwt-decode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {logo, logoW} from '../../utils/imgImports'
import UserCircleLog from '../UserCircleLog'
import ConfigUser from '../ConfigUser'
import UserCircle from '../UserCircle';
import { useTranslation } from 'react-i18next';
import ChangeLngBtn from '../ChangeLgnBtn';
import NotificationBell from './NotificationBell';
import Routes from '../../utils/Routes';

function AppBar({
    onOpenNotification,
    children, 
    searchCity,
    background = 'backgroundTransparent', 
    istoken, onSignOut, 
    onRegister, onLogin,
    showMobileSearch,
    setShowMobile,
    mobileSearchSubmit,
    disableSearchBar,
    disableLogin,
    onCreateZeroCalendar,
    mobileContent,showSignUp, 
    oncitiesPop=()=>{},
    onClose=()=>{},
}) {
    const history = useHistory()
    const location = useLocation()
    const [user, setUser] = useState(undefined)
    const [mobileMenu, setMobileMenu] = useState('0px')
    const [config, setConfig] = useState(false)
    const [mobileSearch, setMobileSearch] = useState(false)
    const movToPrincipal = () => {
        if(location.pathname === '/') return
        history.push('/')
    }
    useEffect(() => {
        setMobileSearch(showMobileSearch)
        return () => {
            
        }
    }, [showMobileSearch])
    useEffect(() => {
        const user = istoken && jwt_decode(istoken).user
        setUser(user)
      return () => {

      }
    }, [istoken])
    
    const { t } = useTranslation();
    const localCalendars= JSON.parse(localStorage.getItem('calendars'))||[]
    return (
        <>
        <div className={`appbarCont ${background} ${mobileMenu === '100vh' && 'forceBackWhite'}`} 
        style={{zIndex:(mobileMenu === '100vh') && 501, maxWidth:'100vw'}}
        >
            <div className=' justify-content-between d-flex h-100'>
                
                <div className='logoSearchCont  p-0'>
                    <div onClick={movToPrincipal} className='appbarLogo'>
                        {(background==='backgroundTransparent') ? <img alt='logo' src={logo}></img>:
                        <>
                        <img className='d-none d-md-block' alt='logo' src={logoW}></img>
                        <img className='d-block d-md-none' alt='logo' src={logo}></img>
                        </>}
                    </div>
                    
                </div>
                {/**/}
                
                    {(localCalendars.length>=1 ||istoken) ? <NavSection className='d-none d-md-block'>
                    {children}
                    <div className='h-100'>
                        <Link className={`navAppbar ${location.pathname.toLowerCase().includes('calendars') && 'selectedPage'}`} to={Routes.calendars()} >
                        <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> {t('appbarItinerarios')}</Link> 

                        {user?.redactor && <Link className={`navAppbar ${location.pathname.toLowerCase().includes('createBlog') && 'selectedPage'}`}  to={Routes.myBlogs()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('myBlogsAppbar')}</Link>}

                         {(user?.isAgency || user?.isHotel) && <Link className={`navAppbar ${location.pathname.toLowerCase().includes('createBlog') && 'selectedPage'}`}  to={Routes.Bussiness()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('Agency')}</Link>}
                         {user?.isInfluencer && <Link className={`navAppbar ${location.pathname.toLowerCase().includes('createBlog') && 'selectedPage'}`}  to={Routes.BussinessInfluencer()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> Mi espacio</Link>}
                        </div>
                    {/* <div><Link className={`navAppbar ${location.pathname.toLowerCase().includes('calendars') && 'selectedPage'}`} to={Routes.calendars()} >
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.50798 8.38048L2.5107 8.38171L7.0235 10.418L9.04836 14.8989C9.14733 15.1748 9.32858 15.4143 9.56838 15.5847C9.82586 15.7676 10.1365 15.8605 10.4521 15.8491C10.7677 15.8376 11.0708 15.7224 11.3144 15.5213C11.5405 15.3346 11.7037 15.0834 11.7827 14.8021L16.1841 2.99108C16.2834 2.75649 16.3131 2.49794 16.2691 2.24645C16.2228 1.98222 16.0975 1.73824 15.9096 1.54679C15.7217 1.35534 15.4801 1.22545 15.2168 1.1743C14.9628 1.12498 14.7002 1.15112 14.4613 1.24918L2.60567 5.67308C2.60538 5.67319 2.60508 5.6733 2.60478 5.67341C2.33311 5.7744 2.09748 5.95369 1.9277 6.1886C1.75774 6.42378 1.66142 6.70412 1.65095 6.9941C1.64049 7.28407 1.71635 7.57063 1.86893 7.81744C2.0215 8.06425 2.24392 8.26021 2.50798 8.38048Z" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('appbarItinerarios')}</Link> </div> */}
                    </NavSection> : <NavSection className='d-none d-md-block'>
                    {children}
                    </NavSection>}
                <Col className='p-0 d-flex'> 
                    {!(mobileMenu === '100vh') && <div className='d-flex d-md-none justify-content-center align-items-center w-100'>
                            
                            {(mobileSearchSubmit && !disableSearchBar ) ? <div
                            onClick={()=>{
                            !mobileSearch && 
                            setMobileSearch(true)
                            }}
                            className='SearchInfoCont w-100 mr-2 cursor-pointer' >
                            <div
                            style={{
                                backgroundColor:'white',
                                fontSize:'12px',
                                height:'30px',
                                color:'#7E96AE'
                            }}
                            className='searchinput d-flex align-items-center'>{t('appbarSearchPlaceHolder')}</div>
                            <div
                            style={{
                                height:'20px',
                                width:'20px',
                                fontSize:'10px'
                            }} className='searchbutton rounded-circle d-flex justify-content-center align-items-center' type='submit'>
                            <FontAwesomeIcon icon='search'></FontAwesomeIcon>
                            </div>
                            
                        </div> : mobileContent}
                    </div>}
                </Col>
                    
                
                <ChangeLngBtn className='langBtn'></ChangeLngBtn>
                <div style={{width:'max-content'}} className={`usercircleLogCont align-items-center justify-content-around ${background !== 'backgroundTransparent' &&  'border-left'} p-0`}>
                {(!(background==='backgroundTransparent')&& (!istoken)) && <div className='emojiCont d-inline-block ml-2 mr-2'><img style={{height:'32px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f91f.png'></img></div>}
                {user?.agency && <NotificationBell
                    agencyId={user?.agency}
                    onOpenNotification={onOpenNotification}
                    />}
                <UserCircleLog
                    simpleLogin={!(background==='backgroundTransparent')}
                    disableLogin={disableLogin}
                    openConfig={()=>{setConfig(true)}}
                    onSignOut={onSignOut} 
                    onRegister={onRegister} 
                    onLogin={onLogin} 
                    user={user}>
                </UserCircleLog>
                </div>

                <div onClick={() => {
                    if(mobileMenu === '0px') {
                        setMobileMenu('100vh')
                    } else {
                        onClose()
                        setConfig(false)
                        setMobileMenu('0px')
                    }
                }} id='toggler' className={`mobilemenu ml-4`}>
                        <FontAwesomeIcon color={'#00264D'} size='2x' icon={mobileMenu === '100vh' ? 'times' : 'bars'}/>
                </div>
                <div className='mobileMenuCollapse' toggler="#toggler" style={{maxHeight: mobileMenu}}>
                    <div className='mobileMenuCont'>
                        {istoken ? 
                        <>
                            <NavSection hideContent={config} type='mobile'>
                            {children}
                            <Link className={`optionsUserbtn text-center ${location.pathname.toLowerCase().includes('calendars') && 'selectedPage'}`}  to={Routes.calendars()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('appbarItinerarios')}</Link>
                            {user?.redactor && <Link className={`optionsUserbtn text-center`}  to={Routes.myBlogs()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('myBlogsAppbar')}</Link>}
                            </NavSection>
                            <Row className='border-top ' style={{margin:'10px'}}>
                                <Col className='d-flex mb-4 justify-content-center' xs={12}> 
                                    {!config && <Col className='pl-0 d-flex align-items-center justify-content-start'>
                                        <UserCircle className='text-uppercase' borderGray  diametro='60px' fSize='28px'>
                                        {user?.name?.[0]}{user?.lastName?.[0]}
                                        </UserCircle>
                                        <p style={{fontSize: '18px'}} className='userDes text-truncate ml-2 mb-0'>{user?.name} {user?.lastName}</p>
                                    </Col>}
                                </Col>
                                <Col className='d-flex justify-content-center'>
                                    <button onClick={onSignOut} className='optionsmobileUserbtn smallBtnMobile' type='button'> <div className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/270c-fe0f.png'></img></div>  {t('appbarLogOut')}</button>
                                </Col>
                                <Col className='d-flex justify-content-center'>
                                    <button onClick={()=>{setConfig(!config)}} className={`optionsmobileUserbtn smallBtnMobile ${config && 'optionsmobileUserbtnSelected'}`} type='button'><div className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f527.png'></img></div> {t('appbarConfig')}</button>
                                </Col>
                            </Row>
                            
                        </>
                        : 
                        <>
                        <NavSection type='mobile'>
                        {children}
                        
                            {(localCalendars.length>=1) && <Link className={`optionsUserbtn text-center ${location.pathname.toLowerCase().includes('calendars') && 'selectedPage'}`}  to={Routes.calendars()} >
                                <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> {t('appbarItinerarios')}
                            </Link>}
                            {user?.redactor && <Link className={`optionsUserbtn text-center`}  to={Routes.myBlogs()} >
                            <svg className='mr-1' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.800491 2.63879V2.63933V13.418C0.792928 13.6864 0.87288 13.9502 1.02854 14.1695C1.18768 14.3936 1.4169 14.5584 1.68004 14.6379L1.68004 14.6379L1.68542 14.6395L5.03782 15.6226C5.37758 15.7263 5.74069 15.7258 6.08021 15.621L6.08022 15.621L6.08608 15.6191L9.56093 14.5128C9.63838 14.4899 9.72079 14.4899 9.79823 14.5129L12.897 15.4957L12.8986 15.4962C13.0836 15.5543 13.2797 15.5682 13.4711 15.5367C13.6625 15.5052 13.8438 15.4292 14.0004 15.3147C14.157 15.2003 14.2845 15.0507 14.3726 14.8779L13.7491 14.5598L14.3727 14.8779C14.4608 14.7052 14.5071 14.5141 14.5079 14.3201V14.3174V3.70725C14.5164 3.4428 14.44 3.18237 14.2895 2.96431C14.1352 2.74058 13.9114 2.57388 13.6529 2.48994L13.6509 2.48931L10.1868 1.37583L10.1703 1.37074C9.85618 1.27829 9.5225 1.27646 9.20763 1.36526L5.65112 2.22096C5.58948 2.23437 5.5257 2.23455 5.464 2.22148L2.33755 1.43853L2.33755 1.43853L2.3355 1.43802C2.15322 1.39295 1.96308 1.38995 1.77946 1.42922C1.59585 1.4685 1.42357 1.54903 1.27568 1.66472C1.12779 1.78041 1.00815 1.92823 0.925821 2.09699C0.843494 2.26575 0.800636 2.45102 0.800491 2.63879Z" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.55566 2.49991V14.8929" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.68262 2.17139V14" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> {t('myBlogsAppbar')}</Link>}
                        </NavSection>
                        <Row className='border-top'   style={{margin:'10px'}}>
                            <Col className='d-flex mb-4 justify-content-center' xs={12}> 
                                {!showSignUp && <Col className='pl-0 mt-3 d-flex align-items-center justify-content-center'>
                                    <div className='emojiCont d-inline-block mr-1'>
                                        <img style={{height:'60px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f918.png'></img>
                                    </div>
                                    <p style={{fontSize: '18px'}} className='userDes text-truncate ml-2 mb-0'>{t('appbarUnregisterMsg')}</p>
                                </Col>}
                            </Col>
                            <Col className=' p-0' onClick={disableLogin ? ()=> {}:onLogin}><div><p className={`optionsmobileUserbtn m-0 ${disableLogin && 'optionsmobileUserbtnDisabled'}`}>
                            <span className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44b.png'></img></span>
                            {t('appbarLogin')}</p></div></Col>
                            <Col className='pr-0' onClick={disableLogin ? ()=> {}:onRegister}><div><p className={`optionsmobileUserbtn m-0  ${disableLogin && 'optionsmobileUserbtnDisabled'}`}>
                            <span className='emojiCont d-inline-block mr-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/2764-fe0f.png'></img></span>
                            {t('appbarSignUp')}</p></div></Col>
                        </Row>
                        </>
                        
                        }
                        
                    </div>
                </div>
                {/**/}
            </div>
        </div>  
        
        {(config && istoken) && <ConfigUser 
                    id={istoken &&user?._id}
                    name={istoken &&user?.name}
                    email={istoken && user?.email}
                    onLogin={()=>{}} 
                    onSignUp={()=>{}}
                    onClose={() => {
                        setConfig(false)
                    }}/>}
        {mobileSearch && <SearchPopUp
                searchCity={searchCity}
                oncitiesPop={oncitiesPop}
                onSubmit={(a,b) => {
                    mobileSearchSubmit(a,b);
                    setMobileSearch(false);
                    showMobileSearch && setShowMobile(false)
                    } }
                onCreateZeroCalendar={(a,b)=> {
                    onCreateZeroCalendar(a,b);
                    setMobileSearch(false);
                    showMobileSearch && setShowMobile(false)
                    }}
                onClose={()=>{
                    setMobileSearch(false);
                    showMobileSearch && setShowMobile(false)
                }}
                    />
        }
    </>  
    )
}
export default React.memo(AppBar)
/*
                <div><p href='#l'><FontAwesomeIcon size='1x' icon='heart'/> Mis Favoritos</p> </div>
                <div><p href='#l'><FontAwesomeIcon size='1x' icon='heart'/> Mis visitas</p> </div>
<div className=>
            
<div className='headerCont'>
                    <Row >
                        <Col xs={16}  md={4}><a href='#l'><img alt='corazon' src={corazon}></img> Mis favoritos</a></Col>
                        <Col xs={16}  md={4}><a href='#l'><img alt='raton' src={raton}></img> Mis visitas</a></Col>
                        <Col xs={16}  md={4}><a href='#l'><img alt='mapa' src={mapa}></img> {t('appbarItinerarios')}</a></Col>
                        {/*<Col xs={12}  md='auto'><a href='#l'> Mis lugares</a></Col>/}
                        </Row>                    
                        </div>
                        <div className='headerCont'>
                            <Row>
                                
                                <Col xs={12}  md={3}></Col>
                                <Col xs={12}  md={5}  offset={{ md: 2 }}><a href='#l'>Inicia sesión</a></Col>
                                <Col xs={12}  md={4} ><button href='#l'>Súmate</button></Col>
                            </Row>
                        </div>
            
            <FontAwesomeIcon size='1x' icon='heart'/>
<FontAwesomeIcon size='1x' icon='heart'/>
        </div>
*/