import React, { useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import api from "../utils/axiosApi";
import Routes from "../utils/Routes";
import LoadingCurtain from "../components/LoadingCurtain";

function ProcessPaymentPage() {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const processPayment = async () => {
      console.log(params);
      debugger
      //preguntar por el recurso externo
      const { data: payment } = await api.patch(
        `/payments/${params.idPayment}`,
        {status: "payed"}
      );
      if (payment.data.type === "model_itinerary") {
        const { data: clonedCalendar, error } = await api.post(
          `/ModelsCalendar/clone/${payment.data.resource_id}`,
          {
            payment_id: payment.data._id,
          }
        );
        if (error) return;
        history.push(
          Routes.calendar(
            clonedCalendar.data._id
          )
        );
      }
      debugger;
    };

    processPayment();
    return () => {};
  }, []);

  return <div><LoadingCurtain
  zIndex={650}
  background={"rgba(255, 255, 255, 0.507)"}
  isLoading={true}
/></div>;
}

export default ProcessPaymentPage;
