import React from 'react'
import {  Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

function LoginInput({onChange, userEmail, userPass, remenber, goEdit}) {
    const { t } = useTranslation();
    return (
        <>
                        <div>
                            <label className='labelLogSign'>{t("signUpEmail")}:</label>
                            
                            <Row className='inputLogSign border-0 m-0 justify-content-center align-items-center'>
                                <Col className="p-0" xs={8}>
                                <p style={{color:'#7E96AEAD'}} className='m-0'>{userEmail}</p></Col>
                                <Col className="p-0 d-flex justify-content-end"  xs={4}>
                                <button style={{color:'#7E96AEAD'}} className='btnGoEdit' onClick={goEdit} type='button'>{t("noEdit")}</button>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <label className='labelLogSign'>{t("signUpPass")}:</label>
                            
                            <Row className='inputLogSign border-0 m-0 justify-content-left align-items-center'>
                                <Col className="p-0 iconInputEditUser"  xs={1}><FontAwesomeIcon icon='lock'/></Col>
                                <Col className="p-0" xs={8}>
                                <p style={{color:'#7E96AEAD'}} className='m-0 pt-1'> ***************</p></Col>
                                <Col className="p-0 d-flex justify-content-end"  xs={3}>
                                <button className='btnGoEdit' onClick={goEdit} type='button'>{t("change")}</button>
                                </Col>
                            </Row>
                        </div>
        </>
    )
}

export default React.memo(LoginInput)
