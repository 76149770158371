import _ from "lodash";
import moment from "moment";
import { startTimeCalendar } from "../const";

/**
 *
 * @param {Array} ArrayActivites List of activities that needed set split by days
 * @returns
 */
export function findDaysRows(ArrayActivites = []) {
  let actualStartDate;
  const objelements = {};
  if (ArrayActivites.length === 0) {
    return objelements;
  }
  let copyItems = ArrayActivites.map((item) => moment(item.endDateTime).unix());
  let start = moment(ArrayActivites[0].startDateTime);
  if (ArrayActivites[1]?.startDateTime < ArrayActivites[0]?.startDateTime) {
    start = moment(ArrayActivites[1].startDateTime);
  }
  const day = moment(`${start.format("YYYY/MM/DD")} ${startTimeCalendar}:00`);
  const diffday = start.diff(day, "hours", true);
  if (diffday < 0) {
    actualStartDate = day.subtract(24, "hours");
  } else {
    actualStartDate = day;
  }
  let counter = 0;
  let conterror = copyItems.length;
  let pastCounter;
  do {
    let prueba = _.sortedIndex(
      copyItems,
      moment(actualStartDate).add(1, "day").unix()
    );
    if (prueba === 0) {
      prueba++;
    }
    copyItems.splice(0, prueba);
    counter = counter + prueba;
    objelements[moment(actualStartDate).format("YYYY/MM/DD")] = counter;
    if (counter < ArrayActivites.length) {
      if (pastCounter === copyItems[0]) {
        copyItems.splice(0, 1);
        counter = counter + 1;
        objelements[moment(actualStartDate).format("YYYY/MM/DD")] = counter;
      }
      start = moment(ArrayActivites[counter]?.startDateTime);
      const day = moment(
        `${start.format("YYYY/MM/DD")} ${startTimeCalendar}:00`
      );
      const diffday = start.diff(day, "hours", true);
      if (diffday < 0) {
        actualStartDate = day.subtract(24, "hours");
      } else {
        actualStartDate = day;
      }
    }
    pastCounter = copyItems[0];
    conterror--;
  } while (copyItems.length > 0 && conterror > 0);
  return objelements;
}

export function findActualTemporada({startDate, workingH}) {
  
  const momentDate = moment(startDate);
  if (workingH) {
    const keys = Object.keys(workingH).sort((a, b) => {
      const dateA = moment(`${a} 00:00`);
      const dateB = moment(`${b} 00:00`);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    let actualTemp = keys.find((date) => {
      const mDat = moment(`${date} 00:00`);
      const dife = mDat.diff(momentDate, "hours", true);
      return dife <= 0;
    });
    if (!actualTemp) {
      actualTemp = keys[keys.length - 1];
    }
    return actualTemp;
  }
  return undefined;
}
