import React from 'react'
import { Col } from 'reactstrap'
import './styles.css'

const NavSection = ({children, type, className='', hideContent}) => {
    return (
        <Col className={`${!type ? 'navegation' : 'navegationMobile'} justify-content-start ${className}`}>
            
            {!hideContent && children}
        </Col>
    )
}

export default React.memo(NavSection)