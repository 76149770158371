import jwtDecode from "jwt-decode";
import { setcalendarlist, setcalendarlistCount, setcalendarlistPage, setUserShopCarList } from ".";
import api from "../../utils/axiosApi";
import { LOGIN_USER, NOTIFICATIONS_USER, SET_USERS_MSG_SOCKET, SET_USERS_SOCKET, SIGNOUT_USER } from "../const";
import LogRocket from "logrocket";
import Cookies from "universal-cookie";

const cookies = new Cookies();

//funciones para el chat que no esta operativo por rediseño
export const setUsersSocket = (value) => ({ type: SET_USERS_SOCKET, value });
export const setUsersMsgsSocket = (value) => ({
  type: SET_USERS_MSG_SOCKET,
  value,
});
export const setUsersNotifications = (value) => ({
  type: NOTIFICATIONS_USER,
  value,
});
/*-----------------------------------------------------------------------login----------------------*/

export const setLogin = (value) => ({ type: LOGIN_USER, value });
//const setSignup = value => ({type: SIGNUP_USER, value});
export const signout = (value) => ({ type: SIGNOUT_USER, value });

export const setLoginUser = (payload) => {
  return async (dispatch, getState) => {
    try {
      debugger
      //se loguea el usuario
      const resp = await api.post(`/user/login`, payload, {
        withCredentials: true,
      });

      const { data, error /*meta*/ } = resp.data;
      if (error) return;
      const { user } = jwtDecode(data.token);

      LogRocket.identify(`${user._id}`, {
        name: `${user.name} ${user.lastNname}`,
        email: `${user.email}`,

        // Add your own custom user variables here, ie:
      });
      //se guarda el token en caso de presionar que se desea guardar
      if (payload.remenber) {
        //cookies.set('jwtuser', data.token )
        localStorage.setItem("jwtuser", data.token);
      } else {
        window.sessionStorage.setItem("jwtuser", data.token);
        //cookies.set('jwtuser', resp.data.data.token,{maxAge: 1000 * 60 *60 *12} )
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      dispatch(setLogin(data.token));
    } catch (error) {
      dispatch(setLogin(""));
      //console.log(error)
      return error?.response?.data?.message;
    }
  };
};
//desloguea el usuario y elimina el token
export const setSignout = (payload) => {
  return async (dispatch, getState) => {
    try {
      await api.get(`/user/logout`, { withCredentials: true });

      const token =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      if (token && token !== "") {
        localStorage.removeItem("jwtuser");
        window.sessionStorage.removeItem("jwtuser");
      }
      cookies.set("jwtuser", "", { maxAge: 12 });
      delete api.defaults.headers.common["Authorization"];

      var newListShop = [];
      const data = {
        list: newListShop,
      };
      dispatch(setUserShopCarList(data));
      dispatch(setcalendarlist([]));
      dispatch(setcalendarlistPage(0));
      dispatch(setcalendarlistCount(0));
      dispatch(signout());
    } catch (error) {
      dispatch(setLogin(""));
      //console.log(error)
      return false;
    }
  };
};
//crea un nuevo uduario
export const setSignupUser = ({ payload, len, isAgency, IsInfluencer }) => {
  return async (dispatch, getState) => {
    try {
      if(IsInfluencer) payload.level = 'influencer'
      const resp = await api.post(`/user/signup?len=${len}`, payload, {
        withCredentials: true,
      });

      const { data, error /*meta*/ } = resp.data;
      let tokenUser = data.token;
      let { user } = jwtDecode(tokenUser);

      if (error) return;
      let extraUrl =""
      if(isAgency) extraUrl = "/agencias/CreateAgency"
      if(IsInfluencer) extraUrl = "/influencer/"
      if (extraUrl) {
        /*const agency = */ await api.post(
          extraUrl,
          {
            userId: user._id,
            nameAgency: user.name,
            legalNameAgency: user.lastName,
            emailPublico: user.email,
            urlId: user.name.replace(/\s+/g, ""),
            idImg: "",
            urlImg: "",
          },
          {
            headers: {
              Authorization: `Bearer ${tokenUser}`,
            },
          }
        );
        const newTokenRequest = await api.get("/user/updateToken", {
          headers: {
            Authorization: `Bearer ${tokenUser}`,
          },
        });
        const { data, error } = newTokenRequest.data;
        if (error) {
          return;
        }
        tokenUser = data.token;
        user = jwtDecode(tokenUser).user;
      }

      localStorage.setItem("jwtuser", tokenUser);
      LogRocket.identify(`${user._id}`, {
        name: `${user.name}`,
        email: `${user.email}`,
        // Add your own custom user variables here, ie:
      });
      dispatch(setLogin(tokenUser));
    } catch (error) {
      dispatch(setLogin(""));
      const { keyPattern } = error.response.data.message;
      //console.log(error)
      return keyPattern;
    }
  };
};
/*
export const setSignupCustomer = (payload) => {
    return async (dispatch, getState) => {
        try {
            const resp = await api.post(`/user/signupcustomer`, payload)
            const token = localStorage.getItem('jwtuser') //cookies.get('jwtuser')
            if(!token) {
                cookies.set('jwtuser', resp.data.token, {maxAge: 1000 * 60 *60 *24} )
            }
            dispatch(setLogin(resp.data.token))
        } catch (error) {
            dispatch(setLogin(''))
            //console.log(error)
        }
    }
}*/
