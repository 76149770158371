import moment from "moment";

const SortDate = (array) => {
  const arrayToSort = array.map((it, index) => ({
    index,
    value: moment(it.endDateTime),
  }));
  arrayToSort.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    // a debe ser igual b
    return 0;
  });
  return arrayToSort.map((item) => {
    return { ...array[item.index] };
  });
};
export const SortDateStart = (array) => {
  
  const arrayToSort = array.map((it, index) => ({
    index,
    value: moment(it.startDateTime),
  }));
  arrayToSort.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    // a debe ser igual b
    return 0;
  });
  return arrayToSort.map((item) => {
    return { ...array[item.index] };
  });
};

export default SortDate;
