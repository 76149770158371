import React, { Component } from 'react';
import { Col, Row} from 'reactstrap';
import './styles.css'
import {withTranslation } from 'react-i18next';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { emailSchema } from '../../utils/validateForm';
import api from '../../utils/axiosApi';
class ModalContactBusiness extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOptions:false,
            disabled:true,
            email:''
        }
        this.setShowOptions =this.setShowOptions.bind(this)
        this.handleChangeEmail =this.handleChangeEmail.bind(this)
        this.handleSend=this.handleSend.bind(this)
    }

    setShowOptions(next) {
        this.setState({
            showOptions:next
        })
    }
    handleSend = async ()=>{
        const emailA= {email:this.state.email}
        await api.post('/PotentialAgencies/setEmail', {emailA})
        this.setState({email:''})
    }
    handleChangeEmail(e) {
        this.setState({email: e.target.value})
        const {disabled} = this.state
        const emailValidate = emailSchema.validate(e.target.value)
        if(emailValidate.error && !disabled) {
            this.setState({disabled:true})
        } else if( !emailValidate.error && disabled) {
            this.setState({disabled:false})
        }
    }
    render() {
        
        const { t } = this.props;
        return (
            <>
            <div onClick={()=>{this.props.onClose()}} className='backgroundPopUp'></div>
            <div className='totalPopUpUpperCont' style={{zIndex:"1000", width:'70%', left:'calc( 50% - 35vw )'}}>
            <div  className='popUpAgency landingBusinessCont justify-content-center justify-content-md-start position-relative'
             style={{overflow:'hidden',width:'100%'}}>
                <Row className='ml-0 mr-0 h-100' onClick={() => {this.state.showOptions && this.setShowOptions(false)}}>
                    <Col className='d-flex flex-column'  xs={12} md='12' lg={12}>
                        <div className=' d-flex flex-column justify-content-md-center'>
                            <div className='d-flex flex-column align-items-start align-items-md-start'>
                                <h2 className='titleLanding'>
                                    
                                    {t('areYouAgencyTitle')}
                                </h2>
                            
                                <p className='subtitlelandingBusiness d-none d-md-block mb-4'>
                                    {t('areYouAgencyDescription')}
                                </p>
                            </div>
                            {
                                //barra buscadora
                            }


                            <Row className='searchFlightCont align-items-center mb-4 d-none d-md-flex' style={{width: '100%'}}>
                                <Col  className=''><input value={this.state.email} onChange={this.handleChangeEmail} placeholder={t('workWithUs')} type='mail'></input></Col>
                                <Col xs={4} md='2' lg={3} xl='4' className='p-0' style={{maxWidth:'150px '}}><button type='button' disabled={this.state.disabled} onClick={this.handleSend}>{t('bookingConfirmPhoneTypeLabel')}</button></Col>
                            </Row> 
                            
                        </div>
                    </Col>
                        
                </Row>
                
                <Col  xs={12} md='5' lg={6} className='pr-0 d-none d-md-block'>
                        <div className='imagenBackBusiness' alt='img1'></div>
                    </Col>
            </div>
            </div>
            </>
        );
    }
}

export default withTranslation()(ModalContactBusiness);