import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import api from './axiosApi'

import LogRocket from 'logrocket';
const startSession =moment()

function ChangePageMetrics({children}) {
    const auth = window.sessionStorage.getItem('jwtuser')|| localStorage.getItem('jwtuser') //cookies.get('jwtuser')
    const location = useLocation()
    useEffect(() => {
        try {
            switch (location.pathname) {
            case '/':
                api.post('/metrics/setMetric',{
                    type:'changePage',
                    urlDestiny:location.pathname,
                    value:'landing'
                }, {
                    headers : {
                        Authorization: `Bearer ${auth}`
                    }
                })
                break;
            /*case '/flights':
                api.post('/metrics/setMetric',{
                    type:'changePage',
                    urlDestiny:location.pathname,
                    value:'Cheapflights'
                })
                break;
            */
            default:
                break;
            }
            
        } catch (error) {
            //console.log(error)
        }
    return () => {
        
    }
    }, [location.pathname])
    useEffect(() => {
        let prevUrl = document.referrer;
        if(!location.pathname.includes('/admin')){
            LogRocket.init('iykrlb/tripendar');
        }
        let value = ''
        if(prevUrl.includes('facebook')){
            value = 'facebook'
        }else if(prevUrl.includes('linkedin')){
            value = 'linkedin'
        }else if(prevUrl.includes('instagram')){
            value = 'instagram'
        }else if(prevUrl.includes('twitter')){
            value = 'twitter'
        } 
        if(value){
            api.post('/metrics/setMetric',{
                type:'ArrivalFrom',
                urlDestiny:prevUrl,
                value:value
            }, {
                headers : {
                    Authorization: `Bearer ${auth}`
                }
            })

        }
        window.addEventListener('beforeunload', function (e) {
            let diferencia= moment().diff(startSession,'minutes',true)
            e.preventDefault();
            try {
                api.post('/metrics/setMetric',{
                    type:'FinishSession',
                    urlDestiny:'',
                    value:`${diferencia.toFixed(4)}`
                }, {
                    headers : {
                        Authorization: `Bearer ${auth}`
                    }
                })
            } catch (error) {
                //console.log(error)
            }
        });
    return () => {
        
    }
    }, [])
    
    return (
    <>{children}</>
    )
}

export default (ChangePageMetrics)