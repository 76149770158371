import React from 'react'
import { Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

function ChangingPass({onChange, newPass,renewPass,oldPass,userName,userLastname, accept}) {
    const { t } = useTranslation();
    return (
        <>
            <div>
                <label className='labelLogSign'>{t("actualPass")}</label>
                
                <Row className='inputLogSignCont position-relative justify-content-center align-items-center m-0'>
                    
                    <div style={{left:'7px', top:'0px',bottom:'0px'}} className="position-absolute p-0 d-flex justify-content-center align-items-center iconInputEditUser" >
                    <FontAwesomeIcon icon='lock'/>
                    </div>
                    <input 
                    onChange={onChange} 
                    name='oldPass' 
                    value={oldPass}
                    className='w-100 h-100 border-0 pl-4' 
                    type='password'></input>

                </Row>
            </div>
            <div>
                <label className='labelLogSign'>{t("newPass")}</label>
                
                <Row className='inputLogSignCont position-relative justify-content-center align-items-center m-0'>
                    
                    <div style={{left:'7px', top:'0px',bottom:'0px'}} className="position-absolute p-0 d-flex justify-content-center align-items-center iconInputEditUser" >
                    <FontAwesomeIcon icon='lock'/>
                    </div>
                    <input 
                    onChange={onChange} 
                    className='w-100 h-100 border-0 pl-4' 
                    value={newPass}
                    name='newPass' 
                    type='password'></input>

                </Row>
            </div>
            <div className='mb-3'>
                <label className='labelLogSign'>{t("repeatPass")}</label>
                
                <Row className='inputLogSignCont position-relative justify-content-center align-items-center m-0'>
                    
                    <div style={{left:'7px', top:'0px',bottom:'0px'}} className="position-absolute p-0 d-flex justify-content-center align-items-center iconInputEditUser" >
                    <FontAwesomeIcon icon='lock'/>
                    </div>
                    <input 
                    onChange={onChange} 
                    className='w-100 h-100 border-0 pl-4' 
                    value={renewPass}
                    name='renewPass' 
                    type='password'></input>

                </Row>
            </div>
        </>
    )
}

export default React.memo(ChangingPass)
