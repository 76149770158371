
import React, { useState } from 'react'
import {  Col, Row } from 'reactstrap'
import {passwordSchema } from '../../utils/validateForm'
import ConfirmEmail from './ChangingPass'
import ViewConfig from './ViewConfig'
import './styles.css'
import api from '../../utils/axiosApi'
import withReactContent from 'sweetalert2-react-content'
import { option } from '../../utils/sweetalert'
import ConfirmEmailPop from '../ConfirmEmailPop'
import { useTranslation } from 'react-i18next';

const PassValidator = {
    oldPass: (e) => passwordSchema.validate(e),
    newPass: (e) => passwordSchema.validate(e),
    renewPass:(e) => passwordSchema.validate(e),
}

function ConfigUser({error=false,id='',email='', name='', onSignUp=() => {}, onLogin=() => {}, onClose=() =>{} }) {
    const [confirmPage, setConfirmPage] = useState(false)
    const [data, setData] = useState({
        oldPass: '',
        newPass: '',
        renewPass:'',
        remenber:false,
        accept:false,
    })
    const { t,i18n } = useTranslation();
    const [isError, setIsError] = useState(error)
    const [disabled, setDisabled] = useState(false)
    const [confirmEmail, setConfirmEmail] = useState(false)
    const [wrongPassword, setWrongPassword] = useState(false);

    const handlepassLogin = (e) => {
        let err = false
        for(const [key, value] of Object.entries(PassValidator)){
            const validate = value(data[key])
            if(validate.error && !isError) {
                err = (true)
            }
          }
        if((data['newPass']!==data['renewPass'])) {
            err= true
        }
          return err
    }
    const validPasses = async (e) => {
        e.preventDefault()
        try{
            const err = handlepassLogin()
            /*const resp = */await api.post(`/user/login`, {
                email: email,
                password: data.oldPass,
                remenber: false
            }, {withCredentials: true})
            setWrongPassword(false)
            if(err) {

            setIsError(err)
            } else {
                setConfirmEmail(true)
                //onClose()
            }
        } catch(error){
            setWrongPassword(true)
        }
    }
/*
    const validatePassword = async (e) =>{
        e.preventDefault();
        
    }
*/

    const handleSubmit = async (e) => {
        if(confirmPage) {
            setDisabled(true)
            const MySwal = withReactContent(option)
            try {
                const respdb = await api.put(`/user/updateUser/${id}`, {
                    name: name,
                    email: email,
                     password: data['oldPass'],
                     newPassword:data['newPass']
                  })
                    
                    if(!respdb.data.error) {
                        
                        await api.get(`/Email/alertChangePass?email=${email}&len=${i18n.language}`)
                        onClose()
                        await MySwal.fire({
                            background:'#fff',
                            customClass:'confirmName',
                            confirmButtonText: '<img style="height: 25px" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44d.png"></img>',
                            html:`
                            <div class='sweetPopHeader'>
                                <h2 style='font-size: 22px' class='sweetPopTittle'>${t("weAreReady")} <div class='emojiCont d-inline-block ml-1'><img style='height:19px; margin-top:0' alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/2705.png'></img></div></h2>
                                <p class='sweetPopSubTittle'>${t("passwordChanged")}</p>
                            </div>
                            `,
                            confirmButtonColor:'#106AD4',
                            focusConfirm: false,
                            })
                    } else {
                        onClose()
                        await MySwal.fire({
                            background:'#fff',
                            customClass:'confirmName',
                            confirmButtonText: '<img style="height:19px" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44d.png"></img>',
                            html:`
                            <div class='sweetPopHeader'>
                                <h2 style='font-size: 22px' class='sweetPopTittle'>Ups... hubo un error</h2>
                                <p class='sweetPopSubTittle'>${respdb.data.data}  <img src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f62c.png" style="width:22px;height:22px;" alt=""></p>
                            </div>
                            `,
                            confirmButtonColor:'#106AD4',
                            focusConfirm: false,
                            })
                    }
            } catch (error) {
                onClose()
                await MySwal.fire({
                background:'#fff',
                customClass:'confirmName',
                confirmButtonText: 'Ok',
                html:`
                <div class='sweetPopHeader'>
                    <h2 style='font-size: 22px' class='sweetPopTittle'>Ups...</h2>
                    <p class='sweetPopSubTittle'>${t("serverError")}</p>
                </div>
                `,
                confirmButtonColor:'#106AD4',
                focusConfirm: false,
            })
                //console.log(error)
            }
            
            setDisabled(false)
        }
    }
    const handleChange = (e) => {
        const {value,name} = e.target
        if(isError) setIsError(false);
        if(name=== 'remenber' || name=== 'accept') {
            setData({...data,
            [name]: value==='true'
            })
            return
        }
        setData({...data,
            [name]: value
            })
    }
    return (
        <>
            <div className='popUpBodyCont' style={{top: (confirmPage || confirmEmail) ? '20%' : '30%'}}>
                <div  className='popUpLogSignCont popUpForceWidth' >
                    {!confirmEmail ? <form onSubmit={validPasses}>

                    <Col className='headerPopUp '>
                        
                            <Row className='justify-content-center'>
                            <h2>{t("appbarConfig")} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f527.png'></img></div></h2>
                            </Row>
                            {confirmPage&& <Row className='justify-content-center'>
                            <Col xs={10}><p>{t("youAreChanging")}</p></Col>
                            </Row>}
                    </Col>
                    <Col className='headerBody'>
                        
                        
                        {confirmPage ? <ConfirmEmail 
                        onChange={handleChange}
                        userEmail={data.userEmail}
                        userPass={data.userPass}
                        remenber={data.remenber}

                        /> : <ViewConfig 
                        goEdit={() => {setConfirmPage(true)}}
                        onChange={handleChange}
                        userEmail={email}
                        /> }
                    </Col>
                    {wrongPassword && <div>
                        <p className='pl-3 errormsg m-0'>{t("errActPass")}</p>                        
                    </div>}
                    {isError && <div>
                        <p className='pl-3 errormsg m-0'>{t("signUpErrorPass")}</p>
                        
                    </div>}
                    {confirmPage &&
                    <Row className='pl-4 pr-4'>
                    <Col>
                        <button disabled={disabled} onClick ={onClose} type='button' className='btnCancel'>
                            {t("cancel")}
                        </button>
                    </Col>
                    <Col>
                    <button disabled={disabled} className='btnCrear'>
                            {t("change")}
                        </button>
                    </Col>
                        
                    </Row>
                    }
                    </form> : <div>
                        
                        <ConfirmEmailPop
                        email={email}
                        isError={(isError)}
                        onClose={onClose}
                        onFailure={()=>{}}
                        onSuccess={handleSubmit}
                        />
                    </div>}
                </div>
                
            </div>
            <div onClick={onClose} className='backgroundPopUp  d-none d-md-block'></div>
        </>
        
    )
}

export default React.memo(ConfigUser)

