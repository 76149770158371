import img1Avif from '../img/image1.avif'
import img2Avif from '../img/image2.avif'
import img3Avif from '../img/image3.avif'
import img4Avif from '../img/image4.avif'
import logoAvif from '../img/logo.avif'
import logoWAvif from '../img/logoW.avif'
import img1Png from '../img/image1.png'
import img2Png from '../img/image2.png'
import img3Png from '../img/image3.png'
import img4Png from '../img/image4.png'
import logoPng from '../img/logo.png'
import logoWPng from '../img/logoW.png'

const imgs = navigator.userAgent.toLowerCase().indexOf('edg')<0 ? {
    img1: img1Avif,
    img2: img2Avif,
    img3: img3Avif,
    img4: img4Avif,
    logo: logoAvif,
    logoW: logoWAvif

}: {
    img1 : img1Png,
    img2 : img2Png,
    img3 : img3Png,
    img4 : img4Png,
    logo : logoPng,
    logoW : logoWPng
}

export const img1 = imgs.img1
export const img2 = imgs.img2
export const img3 = imgs.img3
export const img4 = imgs.img4
export const logo = imgs.logo
export const logoW = imgs.logoW