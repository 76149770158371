import React from 'react'
import {logoW} from '../../utils/imgImports'

function LoadingCurtain({isLoading, background= 'white', height='auto', zIndex=1}) {
    if(isLoading) {
        return <div className='loadingCurtainCont' style={{backgroundColor:background, zIndex: zIndex,height:height}}>
        <div className='loader-Cont'>
            <div className='loader loader1'></div>
            <div className='loader loader2'></div>
            <div className='loader-Logo'>

            <img className='d-block' alt='logo' src={logoW}></img>
            </div>
        </div>
    </div>
    } else {
        return (
            <></>
        )
    }
}

export default LoadingCurtain
