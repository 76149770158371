import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {  Col, Row } from 'reactstrap'
import withReactContent from 'sweetalert2-react-content'
import api from '../../../utils/axiosApi'
import { option } from '../../../utils/sweetalert'
import { emailSchema } from '../../../utils/validateForm'
import ConfirmEmailPop from '../../ConfirmEmailPop'


const emailValidate = (e) => emailSchema.validate(e)
function RecoveryPass({
    isError=false,
    onClose=()=> {},
}) {
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState('')
    const [user, setUser] = useState(undefined)
    const [error, setError] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const { t,i18n } = useTranslation();
    const handleConfirm = async () => {
        setDisabled(true)
        const correct = emailValidate(email)
        const MySwal = withReactContent(option)
        if(!correct.error){
            try {
                const resExist = await api.get(`/user/exist?email=${email}`)
                if(resExist.data.data) {
                    setUser(resExist.data.data)
                    setConfirm(true)
                } else {
                    onClose()
                    await MySwal.fire({
                        background:'#fff',
                        customClass:'confirmName',
                        confirmButtonText: 'Ok',
                        html:`
                        <div class='sweetPopHeader'>
                            <h2 style='font-size: 22px' class='sweetPopTittle'>${t('recoveryPassPopUpTitleRegister')}...
                            </h2>
                            <p class='sweetPopSubTittle'>${t('recoveryPassPopUpBodyRegister')}</p>
                        </div>
                        `,
                        confirmButtonColor:'#106AD4',
                        focusConfirm: false,
                        })
                }

            } catch (error) {
                    await MySwal.fire({
                    background:'#fff',
                    customClass:'confirmName',
                    confirmButtonText: 'Ok',
                    html:`
                    <div class='sweetPopHeader'>
                        <h2 style='font-size: 22px' class='sweetPopTittle'>Upss...
                        </h2>
                        <p class='sweetPopSubTittle'>Parece ser que hubo un error en nuestro servidor</p>
                    </div>
                    `,
                    confirmButtonColor:'#106AD4',
                    focusConfirm: false,
                    })
            }

        } else {
            setError('El Correo es incorrecto')
        }
        setDisabled(false)
        //onSuccess()
    }
    const handleSendRecoveryPass = async () => {
        
        const MySwal = withReactContent(option)
        try {
            /*const resExist =*/ await api.get(`/Email/ChangePass?email=${email}&id=${user._id}&len=${i18n.language}`)
            onClose()
            await MySwal.fire({
            background:'#fff',
            customClass:'confirmName',
            confirmButtonText: `<div class='emojiCont d-inline-block ml-1'><img style='height:19px; margin-top:0' alt="👍" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f44d.png"> </div>`,
            html:`
            <div class='sweetPopHeader'>
                <h2 style='font-size: 22px' class='sweetPopTittle'>${t('recoveryPassPopUpTitleEmailSend')}
                <div class='emojiCont d-inline-block ml-1'><img style='height:19px' alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f64c.png'></img></div>
                </h2>
                <p class='sweetPopSubTittle'>${t('recoveryPassPopUpBodyEmailSend')}</p>
            </div>
            `,
            confirmButtonColor:'#106AD4',
            focusConfirm: false,
            })
        } catch (error) {
            console.log(error)
            onClose()
            await MySwal.fire({
                background:'#fff',
                customClass:'confirmName',
                confirmButtonText: 'Ok',
                html:`
                <div class='sweetPopHeader'>
                    <h2 style='font-size: 22px' class='sweetPopTittle'>Upss...
                    </h2>
                    <p class='sweetPopSubTittle'>Parece ser que hubo un error en nuestro servidor</p>
                </div>
                `,
                confirmButtonColor:'#106AD4',
                focusConfirm: false,
                })
        }
        
        //onSuccess()
    }
    const handleErrorsen = async () => {
        onClose()
        const MySwal = withReactContent(option)
                    await MySwal.fire({
                    background:'#fff',
                    customClass:'confirmName',
                    confirmButtonText: 'Ok',
                    html:`
                    <div class='sweetPopHeader'>
                        <h2 style='font-size: 22px' class='sweetPopTittle'>Upss
                        
                        </h2>
                        <p class='sweetPopSubTittle'>${t('recoveryPassPopUpBodyError')}</p>
                    </div>
                    `,
                    confirmButtonColor:'#106AD4',
                    focusConfirm: false,
                    })
        //onSuccess()
    }

    return (
        <>
        {!confirm && <>
            <Col className='headerBody'>
                    <div className='confirmHeader'>

                        <h2>{t('recoveryPassTitle')} <div className='emojiCont d-inline-block ml-1'><img style={{height:'19px'}} alt='emo' src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f62c.png'></img></div></h2>
                        <p>{t('recoveryPassSubTitle')}</p>
                        </div>
                    <div>
                        <Row className='inputLogSignCont m-0 justify-content-center align-items-center'>
                            <Col className="p-0" xs={12}>
                            <input 
                            onChange={(e)=>{setEmail(e.target.value); error && setError(false)}} 
                            value={email}
                            name='correo' 
                            placeholder={t('signUpEmailPlaceHolder')}
                            className='w-100 border-0 inputLogSign' 
                            type='email'></input></Col>
                        </Row>
                    </div>
                    </Col>

                    {error && <div>
                        <p className='pl-3 errormsg m-0'>{error}</p>
                        
                    </div>}

                    <Row className='pl-4 pr-4 mt-3'>
                    <Col>
                        <button disabled={disabled} onClick ={onClose} type='button' className='btnCancel'>
                        {t('signUpCancel')}
                        </button>
                    </Col>
                    <Col>
                        <button disabled={disabled} type='button' onClick={handleConfirm} className='btnCrear'>
                            {t('recoveryPassSend')}
                        </button>
                    </Col>
                        
                    </Row></>}
            {confirm && <>
                <ConfirmEmailPop
                            forgetPass
                            email={email}
                            isError={isError}
                            onClose={onClose}
                            onFailure={handleErrorsen}
                            onSuccess={handleSendRecoveryPass}
                            />
            </>}
        </>
    )
}

export default RecoveryPass
