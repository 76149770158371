import React, { Component } from 'react';
import { Col, Row} from 'reactstrap';
import LandingForm from './LandingForm'
import './styles.css'
import {withTranslation } from 'react-i18next';
import {img1, img2, img3, img4} from '../../utils/imgImports'
import line from '../../img/subline.svg'
import { Helmet } from "react-helmet";
class LandingDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOptions:false,
            width:window.innerWidth
        }
        this.setShowOptions =this.setShowOptions.bind(this)
    }
    setShowOptions(next) {
        this.setState({
            showOptions:next
        })
    }
    updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
    render() {
        
        const { t } = this.props;
        return (
            <>
            <Helmet>
                <meta property="og:url" content="https://www.Tripendar.com/" />
                <meta property="og:image" content="https://res.cloudinary.com/tripendar-com/image/upload/v1643335929/imgpreview_qpbjfw.png" /> 
                <meta property="og:site_name" content="Tripendar" />
                <meta property="og:title" content="The best free trip planning tool for smart travelers." />
                <meta property="og:description" content="Plan your trip the smart way. Choose your activities, organize them in our calendar, choose your transportation, optimize your routes, calculate your budget, and best of all - it’s totally free." />
            </Helmet>
            <div  className='landingCont justify-content-center justify-content-md-start
            '>
                <Row className='m-0' onClick={() => {this.state.showOptions && this.setShowOptions(false)}}>
                    <Col className='d-flex flex-column' xs={12}  md={7}>
                        <div className='d-flex flex-column align-items-center align-items-md-start'>
                            <h2 className='titleLanding'>
                                {/* 
                                La mejor forma de
                                <span className='position-relative ml-2 mr-2 d-inline-block'>planificar
                                <img className="linetitle" alt='line' src={line}></img> 
                                </span> 
                                tus viajes 
                                <Trans i18nKey={'titleLandingStart'}>
                                <span className='position-relative ml-2 mr-2 d-inline-block'>
                                </span> 
                                <div className="linetitle" alt='_' src={line}></div> 
                                </Trans>
                                */}
                                {t('titleLandingStart')}
                                <span className='position-relative ml-2 mr-2 d-inline-block'>{t('titleLandingLine')}
                                <img className="linetitle" alt='line' src={line}></img> </span> 
                                {t('titleLandingEnd')}
                            </h2>
                        
                            <p className='subtitleLanding'>{t('subtitleLanding')}
                            <img style={{marginLeft: '7px', width:'25px'}}  alt='mano' src={"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/1f919.png"}></img>
                            </p>
                        </div>
                        {this.props.children}
                        <LandingForm 
                        onCreateZeroCalendar={this.props.onCreateZeroCalendar}
                        oncitiesPop={this.props.oncitiesPop}
                        showOptions={this.state.showOptions}
                        setShowOptions={this.setShowOptions} 
                        handleSubmit={this.props.onSubmit}></LandingForm>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                {this.state.width>=768&&<>
                <img className='imagen1' alt='img1' src={img1}></img>
                <img className='imagen2' alt='img2' src={img2}></img>
                <img className='imagen3' alt='img3' src={img3}></img>
                <img className='imagen4' alt='img4' src={img4}></img>
                </>}
            </div>
            <div
                onClick={() => {this.state.showOptions && this.setShowOptions(false)}}
                style={{
                    position:'fixed',
                    inset:0,
                    zIndex:1,
                }}
            />
            </>
        );
    }
}

export default withTranslation()(LandingDiv);