import moment from "moment";
import { SortDateStart } from "./sortDate";
import api from "./axiosApi";
import _ from "lodash";
import CalendarMotor from "./CalendarMotor";

export const travelMode = {
  car: "driving",
  walking: "walking",
  train: "transit",
  bicycle: "bicycling",
};
export const reversetravel = {
  driving: "car",
  walking: "walking",
  transit: "train",
  bicycling: "bicycle",
};
async function moveListOfItems(originalArray, index, traveltime) {
  const list = _.cloneDeep(originalArray);
  const newItem = list[index];
  let durationTransport = traveltime;
  let diferenceBetweenTransports = 0;
  let poly;
  try {
    let transport = travelMode[newItem.typeTrip];
    const diffInAti =list[index+1]? moment(list[index+1]?.startDateTime).diff(
      moment(newItem.endDateTime),
      "minutes"
    ) : 25;
    if(!durationTransport && diffInAti < 24) {
      //hacemos peticion de transporte
      
      newItem.nextPlace = {
        lat: list[index + 1].lat,
        lon: list[index + 1].lon,
        name: list[index + 1].name,
        _id: list[index + 1]._id
      };
      do {
        const resp = await api.get(
          `/MapRoute/routes?origin=${newItem.lat},${newItem.lon}&destination=${newItem.nextPlace.lat},${newItem.nextPlace.lon}&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=${transport}`
        );
        if (
          resp.data.data.available_travel_modes &&
          resp.data.data.available_travel_modes.length > 0
        ) {
          newItem.typesTravels = resp.data.data.available_travel_modes.map(
            (el) => reversetravel[el.toLowerCase()]
          );
          newItem.typeTrip = newItem.typesTravels[0];
          transport = resp.data.data.available_travel_modes[0];
        } else {
          durationTransport =
            (resp.data.data.routes?.[0]?.legs[0].duration.value  || 0) / 60.0; // resp.data.routes?.[0]?.duration
          poly = resp.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
          transport = undefined;
        }
      } while (transport);
      newItem.polyline = poly;
    }
    //calculamos cuanto tiempo hay que añadir o quitar
    let i = index;
    diferenceBetweenTransports = durationTransport - newItem.traveltime;
    newItem.traveltime = durationTransport;
    let oneAfterIsBlock = list[i].blocked;
    let showldContinue = list[i + 1]
      ? moment(list[i].endDateTime).add(diferenceBetweenTransports, "minutes") >
        moment(list[i + 1].startDateTime)
      : false;
    //detectamos que hay suficiente espacio para mover hacia abajo
    while (!oneAfterIsBlock && showldContinue) {
      i++;
      oneAfterIsBlock = list[i]?.blocked;
      showldContinue = !oneAfterIsBlock &&  list[i + 1] &&
      moment(list[i].endDateTime).add(diferenceBetweenTransports, "minutes") >= moment(list[i + 1].startDateTime);
    }
    //aqui hacia arriba
    i = index;
    let oneBeforeIsBlock = list[i]?.blocked;
    showldContinue = list[i - 1]?
      moment(list[i].startDateTime).subtract(
        diferenceBetweenTransports,
        "minutes"
      ) < moment(list[i - 1].endDateTime) :
      false;
    
    while (!oneBeforeIsBlock && showldContinue) {
      i--;
      oneBeforeIsBlock = list[i]?.blocked
      showldContinue = !oneBeforeIsBlock && list[i - 1] &&
      moment(list[i].startDateTime).subtract(diferenceBetweenTransports, "minutes") <= moment(list[i - 1].endDateTime);
    }
    let hasTransportAndNotBlock;
    
    if (
      (oneBeforeIsBlock &&
      oneAfterIsBlock )// || (!newItem.NewRoute)
    ) {
      if (newItem.traveltime >= 0) {
        let end = moment(newItem.endDateTime)
          .add(newItem.traveltime, "minutes")
        if (list[index + 1] && end > moment(list[index + 1]?.startDateTime)) {
          end = moment(list[index + 1]?.startDateTime).subtract(
            newItem.traveltime,
            "minutes"
          );
        }
        const newDur = end.diff(moment(newItem.startDateTime), "hours", true);
        if (newDur <= 0) {
          throw Error("can not move activites");
        }
        newItem.dur = newDur;
        newItem.selected = true;
        newItem.endDateTime = end
          .add(newItem.traveltime, "minutes")
          .format(`YYYY/MM/DD HH:mm:00`);
      } else {
        throw Error("can not move activites");
      }
    } else if (!oneAfterIsBlock || diferenceBetweenTransports<0) {
      newItem.endDateTime = moment(newItem.endDateTime)
        .add(diferenceBetweenTransports, "minutes")
        .format(`YYYY/MM/DD HH:mm:00`);
      i = index + 1;
      const prevActConect = list[i]
        ? (moment(newItem.endDateTime) >= moment(list[i].startDateTime) || newItem.nextPlace)
        : false;
      hasTransportAndNotBlock = prevActConect && !list[i].blocked;
      while (hasTransportAndNotBlock) {
        list[i].startDateTime = moment(list[i].startDateTime)
          .add(diferenceBetweenTransports, "minutes")
          .format(`YYYY/MM/DD HH:mm:00`);

        list[i].endDateTime = moment(list[i].endDateTime)
          .add(diferenceBetweenTransports, "minutes")
          .format(`YYYY/MM/DD HH:mm:00`);
        
        const prevActConect = list[i + 1]
          ? (moment(list[i].endDateTime) >= moment(list[i + 1].startDateTime) || list[i]?.nextPlace)
          : false;
        hasTransportAndNotBlock = prevActConect && !list[i + 1]?.blocked;
        if (!hasTransportAndNotBlock) {
          list[i].nextPlace = undefined;
        }
        i++;
      }
    } else {
      newItem.startDateTime = moment(newItem.startDateTime)
        .subtract(diferenceBetweenTransports, "minutes")
        .format(`YYYY/MM/DD HH:mm:00`);
      i = index - 1;
      const prevActConect = list[i]
        ? moment(newItem.startDateTime) <= moment(list[i].endDateTime)
        : false;
      hasTransportAndNotBlock = prevActConect && !list[i].blocked;
      while (hasTransportAndNotBlock) {
        list[i].startDateTime = moment(list[i].startDateTime)
          .subtract(diferenceBetweenTransports, "minutes")
          .format(`YYYY/MM/DD HH:mm:00`);
        list[i].endDateTime = moment(list[i].startDateTime)
        .add(list[i].dur, "hours")
        .add(list[i].traveltime, "minutes")
        /*
        list[i].endDateTime = moment(list[i].endDateTime)
          .subtract(diferenceBetweenTransports, "minutes")
          .format(`YYYY/MM/DD HH:mm:00`);
        */

        const prevActConect = list[i - 1]
          ? moment(list[i].startDateTime) <= moment(list[i - 1].endDateTime)
          : false;
        hasTransportAndNotBlock = prevActConect && !list[i - 1]?.blocked;
        i--;
      }
    }
  } catch (e) {
    throw Error(e.message);
  }
  return list;
}

/**
 * detect the modified activity and modified the position if it necesary
 * @param {Array} items list Of Items to modify
 * @param {Object} item item modified that will be evaluated
 * @param {Boolean} isSize
 * @returns {items: Array, item: Object}
 */
export const handleSelectedActivity = async (items, item, isSize) => {
  
  const newItem = { ...item };
  let newItems = SortDateStart(items);
  const id = newItems.findIndex((i) => newItem._id === i._id);
  const nextItem = newItems[id + 1];
  const prevItem = newItems[id - 1];
  const isBetweenUpper =
    prevItem &&
    !(prevItem.blocked || prevItem.tourRef) &&
    moment(newItem.startDateTime).format("YYYY-MM-DDTHH:mm:00") <
      moment(prevItem.startDateTime)
        .add(prevItem.dur / 2, "hour")
        .format("YYYY-MM-DDTHH:mm:00");

  if (newItems[id + 1] && newItem.startDateTime === nextItem.startDateTime) {
    newItems[id + 1].startDateTime = moment(newItems[id + 1].startDateTime)
      .add(1, "minute")
      .format("YYYY/MM/DD HH:mm:00");
  } else if (isBetweenUpper) {
    const diffTimes = moment(newItem.startDateTime).diff(
      newItems[id - 1].startDateTime
    );
    newItem.startDateTime = moment(newItem.startDateTime)
      .subtract(diffTimes)
      .format("YYYY/MM/DD HH:mm:00");
    newItem.endDateTime = moment(newItem.endDateTime)
      .subtract(diffTimes)
      .format("YYYY/MM/DD HH:mm:00");
    newItems[id - 1].startDateTime = moment(newItems[id - 1].startDateTime)
      .add(1, "minute")
      .format("YYYY/MM/DD HH:mm:00");
  }
  let start = moment(newItem.startDateTime);
  /*isSize ? moment(newItem.endDateTime).add(15,'minutes').subtract(newItem.traveltime, 'minutes'):*/
  let end = moment(newItem.endDateTime).subtract(newItem.traveltime, "minutes");
  let newDur = end.diff(start, "hours", true);
  //se establece un nuevo inicio en caso de ser necesarios
  if (prevItem && start < moment(prevItem.endDateTime) && !isBetweenUpper) {
    start = moment(prevItem.endDateTime).subtract(1, "minute");
    end = moment(start).add(newDur, "hours");
  }

  newItem.startDateTime = start.format(`YYYY/MM/DD HH:mm:00`);
  newItem.endDateTime = end
    .add(newItem.traveltime, "minutes")
    .format(`YYYY/MM/DD HH:mm:00`);

  newItems[id] = newItem;
  //funcion para arrastrar todas las actividades en caso de que se mueva el transporte
  const travelTime = !newItem.NewRoute ?  newItem.traveltime : 0
  try {
    const newList = await moveListOfItems(newItems, id,travelTime);
    if(newList?.error) return { error: "Duration can not be negative" };
    newItems = newList;
    
  } catch (error) {
    return { items: newItems, item: newItem }
  }
  return { items: newItems, item: newItem };
};

export default async function FixItems(arrayObj) {
  const calendarMotor = new CalendarMotor(arrayObj)
  
  //ordenamos los objetos
  try {
    
    const status = await calendarMotor.process()
    if(status) return Error({message:'can not fix'})
    const {list,days} = calendarMotor.decodeToArray()
    return {
      list: list || [],
      days: days || [],
    };
  } catch (error) {
    console.log(error)
  }
}
