
import { library } from '@fortawesome/fontawesome-svg-core'
//import { fab } from '@fortawesome/free-brands-svg-icons'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {faCaretDown ,faSearch, faChevronRight, faChevronLeft, faPlaneDeparture, faPlaneArrival, faSearchMinus, faSearchPlus, faPlusCircle, faTrashAlt, faShareAlt, faSun, faCloud, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faSpinner, faEnvelope, faLock, faBan, faStar, faPenSquare, faCircleNotch, faUserAlt, faUserAltSlash} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { socket, socketContext } from './utils/socket'
import { setLogin } from './redux/actions/usersActions.js'
import { getUserToken } from './redux/reducers'
import { connect } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.css';
import Cookies from 'universal-cookie'
import api from './utils/axiosApi'
import UserLeaveConfirmation from './utils/UserLeaveConfirmation'
import {logoW} from './utils/imgImports'
import './components/PopUpStyles.css'
import './components/LoadingCurtain/styles.css'
import './pages/generalStyles.css'
import './i18n/config';
import './App.css'
/*import CalendarPage from './pages/CalendarPageClss'
import UsersCalendarsPage from './pages/UsersCalendars'
import LandingPage from './pages/LandingPage'
import SearchPage from './pages/SearchPage'
import LoginPage from './pages/LoginPage'
import CalendarViewerPage from './pages/CalendarViewerPage'
import AddUsersCalendarPage from './pages/AddUsersCalendarPage'
import LoginPage from './pages/LoginPage'
import SearchPage from './pages/SearchPage'
*/
import LandingPage from './pages/Landings/LandingPage'
import CopyModelAgency from './pages/CopyModelAgency'
import ChangePageMetrics from './utils/ChangePageMetrics.js';
import Routes from './utils/Routes';
import ProcessPaymentPage from './pages/ProcessPayment.js';
//const LoginPage = lazy(() => import('./pages/LoginPage'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const InfluencerSelectorPage = lazy(() => import('./pages/InfluencerSelectorPage'));
const LandingBusiness = lazy(() => import('./pages/Landings/LandingBusinessPage'));
const UsersCalendarsPage = lazy(() => import('./pages/UsersCalendars'));
const CalendarViewerPage = lazy(() => import('./pages/Calendars/CalendarViewerPage'));
const AddUsersCalendarPage = lazy(() => import('./pages/AddUsersCalendarPage'));
const CalendarPage = lazy(() => import('./pages/Calendars/CalendarPageClss'));
const CalendarAgencyPage = lazy(() => import('./pages/Calendars/CalendarAgencyPage'));
const FalseCalendarPage = lazy(() => import('./pages/FalseCalendarActivites'));
const ThingsToDo = lazy(() => import('./pages/ThingsToDo'));
const BussinessDashboardPage = lazy(() => import('./pages/BussinessDashboardPage'));
const BussinessInfluencerDashboardPage = lazy(() => import('./pages/BussinessInfluencerDashboardPage'));
const staticBlog = lazy(() => import('./pages/BlogsPages/StaticBlog.page.js'));
//iframes
const NewAgencyCalendarIframe = lazy(() => import('./pages/IframePages/newAgencyCalendar.iframe'));
const CalendarAgencyIframe = lazy(() => import('./pages/IframePages/CalendarAgency.iframe'));
const CalendarAgencyActivitiesIframe = lazy(() => import('./pages/IframePages/CalendarAgencyActivities.iframe.js'));

const cookies = new Cookies()

library.add(faUserAlt, faUserAltSlash ,faCircleNotch,faPenSquare,faStar,faBan,faLock,faEnvelope, faSpinner,faSearch, faCaretDown, faChevronRight, faChevronLeft, faPlaneDeparture, faPlaneArrival, faSearchMinus, faSearchPlus, faPlusCircle, faTrashAlt, faShareAlt, faSun, faCloud, faCloudSun, faCloudSunRain, faCloudShowersHeavy  )
/*const renderCalendar = () => <CalendarPage/>
const renderCustomerToCalendar = () => <AddUsersCalendarPage/>
const renderViewer = () => <CalendarViewerPage/>
const renderCalendars = () => <UsersCalendarsPage/>
const renderSearch = () => <SearchPage/>
const renderLogin = () => <LoginPage/>
const renderLandingPage = () => <LandingPage/>
const renderInfoPage = () => <SearchPage/>*/

function App({token, setLogin}) {
  const [track, setTrack] = useState(false)
  useEffect(() => {
    const trackcookie = cookies.get('trackingAcept')
    if(trackcookie) {
      setTrack(false)
    } else {
      setTrack(true)
    }
  }, [track])
  useEffect(() => {
    const tokencookie = window.sessionStorage.getItem('jwtuser')|| localStorage.getItem('jwtuser') //cookies.get('jwtuser')
    const updateToken = async () =>{
      try {
          const newTokenRequest = await api.get('/user/updateToken',{
            headers : {
                Authorization: `Bearer ${tokencookie}`
            }
        })
        const {data,error} = newTokenRequest.data
        if(error){
          return
        }
        localStorage.setItem('jwtuser', data.token)
        setLogin(data.token)
        api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`

      } catch (error) {
        localStorage.removeItem('jwtuser')
        window.sessionStorage.removeItem('jwtuser')
      }
    }

    if(tokencookie){
      setLogin(tokencookie)
      api.defaults.headers.common['Authorization'] = `Bearer ${tokencookie}`
      //updateToken()
    }
  }, [setLogin])
  
  const [confirmOpen, setConfirmOpen] = useState(true);
  return (
    <>
      <socketContext.Provider value={socket}>

      <Router
      getUserConfirmation={(message, callback) => {
          return UserLeaveConfirmation(
            message,
            callback,
            confirmOpen,
            setConfirmOpen
          );
        }}
      >
      <ChangePageMetrics>

        <Suspense fallback={<>
          <div style={{
              color: '#E5E9F2' ,
              fontSize: '100px',
              width:'100vw', 
              height: 'calc(100vh - 70px)', 
              display: 'flex', 
              justifyContent: 'center', alignItems: 'center'}}>
          <div className='loader-Cont'>
              <div className='loader loader1'></div>
              <div className='loader loader2'></div>
              <div className='loader-Logo'>

              <img className='d-block' alt='logo' src={logoW}></img>
              </div>
          </div>
          </div>
        </>} >
              <Switch>

                {/* <Route path="/login" component={LoginPage} />
                <Route path="/signup" component={LoginPage} /> */}
                <Route path={Routes.processPayment()} component={ProcessPaymentPage} />
                <Route path={Routes.bussinessLanding()} component={LandingBusiness} />

                <Route path={Routes.activities(':nameCity', ':idPlace')} component={SearchPage} />
                <Route path={Routes.activities(':nameCity')} component={SearchPage} />
                {/* <Route path="/search"  component={renderSearch} /> */}
                <Route path={Routes.calendars()} component={UsersCalendarsPage}  />
                <Route path={Routes.viewCalendar(':id')}  component={CalendarViewerPage} />

                <Route path={Routes.calendar(':id',':idcity', ':idPlace')} component={CalendarPage} />
                <Route path={Routes.calendar(':id',':idcity')} component={CalendarPage} />
                <Route path={Routes.calendar(':id')} component={CalendarPage} />

                {/* <Route path="/info/:placeId"  component={renderInfoPage} /> */}

                <Route path={Routes.shareCalendar(':id')}  component={AddUsersCalendarPage} />
                <Route path={Routes.blog('lugares-instagrameables-en-cartagena-de-indias')}  component={staticBlog} />
                {
                  //en la de abajo seria el dashboard
                <Route path={Routes.Bussiness()}  component={BussinessDashboardPage} />
              }
                <Route path={Routes.BussinessInfluencer()}  component={BussinessInfluencerDashboardPage} />
                <Route path={Routes.CalendarInfluencer(':idInfluencer','itineraries')}  component={InfluencerSelectorPage} />
                <Route path={Routes.agencyCalendar(':idAgency',':id',':idcity', ':idPlace')} component={CalendarAgencyPage} />
                <Route path={Routes.agencyCalendar(':idAgency',':id',':idcity')} component={CalendarAgencyPage} />
                <Route path={Routes.agencyCalendar(':idAgency',':id')} component={CalendarAgencyPage} />
                <Route path={Routes.agencyCalendar(':idAgency')} component={CopyModelAgency} />

                <Route path={Routes.agencyCalendarIframe(':idAgency',':id',':idcity', ':idPlace')} component={CalendarAgencyIframe} />
                <Route path={Routes.agencyCalendarIframe(':idAgency',':id',':idcity')} component={CalendarAgencyIframe} />
                <Route path={Routes.agencyCalendarIframe(':idAgency',':id')} component={CalendarAgencyIframe} />
                <Route path={Routes.agencyCalendarIframe(':idAgency')} component={NewAgencyCalendarIframe} />

                <Route path={Routes.activitiesCalendarIframe(':idAgency',':id')} component={CalendarAgencyActivitiesIframe} />
                
                <Route path={Routes.thingsToDo(':nameCity',':idPlace')}  component={ThingsToDo} />
                <Route path={Routes.thingsToDo(':nameCity')}  component={ThingsToDo} />

                <Route path={Routes.city(':nameCity',':idPlace')} component={FalseCalendarPage} />
                <Route path={Routes.city(':nameCity')}  component={FalseCalendarPage} />
                <Route path="*" component={LandingPage} />
              </Switch>
        </Suspense>
      </ChangePageMetrics>
            {/*
            <div style={{position:'fixed', bottom: 0, left:'5px', zIndex:50}}>
                <ChangeLngBtn></ChangeLngBtn>
            </div>*/}
      </Router>
      </socketContext.Provider>
      
    </>
  );
}

const mapStateToProps = state => {
  return ({
      token:getUserToken(state)
})}
const mapDispatchToProps = dispatch => (
  {
      setLogin: value =>dispatch(setLogin(value)),
  })
/*
{track && <div className='d-flex justify-content-end align-items-end' style={{
        zIndex:1000000000,
        position: 'fixed', 
        top:'0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgba(0,0,0,0.7)'
        
        }}>
          <div className='TrackingCookies'
          >
          <p>Esta pagina hace uso de cookies de recoleccion de datos para poder mejorar la experiancia de usuario, es necesario aceptar para poder continuar</p>
          <button onClick={() => {
            cookies.set('trackingAcept', true)
            setTrack(false)
          }} type='button'>Aceptar</button>
          </div>
        </div>}
*/
export default connect(mapStateToProps,mapDispatchToProps)(App);

/* <Switch>
              <Route path="/login" render={renderLogin} />
              <Route path="/signup" render={renderLogin} />

              <Route path="/search"  render={renderSearch} />
              <Route path="/calendar/:id"  render={renderCalendar} />
              <Route path="/info/:placeId"  render={renderInfoPage} />

              <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
              <Route path="*" render={renderLandingPage} />
            </Switch> */
