import {
  LOGIN_USER,
  SIGNUP_USER,
  SIGNOUT_USER,
  SET_SHOP_LIST,
  SET_PLACE_IN_LIST,
  NOTIFICATIONS_USER,
} from "../const";
import jwtDecode from "jwt-decode";

export const user = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, token: action.value };
    case SIGNUP_USER:
      return { ...state, token: action.value };
    case SIGNOUT_USER:
      return { ...state, token: "" };
    case NOTIFICATIONS_USER:
      const notificationState = { ...state };

      notificationState.notifications = action.value;
      notificationState.UnreadNotifications = action.value.reduce(
        (previousValue, currentValue) => {
          let readed = 0;
          if (currentValue.unread) {
            ++readed;
          }
          return previousValue + readed;
        },
        0
      );

      return notificationState;
    //-----------------------------------------------
    case SET_SHOP_LIST:
      const newState = { ...state };
      newState.list = action.value.list;
      if (action.value.places) {
        newState.places = action.value.places;
        newState.page = action.value.page || 0;
        newState.Nitems = action.value.Nitems;
        newState.bounds = action.value.bounds;
      }
      return newState;
    case SET_PLACE_IN_LIST:
      const newState2 = { ...state };
      newState2.places = action.value.places;
      newState2.query = action.value.query || "";
      newState2.page = action.value.page || 0;
      newState2.Nitems = action.value.Nitems;
      newState2.bounds = action.value.bounds;
      if (action.value.markPlaces) {
        newState2.markPlaces = action.value.markPlaces;
      }

      return newState2;
    default:
      return state;
  }
};
export const getUserDecoded = (state) => {
  const token =
    state.user.token ||
    window.sessionStorage.getItem("jwtuser") ||
    localStorage.getItem("jwtuser");
  return token ? jwtDecode(token) : {};
};
