import { Distance } from './CoorDis'
import { startTimeCalendar } from './const';
import moment from 'moment'
const ColorMarkers= {
    "color-1": '#0073DD',
    "color-2": '#8F58FE',
    "color-3": '#2AA434',
    "color-4": '#FD841F',
    "color-5": '#EA047E',
    'color-6': '#DD5353',
    "color-7": '#7DE5ED',
    "color-8": '#FFD372',
    "color-9": '#D58BDD',
    "color-10": '#E1FFB1',
    "color-11": '#FF1E1E',
    "color-12": '#38E54D',
    "color-13": '#AF0171',
    "color-14": '#FFDE00',
}
        
/**
 * 
 * @param {Array} itemsCoords Array of objects {lat, lon,...}
 * @returns {
 *      {
  *          distance: Float,
 *           center: {lat:float, lon:float},
 *           colorDays:Object
  *      }
 */
export const getDataCoordsFromListCoords =(itemsCoords=[]) => {
    if(itemsCoords.length===0) {
        return {
            distance: 0,
            center :{
                lat:0,
                lon:0
            },
            zoom:0,
            coordsProm: {
                lat:0,
                lon:0
            }
        }
    }
    const array =[...itemsCoords]
    const bound = {
        maxLat: array?.[0]?.lat || 0 ,
        maxLon: array?.[0]?.lon || 0,
        minLat: array?.[0]?.lat || 0,
        minLon: array?.[0]?.lon || 0,
    }
    var lats = 0, lons =0
    array.forEach(element => {
        lats= lats + element.lat
        lons= lons + element.lon
        if(bound.maxLat < element.lat) {
            bound.maxLat=element.lat
        }
        if(bound.maxLon < element.lon) {
            bound.maxLon=element.lon
        }
        if(bound.minLat > element.lat) {
            bound.minLat=element.lat
        }
        if(bound.minLon > element.lon) {
            bound.minLon=element.lon
        }
        
    });
    const distancmax= Distance(bound.maxLat, bound.maxLon, bound.minLat,bound.minLon)
    const center = {
        lat : (bound.maxLat+bound.minLat)/2,
        lon : (bound.maxLon+bound.minLon)/2,
    }
    const coordsProm = {
        lat : lats/array.length,
        lon : lons/array.length,
    }
    //formula para determinar el zoom y=-1.09*ln(15x)+16
    const zoom= distancmax>0 ? (-1.09*Math.log(15*distancmax))+16.3 :13
    return {
        distance: distancmax,
        center,
        zoom,
        coordsProm,
    }
}
/**
 * 
 * @param {Array<Object>} items list of the items to split
 * @param {Object} daysDivisor Object make with the day as key (YYYY/MM/DD) and the last index of that day
 * @returns {
 *      {
 *          days: Object,
 *           boundsDays: Object,
 *           colorDays:Object,
 *           daysArray: Array
 *       }
 */
export default function SplitItemsByDays(itemsToSplit=[], daysDivisorObj={}) {
    const items = itemsToSplit.map(item =>({
        lat: item.lat,
        lon: item.lon, 
        polyline:item.polyline,
        _id: item._id, 
        adminRef: item.adminRef?._id,
        tourRef: item.tourRef?._id,
        startDateTime: item.startDateTime, 
        endDateTime: item.endDateTime, 
        name:item.name,
        place: item.place
    }))
    const daysDivisor = {...daysDivisorObj}
    let inicio = 0
        const newDaysAgenda ={}
        const newBoundDays ={}
        const newColorDays ={}
        let color=1
        for(const [key, value] of Object.entries(daysDivisor)){
            newDaysAgenda[key] =  items.slice(inicio, value ).map(it=>({...it}))
            newColorDays[key] =  ColorMarkers[`color-${color}`]
            color++
            if(color>14){
                color=1
            }
            const diff=items[inicio-1] && moment(items[inicio-1]?.endDateTime).diff(moment(`${key} ${startTimeCalendar}:00:00`), 'hours',true)
            const diffEnd =newDaysAgenda[key][newDaysAgenda[key].length -1 ] && moment(newDaysAgenda[key][newDaysAgenda[key].length -1 ]?.endDateTime).diff(moment(`${key} ${startTimeCalendar}:00:00`), 'hours',true)
            if(diff &&  diff> 0) {
                newDaysAgenda[key].unshift(items[inicio-1])
            }
            
            if(newDaysAgenda[key][newDaysAgenda[key].length -1]?.polyline) {
                delete newDaysAgenda[key][newDaysAgenda[key].length -1].polyline
            }
            newBoundDays[key] =  getDataCoordsFromListCoords(newDaysAgenda[key])
            inicio = value
            if(diffEnd>24){
                const nextDay = moment(key).add(1,'day').format('YYYY/MM/DD')
                newDaysAgenda[nextDay] = [newDaysAgenda[key][newDaysAgenda[key].length -1 ]]
                newBoundDays[nextDay] = {distance: 0, center:{
                    lat:newDaysAgenda[key][newDaysAgenda[key].length -1 ].lat, 
                    lon: newDaysAgenda[key][newDaysAgenda[key].length -1 ].lon
                }, zoom:14}
                
                
            }
        }
        const keys = Object.keys(newDaysAgenda)
        return {
            days: newDaysAgenda,
            boundsDays: newBoundDays,
            colorDays:newColorDays,
            daysArray: keys,
        }
}
        