import {
  SET_PLACES,
  SET_AGENDA_PLACES,
  INITIAL_SET,
  SET_CALENDAR_LIST,
  SET_NEW_USER_AGENDA,
  SET_NEW_INV_AGENDA,
  SET_CALENDAR_INV_LIST,
  SET_CALENDAR_PUBLIC,
  SET_NEW_USER_INV_AGENDA,
  SET_CALENDAR_LOADING,
  SET_CALENDAR_NAME,
  SET_SHOP_LIST,
  SET_CALENDAR_LIST_PAGE,
  SET_CALENDAR_COUNT_ITEMS,
  SET_PLACE_IN_LIST,
  SET_UNREGISTER_USER_AGENDA,
  SET_PLACES_ADMIN_LIST_QUERY,
  SET_PREV_AGENDA_PLACES,
} from "../const";
import FixItems, {
  handleSelectedActivity,
  reversetravel,
} from "../../utils/fixItems";
import api from "../../utils/axiosApi";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { getRandomColor } from "../../utils/getRandomColor";
import axios from "axios";
import _ from "lodash";
import SortDate from "../../utils/sortDate";
import { startTimeActivities, startTimeCalendar } from "../../utils/const";
import { formatHours } from "../../utils/CalendarMotor";
import { getDataCoordsFromListCoords } from "../../utils/SplitItemsByDays";


const daySeg = {
  //cantidad de segundos de los dias
  1: 0,
  2: 86400,
  3: 172800,
  4: 259200,
  5: 345600,
  6: 432000,
  7: 518400,
};
//funciones que modifican directamente el redux
//se limpia el calendario
export const clearCalendar = (payload) => {
  return async (dispatch, getState) => {
    const actualState = getState();
    const calendar = {};
    calendar.name = "";
    calendar.id = "";
    calendar.city = "";
    calendar.cityName = "";
    calendar.cityNameEn = "";
    calendar.users = [];
    calendar.inv = [];
    calendar.agendaItems = [];
    calendar.maxDate = moment();
    calendar.minDate = moment();
    calendar.startDate = moment();
    calendar.startAtTime = 7;
    calendar.public = false;
    calendar.toDolist = [];
    calendar.isLoading = true;
    calendar.toDolistUser = [];
    dispatch(setinitialvalues(calendar));
  };
};
//modifican el calendario
export const setPlaces = (value) => ({ type: SET_PLACES, value });
export const setAgendaPlaces = (value) => ({ type: SET_AGENDA_PLACES, value });
export const setPrevAgendaPlaces = (value) => ({
  type: SET_PREV_AGENDA_PLACES,
  value,
});
export const setinitialvalues = (value) => ({ type: INITIAL_SET, value });
const saveUsers = (value) => ({ type: SET_NEW_USER_AGENDA, value });
const saveInv = (value) => ({ type: SET_NEW_INV_AGENDA, value });
export const changeName = (value) => ({ type: SET_CALENDAR_NAME, value });
export const saveUsersInv = (value) => ({
  type: SET_NEW_USER_INV_AGENDA,
  value,
});
const setcalendarPublic = (value) => ({ type: SET_CALENDAR_PUBLIC, value });
export const setLoadingCalendar = (value) => ({
  type: SET_CALENDAR_LOADING,
  value,
});
//modifican la lista de los calendarios
export const setcalendarlist = (value) => ({ type: SET_CALENDAR_LIST, value });
export const setcalendarlistPage = (value) => ({
  type: SET_CALENDAR_LIST_PAGE,
  value,
});
export const setcalendarlistCount = (value) => ({
  type: SET_CALENDAR_COUNT_ITEMS,
  value,
});
const setcalendarinvlist = (value) => ({ type: SET_CALENDAR_INV_LIST, value });
export const setUserShopCarList = (value) => ({ type: SET_SHOP_LIST, value });
export const setPlacesList = (value) => ({ type: SET_PLACE_IN_LIST, value });
const setPlacesAdminList = (value) => ({
  type: SET_PLACES_ADMIN_LIST_QUERY,
  value,
});
export const setUnregisterUsers = (value) => ({
  type: SET_UNREGISTER_USER_AGENDA,
  value,
});
//const setPlacesList = value => ({type: SET_PLACE_IN_LIST, value});
//const setPlace = value => ({type: SET_PLACE, value});

//se carga el calendario
export const setPlacesload = (payload) => {
  return async (dispatch, getState) => {
    //payload = [id del calendario, id usuario]
    try {
      /*
            const dataObj = {
            idCalendar: this.state.idCalendar,
            idUser:tokenCookie?.user?._id || '',
            idAgency,
            code: values?.code
        }
            */
      const state = getState();

      const code = payload?.code || "";
      //detectamos si el calendario tiene usuarios y en caso de tenerlos verfica que el usuario este dentro de los calendarios
      /*
            if (state.calendar.id !== "" && state.calendar.id === payload[0]) {
                const resp = await api.get(`/adminsplaces/get/${state.calendar.city}`)
                const itemsInCalendar = state.calendar.agendaList.map((e) => {
                    return e.name
                })
                const stack = resp.data.data.filter((e) => {
                    return !(itemsInCalendar.includes(e.name))
                })
                dispatch(setPlaces(stack))
                return
            };*/
      var respCalendar;

      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      let idAgency = payload.idAgency;
      /*se obtiene el el calendario por el id (la auth no es requerida debido a que los calendarios pueden
            crearse sin la necesidad de estar registrado)
            */
      if (payload?.access_token && payload?.secret_token) {
        const { data: response } = await api.get(
          `/oauthtokens?access_token=${
            payload?.access_token || ""
          }&secret_token=${payload?.secret_token || ""}`
        );
        
        if (response.data.valid) {
          idAgency = response.data.agency;
        }
      }
      var url = `agendacalendar`;
      if (idAgency) {
        url = `agencyCalendar`;
      }
      respCalendar = await api.get(
        `/${url}/getbyid/${payload.idCalendar}?code=${code}&access_token=${
          payload?.access_token || ""
        }&secret_token=${payload?.secret_token || ""}`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      const { data, error, meta } = respCalendar.data;
      if (error) {
        return {
          error: true,
        };
      }
      if (!meta.editorUser) {
        return {
          error: true,
          inv: true,
        };
      }
      //2 verificacion de que el usuario sea dueño del calendario
      /*
            const proper = data.users.find((e) => {
                return e._id===payload.idUser
            })*/
      const invs = data.inv.find((e) => {
        return e._id === payload.idUser;
      });
      if (/*(data.users.length !== 0 && !proper)|| */ error) {
        if (invs || data?.public) {
          //en caso de que el usuario exista pero solo para visualizacion se devuelve el inv:true
          return {
            error: true,
            inv: true,
          };
        }
        const calendar = {};
        calendar.name = "";
        calendar.id = "";
        calendar.city = "";
        calendar.cityName = "";
        calendar.cityNameEn = "";
        calendar.code = "";
        calendar.users = [];
        calendar.inv = [];
        calendar.agendaItems = [];
        calendar.maxDate = moment();
        calendar.minDate = moment();
        calendar.startDate = moment();
        calendar.startAtTime = 7;
        calendar.public = false;
        calendar.errorLoad = true;
        calendar.toDolist = [];
        calendar.toDolistUser = [];

        dispatch(setinitialvalues(calendar));
        dispatch(setPlaces([]));
        return {
          error: true,
        };
      }
      const calendar = {};

      //se cargan los datos del calendario para guardarse en redux
      calendar.id = data._id;
      calendar.name = data.name;
      calendar.hotel = data.hotel;
      calendar.emailsEditInv = data.emailsEditInv;
      calendar.emailsViewInv = data.emailsViewInv;

      calendar.city = data.city?._id || "You";
      calendar.cityName = `${
        data.city?.city ? `${data.city?.city}, ` : "Global"
      }${data.city?.state || ""}`;
      calendar.cityNameEn = `${
        data.city?.cityEn ? `${data.city?.cityEn}, ` : "Global"
      }${data.city?.stateEn || ""}`;
      calendar.cityEmoji = data.city?.emoji || {
        emoji: "",
        url: "",
      };
      
      if(data.city) {
        calendar.cityDataCoor = {
          lat: data.city?.lat || 0,
          lon: data.city?.lon || -25,
          radius: data.city?.radius || 10,
          airportCoord: data.city?.airportCoord,
          zoom: !data.city?._id && 2,
        };
      } else {
        const locationData = getDataCoordsFromListCoords(data.agendaItems.map(it=>({lat:it.lat, lon:it.lon})))
        
        calendar.cityDataCoor = {
          lat: locationData.center?.lat || 0,
          lon: locationData.center?.lon || -25,
          radius: locationData.zoom || 10,
          airportCoord: undefined,
          zoom: locationData.zoom ,
        };
      }
      if (url !== "AgencyCalendar") {
        calendar.city = data.city?._id || "";
        calendar.cityName = `${data.city?.city ? `${data.city?.city}, ` : ""}${
          data.city?.state || ""
        }`;
        calendar.cityNameEn = `${
          data.city?.cityEn ? `${data.city?.cityEn}, ` : ""
        }${data.city?.stateEn || ""}`;
        calendar.cityEmoji = data.city?.emoji || {
          emoji: "",
          url: "",
        };
      }
      calendar.code = code;
      calendar.users = data.users;
      calendar.dataUser = data?.dataUser || undefined;
      calendar.inv = data.inv;

      //hacemos la peticion para obtener los items de los calendario con el adminRef
      //con los datos como los precios, descripciones y otras
      /*const itemsCalendar = await api.get(`/usersplaces/get/${payload.idCalendar}`, {
                headers : {
                    Authorization: `Bearer ${auth}`
                }
            })*/
      //arreglamos los items para poder guardarlos con la opcion de primera vez activa para que no haga peticiones a google
      const items = await FixItems(data.agendaItems, true);
      calendar.agendaItems = items.list;
      calendar.dayAgenda = items.days;

      calendar.maxDate = moment(data.maxDate);
      calendar.minDate = moment(data.minDate);
      calendar.startDate = moment(data.startDate);
      calendar.startAtTime = data.startAtTime;
      calendar.public = data.public || false;
      calendar.defaultToDoList = data.defaultToDoList || false;
      calendar.toDoTourslist = data.toursToDo || [];

      if (data.__t === "AgencyModelCalendar") {
        calendar.model = true;
      }
      /*
            .sort((a, b) => {
                const aStars = a.stars || 6
                const bStars = b.stars || 6
                if ((aStars) < (bStars)) {
                    return -1;
                }
                if ((aStars) > (bStars)) {
                    return 1;
                }
                // a debe ser igual b
                return 0;
            })
            */
      var firstList = calendar.toDoTourslist.concat(data.toDolist);
      const Todolist = _.reverse(firstList.concat(data.toDolistUser));
      const itemsInCalendar = _.uniq(
        items.list.map((e) => {
          return e.name;
        })
      );
      calendar.toDolist = [
        calendar.hotel,
        ...Todolist.map((e) => {
          const plc = { ...e };
          delete plc.hide;
          plc.hide = itemsInCalendar.includes(e.name);
          return plc;
        }),
      ].filter((place) => place);

      dispatch(setinitialvalues(calendar));
      return data.users;
    } catch (error) {
      
      //console.log(error)
      const calendar = {};
      calendar.name = "";
      calendar.id = "";
      calendar.city = "";
      calendar.cityName = "";
      calendar.cityNameEn = "";
      calendar.code = "";
      calendar.users = [];
      calendar.inv = [];
      calendar.agendaItems = [];
      calendar.maxDate = moment();
      calendar.minDate = moment();
      calendar.startDate = moment();
      calendar.startAtTime = 7;
      calendar.public = false;
      calendar.errorLoad = true;
      calendar.toDolist = [];
      calendar.toDolistUser = [];

      dispatch(setinitialvalues(calendar));
      dispatch(setPlaces([]));
      return error;
    }
  };
};
export const setPlacesInv = (payload) => {
  return async (dispatch, getState) => {
    try {
      //cargamos los dtos para los lugares de los usuarios que solo tienen permisos de visualización
      const state = getState();
      const fromLInk = payload[2];

      const code = payload[3] || "";
      if (state.calendar.id !== "" && state.calendar.id === payload[0]) {
        return;
      }
      var respCalendar;

      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')

      respCalendar = await api.get(
        `/agendacalendar/getbyid/${payload[0]}?fromLink=${fromLInk}&code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      const { data, error /*meta/*,editorUser*/ } = respCalendar.data;
      if (error) {
        return {
          error: true,
        };
      }
      /*
            const proper = (!data.public && !data.users.find((e) => {//data.users.length !== 0 &&
                return e._id===payload[1]
            }))
            const properInv = (!data.public && !data.inv.find((e) => { //data.inv.length !== 0 &&
                return e._id===payload[1]
            }))
            if((proper && properInv && !fromLInk )||error) return {error : true}*/
      const calendar = {};
      calendar.id = data._id;
      calendar.name = data.name;
      calendar.hotel = data.hotel;
      calendar.code = code;
      calendar.city = data.city?._id || "";
      calendar.cityName = `${data.city?.city ? `${data.city?.city}, ` : ""}${
        data.city?.state || ""
      }`;
      calendar.cityNameEn = `${
        data.city?.cityEn ? `${data.city?.cityEn}, ` : ""
      }${data.city?.stateEn || ""}`;
      calendar.cityEmoji = data.city?.emoji || {
        emoji: "",
        url: "",
      };
      calendar.cityDataCoor = {
        lat: data.city?.lat,
        lon: data.city?.lon,
        radius: data.city?.radius,
        airportCoord: data.city?.airportCoord,
      };
      calendar.users = data.users;
      calendar.inv = data.inv;
      //arreglamos los items para poder guardarlos con la opcion de primera vez activa para que no haga peticiones a google

      const items = await FixItems(data.agendaItems, true);
      calendar.agendaItems = items.list;
      calendar.dayAgenda = items.days;
      calendar.maxDate = moment(data.maxDate);
      calendar.minDate = moment(data.minDate);
      calendar.startDate = moment(data.startDate);
      calendar.startAtTime = data.startAtTime;
      calendar.public = data.public || false;
      calendar.defaultToDoList = data.defaultToDoList || false;
      calendar.toDolist = [];
      calendar.toDolistUser = [];
      dispatch(setinitialvalues(calendar));
      return { users: data.users, editor: respCalendar.data.editorUser };
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//funcion para guardar el nombre del calendario
export const saveNameCalendar = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;

      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      await api.put(
        `/agendacalendar/modifyname/${state.calendar.id}?code=${code}`,
        { name: payload },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      dispatch(changeName(payload));
      const localCalendars =
        (localStorage.getItem("calendars") &&
          JSON.parse(localStorage.getItem("calendars"))) ||
        [];

      var calendarIndex = localCalendars.findIndex(
        (cal) => cal._id === state.calendar.id
      );
      if (calendarIndex >= 0) {
        localCalendars[calendarIndex].name = payload;
        localCalendars[calendarIndex].createdAt = moment().toISOString();
        localStorage.setItem("calendars", JSON.stringify(localCalendars));
      }
      return;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//esta funcion es para cargar los calendarios que obtenemos desde la lista de calendarios
export const loadAdminPlaces = ({ payload, code }) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.user;
      const { _id } = token && jwtDecode(token).user;
      var nouser =
        state.calendar.users.length !== 0 &&
        !payload.users.find((e) => e._id === _id);
      try {
        const localToken =
          window.sessionStorage.getItem("jwtuser") ||
          localStorage.getItem("jwtuser");
        const auth = state.user.token || localToken; //cookies.get('jwtuser')
        /*const verifyCodeLink = */ await api.get(
          `/agendacalendar/verifyCodeLink/${payload._id}?code=${code}`,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
      } catch (error) {
        nouser = true;
      }
      //const noinv = (payload.inv.length !== 0 && !payload.inv.includes(_id))
      //verificamos si al usuario le pertenece el calendario
      if (nouser) {
        return { error: true };
      }
      const calendar = {};

      calendar.name = payload.name;
      calendar.hotel = payload.hotel;
      calendar.code = code;
      calendar.id = payload._id;
      calendar.emailsEditInv = payload.emailsEditInv;
      calendar.emailsViewInv = payload.emailsViewInv;
      calendar.city = payload.city?._id || "no City selected";
      calendar.cityName = `${
        payload.city?.city ? `${payload.city?.city}, ` : ""
      }${payload.city?.state || ""}`;
      calendar.cityNameEn = `${
        payload.city?.cityEn ? `${payload.city?.cityEn}, ` : ""
      }${payload.city?.stateEn || ""}`;
      calendar.cityEmoji = payload.city?.emoji || {
        emoji: "",
        url: "",
      };
      calendar.cityDataCoor = {
        lat: payload.city?.lat || 0,
        lon: payload.city?.lon || -25,
        radius: payload.city?.radius || 10,
        airportCoord: payload.city?.airportCoord,
        zoom: !payload.city?._id && 2,
      };

      if (calendar.agency) {
        calendar.city = payload.city?._id || "You";
        calendar.cityName = `${
          payload.city?.city ? `${payload.city?.city}, ` : "Global"
        }${payload.city?.state || ""}`;
        calendar.cityNameEn = `${
          payload.city?.cityEn ? `${payload.city?.cityEn}, ` : "Global"
        }${payload.city?.stateEn || ""}`;
        calendar.cityEmoji = payload.city?.emoji || {
          emoji: "",
          url: "",
        };
      }
      calendar.users = payload.users;
      calendar.inv = payload.inv;

      const itemsCalendar = await api.get(`/usersplaces/get/${payload._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //arreglamos los items para poder guardarlo, con la opcion de first activa para no hacer peticiones innecesarias
      const items = await FixItems(itemsCalendar.data.data, true);
      calendar.agendaItems = items.list;
      calendar.dayAgenda = items.days;
      calendar.maxDate = moment(payload.maxDate);
      calendar.minDate = moment(payload.minDate);
      calendar.startDate = moment(payload.startDate);
      calendar.startAtTime = payload.startAtTime;
      calendar.public = payload.public || false;
      calendar.toDolist = payload.toDolist || [];
      calendar.toDolistUser = /*payload.toDolistUser ||*/ [];
      calendar.defaultToDoList = payload.defaultToDoList || false;

      const Todolist = _.reverse(payload.toDolist.concat(payload.toDolistUser));
      const itemsInCalendar = _.uniq(
        items.list.map((e) => {
          return e.name;
        })
      );
      calendar.toDolist = [
        calendar.hotel,
        ...Todolist.map((e) => {
          return { ...e, hide: itemsInCalendar.includes(e.name) };
        }),
      ].filter((place) => place);

      dispatch(setinitialvalues(calendar));
      return;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};

//esta funcion es para cargar los calendarios que obtenemos desde la lista de calendarios pero solo visualizacion
export const loadAdminPlacesInv = ({ payload, code }) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.user;
      const { _id } = token && jwtDecode(token).user;
      const nouser = !state.public && !payload.users.find((u) => u._id === _id);
      const noinv = !state.public && !payload.inv.find((u) => u._id === _id);

      if (nouser && noinv) {
        return { error: true };
      }
      const calendar = {};
      calendar.name = payload.name;
      calendar.hotel = payload.hotel;
      calendar.code = code;
      calendar.id = payload._id;
      calendar.city = payload.city?._id || "";
      calendar.cityName = `${
        payload.city?.city ? `${payload.city?.city}, ` : ""
      }${payload.city?.state || ""}`;
      calendar.cityNameEn = `${
        payload.city?.cityEn ? `${payload.city?.cityEn}, ` : ""
      }${payload.city?.stateEn || ""}`;
      calendar.cityEmoji = payload.city?.emoji || {
        emoji: "",
        url: "",
      };
      calendar.cityDataCoor = {
        lat: payload.city?.lat,
        lon: payload.city?.lon,
        radius: payload.city?.radius,
        airportCoord: payload.city?.airportCoord,
      };
      calendar.users = payload.users;
      calendar.inv = payload.inv;

      const itemsCalendar = await api.get(`/usersplaces/get/${payload._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //arreglamos los items para poder guardarlo, con la opcion de first activa para no hacer peticiones innecesaria
      const items = await FixItems(itemsCalendar.data.data, true);
      calendar.agendaItems = items.list;
      calendar.dayAgenda = items.days;
      calendar.maxDate = moment(payload.maxDate);
      calendar.minDate = moment(payload.minDate);
      calendar.startDate = moment(payload.startDate);
      calendar.startAtTime = payload.startAtTime;
      calendar.public = payload.public || false;
      calendar.defaultToDoList = payload.defaultToDoList || false;
      calendar.toDolist = [];
      calendar.toDolistUser = [];
      dispatch(setinitialvalues(calendar));
      /*
            const resp = await api.get(`/adminsplaces/get/${payload.city}`)
            const itemsInCalendar = state.calendar.agendaList.map((e) => {
                return e.name
            })
            const stack = resp.data.data.filter((e) => {
                return !(itemsInCalendar.includes(e.name))
            })
            dispatch(setPlaces(stack))*/
      return;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
/*
export const loadFilteredAdminPlaces = ({tags, namePlace}) => {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            var reqUrl = `/adminsplaces/getfiltered/${state.calendar.city}`
            /*if(payload.act) {
                reqUrl= reqUrl + `?act=${payload.act}`
            }
            if(payload.placT) {
                reqUrl= reqUrl + `?placT=${payload.placT}`
            }
            if(payload.dur) {
                reqUrl= reqUrl + `?dur=${payload.dur}`
            }
            if(payload.prec) {
                reqUrl= reqUrl + `?prec=${payload.prec}`
            }*
            const resp = await api.post(reqUrl,{tags, namePlace})
            const itemsInCalendar = state.calendar.agendaList.map((e) => {
                return e.name
            })
            const stack = resp.data.data.filter((e) => {
                return !(itemsInCalendar.includes(e.name))
            })
            dispatch(setPlaces(stack))
            return

        } catch (error) {
            //console.log(error)
            return error
        }
    }
}
*/

//crea las actividades de los usuarios
export const setPlacePost = (payload) => {
  return async (dispatch, getState) => {
    try {
      const resp = await api.post("/usersplaces/set", payload[1]);
      const state = getState();
      const code = state.calendar.code;
      const newState = [...state.calendar.agendaList];
      const e = { ...resp.data.data };
      e.endDateTime = moment(e.endDateTime);
      e.startDateTime = moment(e.startDateTime);

      newState.unshift(e);
      const newActivites = await FixItems(newState);

      await api.put(`/agendacalendar/modify/${payload[0]}?code=${code}`, {
        agendaItems: newActivites.list,
      });
      dispatch(setAgendaPlaces(newActivites));
    } catch (error) {
      //console.log(error)
    }
  };
};
//crea las actividades de los usuarios en el toDoList
export const setPlaceToToDoList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const newStack = [...state.calendar.toDolist];
      const resp = await api.post(
        `/agendacalendar/setToDoListUser/${payload[0]}`,
        { item: payload[1] }
      );
      if (resp.data.error) return resp.data.error;
      newStack.unshift(resp.data.data);
      dispatch(setPlaces(newStack));
    } catch (error) {
      //console.log(error)
    }
  };
};
//crea las actividades de los usuarios en el toDoList
export const UpdatePlacesToToDoList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const newStack = [...payload[1]];

      var useritems = [],
        adminItems = [],
        tourItems = [];
      payload[1].forEach((ite) => {
        if (ite._id.includes("personal")) {
          useritems.unshift(ite);
        } else if (!ite.tripendarActivities) {
          adminItems.unshift(ite._id);
        } else {
          tourItems.unshift(ite._id);
        }
      });
      dispatch(setPlaces(newStack));
      /*const resp =*/ await api.put(
        `/agendacalendar/updateToDoListUser/${payload[0]}`,
        {
          items: useritems,
          adminItems,
          tourItems: tourItems,
        }
      );
    } catch (error) {
      //console.log(error)
    }
  };
};
//cambia si el calendario es publico o no
export const changePublicCalendar = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      await api.put(
        `/agendacalendar/modifypublic/${payload[0]}?code=${code}`,
        { public: payload[1] },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      dispatch(setcalendarPublic(payload[1]));
    } catch (error) {
      //console.log(error)
    }
  };
};

/*----------------------------activites functions---------------*/
//esta funcion lo que hace mover desde la toDo list de la izquierda hacia el calendario pero las actividades de la base de datos
export const movPlacesToAgenda = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      var { destination, idModel, startAtTime, idCalendar } = payload;
      dispatch(setLoadingCalendar(true));
      const newStack = [...state.calendar.toDolist];
      let agenda = [];
      var endDateTime = state.calendar.startDate;
      var first = false;
      if (!destination.includes("T")) {
        const [year, mounth, day] = destination.split("/");

        if (state.calendar.agendaList.length !== 0) {
          const indexDest = state.calendar.dayAgenda[destination];
          if (indexDest) {
            endDateTime = state.calendar.agendaList[indexDest - 1].endDateTime;
          } else {
            endDateTime = `${year}/${mounth}/${day} ${startTimeActivities}:00:00`;
            first = true;
          }
          //ordenamos los items para obtener el ultimo de este
          agenda = [...state.calendar.agendaList];
        } else {
          endDateTime = `${year}/${mounth}/${day} ${startTimeActivities}:00:00`;
          first = true;
        }
      } else {
        endDateTime = moment(destination).format("YYYY/MM/DD HH:mm:00");
        destination = moment(destination).format("YYYY/MM/DD");
        agenda = [...state.calendar.agendaList];
        first = false;
      }

      const idCambio = newStack.findIndex((e) => {
        return e._id === idModel;
      });
      //hallamos el modelo que queremos mover y lo movemos a la ultima posicion
      const model = newStack[idCambio];
      /*
            if(model?.hotel) {
                try {

                    await dispatch(createSingleHotel({day:destination}))
                    dispatch(setLoadingCalendar(false))
                } catch (error) {

                    dispatch(setLoadingCalendar(false))
                }
                return
            }else if(model) {
                newStack.splice(idCambio, 1)
                newStack.push({...model,hide:true})
                dispatch(setPlaces(newStack))
            }
            */
      //---------------------------------------------------------------------------------------------------------

      if (model.tripendarActivities) {
        const momentDate = moment(endDateTime);
        const keys = Object.keys(model.workingH).sort((a, b) => {
          const dateA = moment(`${a} 00:00`);
          const dateB = moment(`${b} 00:00`);
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        let actualTemp = keys.find((date) => {
          const mDat = moment(`${date} 00:00`);
          const dife = mDat.diff(momentDate, "hours", true);
          return dife <= 0;
        });
        if (!actualTemp) {
          actualTemp = keys[keys.length - 1];
        }

        const worktime = model.workingH[actualTemp];

        let dia = moment(endDateTime).isoWeekday();
        const dayRange = Object.keys(worktime).find((da) => {
          const [initDayS, endDayS] = da.split("_");
          let initDay = parseInt(initDayS);
          let endDay = parseInt(endDayS);
          if (endDay < initDay) {
            dia <= endDay && (dia = 7 + dia);
            endDay = 7 + endDay;
          }
          return dia >= initDay && dia <= endDay;
        });
        if (worktime[dayRange]) {
          const [hour, minute] = worktime[dayRange].start.split(":");
          endDateTime = moment(endDateTime)
            .hour(hour)
            .minute(minute)
            .format("YYYY/MM/DD HH:mm:00");
        }
      }
      //---------------------------------------------------------------------------------------------------------
      try {
        //se crea un lugar del usuario tomando el modelo del adminplace
        const resp = await api.post("/usersplaces/setfrommodel", {
          model: idModel,
          startDate: endDateTime, // new Date(moment(`${destination} ${6}:00`))
          startAtTime: startAtTime,
          idCalendar: idCalendar,
        }); /**/
        const { data, meta, error } = resp.data;
        if (error) return;

        if (meta.typeModel !== "hotel") {
          newStack.splice(idCambio, 1);
          newStack.push({ ...model, hide: true });
          dispatch(setPlaces(newStack));
        }
        var activitie = { ...data };
        activitie.selected = true;
        activitie.adminRef = meta.typeModel === "place" && meta.modelData;
        activitie.tourRef = meta.typeModel === "tour" && meta.modelData;
        agenda.push(activitie);
        //una vez creado la actividad se prepara para enviarse
        const { items } = await handleSelectedActivity(agenda, activitie);
        activitie = null;
        const newPlacefix = await FixItems(items);
        await api.put(`/agendacalendar/modify/${idCalendar}?code=${code}`, {
          agendaItems: newPlacefix.list,
        });
        dispatch(setAgendaPlaces(newPlacefix)); /**/
        if (first) {
          return resp.data.data._id;
        } else {
          return "";
        }
      } catch (error) {
        //console.log(error)
        dispatch(setLoadingCalendar(false));
      }
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};
//esta funcion lo que hace mover desde la toDo list de la izquierda hacia el calendario pero las actividades creadas por el usuario
export const createSingleHotel = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { day } = payload;
      dispatch(setLoadingCalendar(true));
      const calendar = state.calendar;
      let agenda = [...state.calendar.agendaList];
      var endDateTime = `${day} 21:00:00`;
      const lat = calendar.cityDataCoor.lat,
        lon = calendar.cityDataCoor.lon;
      const resp = await api.get(`/MapRoute/direction?lat=${lat}&lon=${lon}`);
      const { error, data } = resp.data;
      if (error) return;
      try {
        //se crea un lugar del usuario tomando el modelo del adminplace
        const resp = await api.post("/usersplaces/set", {
          calendarCont: calendar.id,
          polyline: "",
          name: "hotel",
          stars: 0,
          place: data,
          lat,
          lon,
          dur: 12,
          priceType: "$",
          traveltime: 0,
          typeTrip: "car",
          startDateTime: endDateTime,
          endDateTime: moment(endDateTime)
            .add(12, "hours")
            .format("YYYY/MM/DD HH:mm:00"),
          classes: "color-2",
          urlImg: "",
          blocked: false,
          tickets: [],
          adminRef: "",
          notes: [],
        }); /**/
        var activitie = { ...resp.data.data };
        //activitie.selected = true
        activitie.adminRef = undefined;
        agenda.push(activitie);
        activitie = null;
        //una vez creado la actividad se prepara para enviarse
        const newPlacefix = await FixItems(agenda);
        const code = state.calendar.code;
        await api.put(`/agendacalendar/modify/${calendar.id}?code=${code}`, {
          agendaItems: newPlacefix.list,
        });
        dispatch(setAgendaPlaces(newPlacefix)); /**/
        return `${resp.data.data._id}-cellitem`;
      } catch (error) {
        //console.log(error)
        dispatch(setLoadingCalendar(false));
      }
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};
//esta funcion lo que hace mover desde la toDo list de la izquierda hacia el calendario pero las actividades creadas por el usuario
export const movPlacesUsersToAgenda = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { destination, idModel, idCalendar, data } = payload;
      dispatch(setLoadingCalendar(true));
      const newStack = [...state.calendar.toDolist];
      let agenda = [];
      var endDateTime = state.calendar.startDate;
      var first = false;
      if (!destination.includes("T")) {
        const [year, mounth, day] = destination.split("/");
        if (state.calendar.agendaList.length !== 0) {
          const indexDest = state.calendar.dayAgenda[destination];
          if (indexDest) {
            endDateTime = state.calendar.agendaList[indexDest - 1].endDateTime;
          } else {
            endDateTime = `${year}/${mounth}/${day} ${startTimeActivities}:00:00`;
            first = true;
          }
          //ordenamos los items para obtener el ultimo de este
          agenda = [...state.calendar.agendaList];
        } else {
          endDateTime = `${year}/${mounth}/${day} ${startTimeActivities}:00:00`;
          first = true;
        }
      } else {
        endDateTime = moment(destination).format("YYYY/MM/DD HH:mm:00");
        agenda = [...state.calendar.agendaList];
      }
      const idCambio = newStack.findIndex((e) => {
        return e._id === idModel;
      });
      //hallamos el modelo que queremos mover y lo movemos a la ultima posicion
      const model = newStack?.[idCambio] || data;
      if (idCambio >= 0) {
        newStack.splice(idCambio, 1);
        newStack.push({ ...model, hide: true });
      }

      dispatch(setPlaces(newStack));
      try {
        //se crea un lugar del usuario tomando el modelo del adminplace
        const resp = await api.post("/usersplaces/set", {
          calendarCont: idCalendar,
          polyline: "",
          name: model.name,
          place: model.place,
          stars: 0,
          lat: model.lat,
          lon: model.lon,
          dur: model.duration,
          priceType: model.priceType,
          traveltime: 0,
          typeTrip: "car",
          adminRef: "",
          startDateTime: endDateTime,
          endDateTime: moment(endDateTime)
            .add(model.duration, "hours")
            .format("YYYY/MM/DD HH:mm:00"),
          classes: model.classes,
          urlImg: "",
          blocked: false,
          tickets: model.tickets,
          notes: [],
        }); /**/
        var activitie = { ...resp.data.data };
        activitie.selected = true;
        activitie.adminRef = undefined;
        agenda.push(activitie);
        activitie = null;
        //una vez creado la actividad se prepara para enviarse
        const newPlacefix = await FixItems(agenda);
        const code = state.calendar.code;
        await api.put(`/agendacalendar/modify/${idCalendar}?code=${code}`, {
          agendaItems: newPlacefix.list,
        });
        dispatch(setAgendaPlaces(newPlacefix)); /**/
        if (first) {
          return resp.data.data._id;
        } else {
          return "";
        }
      } catch (error) {
        //console.log(error)
        dispatch(setLoadingCalendar(false));
      }
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};

//esta funcion elimina la actividad del usuario
export const movPlacesToStack = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingCalendar(true));

      await api.delete(`/usersplaces/delete/${payload}`);
      const state = getState();
      const newAgenda = [...state.calendar.agendaList];
      const idCambio = newAgenda.findIndex((e) => {
        return e._id === payload;
      });
      const getitem = newAgenda[idCambio];
      const Refid = getitem?.adminRef?._id || getitem?.tourRef?._id;
      const id = Refid ? Refid : getitem.name;
      const [eliminado] = newAgenda.splice(idCambio, 1);
      //lo de siempre, se ordenan para enviarse
      const fixList = await FixItems(newAgenda);
      dispatch(setAgendaPlaces(fixList));

      const resp = await api.get(
        `/agendacalendar/getActivitieFromTodo/${
          state.calendar.id
        }?item=${id}&userActivity=${!Refid}`
      );
      const { data, error /*meta*/ } = resp.data;
      if (error) {
        return error;
      }
      if (data) {
        const itemsInCalendar = [...state.calendar.toDolist];
        const nwIt = { ...data };
        delete nwIt.hide;
        const index = itemsInCalendar.findIndex((it) => it._id === nwIt._id);
        if (!Refid && nwIt.place !== eliminado.place) {
          nwIt.lat = eliminado.lat;
          nwIt.lon = eliminado.lon;
          nwIt.place = eliminado.place;
          itemsInCalendar[index] = nwIt;
          var useritems = [],
            adminItems = [],
            tourItems = [];
          itemsInCalendar.forEach((ite) => {
            if (ite._id.includes("personal")) {
              useritems.unshift(ite);
            } else if (!ite.tripendarActivities) {
              adminItems.unshift(ite._id);
            } else {
              tourItems.unshift(ite._id);
            }
          });
          /*const resp =*/ await api.put(
            `/agendacalendar/updateToDoListUser/${state.calendar.id}`,
            {
              items: useritems,
              adminItems,
              tourItems: tourItems.length !== 0 ? tourItems : undefined,
            }
          );
        } else {
          itemsInCalendar[index] = nwIt;
        }
        dispatch(setPlaces(itemsInCalendar));
      }
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};

//esta funcion es para guardar los items
export const saveAgenda = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingCalendar(true));
      const listItems = await FixItems(payload[1]);
      if (listItems.error) {
        dispatch(setLoadingCalendar(false));
        return { error: true };
      } else {
        const state = getState();
        const code = state.calendar.code;
        dispatch(setAgendaPlaces({ ...listItems, notSave: payload[2] }));
        await api.put(`/agendacalendar/modify/${payload[0]}?code=${code}`, {
          agendaItems: listItems.list,
        });
        const localCalendars = JSON.parse(localStorage.getItem("calendars"));
        const ind = localCalendars.findIndex((it) => it._id === payload[0]);
        if (ind >= 0) {
          localCalendars[ind] = {
            ...localCalendars[ind],
            createdAt: moment().toISOString(),
          };
          localCalendars.sort((a, b) => {
            if (moment(a.createdAt) < moment(b.createdAt)) {
              return 1;
            }
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return -1;
            }
            // a debe ser igual b
            return 0;
          });
          localStorage.setItem("calendars", JSON.stringify(localCalendars));
        }
      }
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};
export const onUndoActivities = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const prevActivities = [...state.calendar.prevAgendaList];
    const pastActivity = prevActivities.pop();

    const data = [state.calendar.id, pastActivity, true];
    dispatch(saveAgenda(data));
    dispatch(setPrevAgendaPlaces(prevActivities));
  };
};
//esta funcion es para guardar los items
export const moveRangeActivities = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      const { rangeIndexs, target } = payload;
      const agendaListCopy = state.calendar.agendaList.map((it) => ({ ...it }));
      const firstIndex = rangeIndexs.firstIndex,
        lastIndex = rangeIndexs.lastIndex + 1;
      const removeItems = agendaListCopy.splice(
        firstIndex,
        lastIndex - firstIndex
      );

      if (removeItems.length <= 0) return;
      const diffTranslate = moment(target).diff(
        moment(removeItems[0].startDateTime)
      );
      removeItems[0].startDateTime = moment(removeItems[0].startDateTime)
        .add(diffTranslate)
        .format("YYYY/MM/DD HH:mm:ss");
      removeItems[0].endDateTime = moment(removeItems[0].endDateTime)
        .add(diffTranslate)
        .format("YYYY/MM/DD HH:mm:ss");
      for (let i = 1; i < removeItems.length; i++) {
        removeItems[i].startDateTime = moment(removeItems[i].startDateTime)
          .add(diffTranslate)
          .format("YYYY/MM/DD HH:mm:ss");
        removeItems[i].endDateTime = moment(removeItems[i].endDateTime)
          .add(diffTranslate)
          .format("YYYY/MM/DD HH:mm:ss");
      }

      const data = [
        state.calendar.id,
        agendaListCopy.concat(removeItems),
        true,
      ];

      const prevActivities = [...state.calendar.prevAgendaList];
      prevActivities.push([...state.calendar.agendaList]);
      //dispatch(setPrevAgendaPlaces(prevActivities));
      dispatch(saveAgenda(data));
    } catch (error) {
      //console.log(error)
      dispatch(setLoadingCalendar(false));
    }
  };
};

//funcion que busca la mejor ruta (no esta operativa por cambio de horarios)
export const setOptimalActivities = ({ idCalendar, days }) => {
  return async (dispatch, getState) => {
    try {
      var daysWilloptimizade = days ? [...days] : [];
      const state = getState();
      const items = [...state.calendar.agendaList];
      const agendaDays = { ...state.calendar.dayAgenda };
      let newfullItems = [];
      let inicio = 0;
      const itemsObj = {};
      items.forEach((i) => {
        itemsObj[i._id] = i;
      });

      dispatch(setLoadingCalendar(true));
      //const newDaysAgenda ={}
      if (daysWilloptimizade.length === 0) {
        daysWilloptimizade = Object.keys(agendaDays);
      }
      for (const [day, value] of Object.entries(agendaDays)) {
        //se preparan los datos para enviar
        const startDay = moment(`${day} ${startTimeCalendar}:00`).isoWeekday();
        const nextDay = startDay === 7 ? 0 : startDay + 1;

        const itemsList = items.slice(inicio, value);
        const itemsToSort = itemsList.map((item) => {
          const objSend = {
            id: `${item._id}_${item.lat}`,
            address: {
              location_id: item._id,
              lon: item.lon,
              lat: item.lat,
            } /*
                        time_windows: [
                            {
                            earliest: 25200,
                            latest: 82800
                            },
                            {
                            earliest: 25200,
                            latest: 82800
                            }
                        ],*/,
            duration: parseInt(item.dur * 60 /*min*/ * 60 /*seg*/),
          };
          if (item.adminRef?.workingH && !item?.blocked) {
            //se colocan los horarios de apertura y cierre para enviarse
            const horario = item.adminRef.workingH[item.actualTemp];

            var horarioInSec = {};
            for (const [days, values] of Object.entries(horario)) {
              horarioInSec[days] = {
                start: values.start.split(":").reduce((accu, act) => {
                  return (parseInt(accu) * 60 + parseInt(act)) * 60;
                }),
                end: values.end.split(":").reduce((accu, act) => {
                  return (parseInt(accu) * 60 + parseInt(act)) * 60;
                }),
              };
              if (horarioInSec[days].end < horarioInSec[days].start) {
                horarioInSec[days].end = horarioInSec[days].end + 86400;
              }
            }

            var dia = startDay;
            var nextDayVar = nextDay;
            var weekNextday;
            var days;
            days = Object.keys(horario).find((da) => {
              const [initDayS, endDayS] = da.split("_");
              var initDay = parseInt(initDayS);
              var endDay = parseInt(endDayS);
              if (endDay < initDay) {
                dia <= endDay && (dia = 7 + dia);
                endDay = 7 + endDay;
              }
              return dia >= initDay && dia <= endDay;
            });
            weekNextday = Object.keys(horario).find((da) => {
              const [initDayS, endDayS] = da.split("_");
              var initDay = parseInt(initDayS);
              var endDay = parseInt(endDayS);
              if (endDay < initDay) {
                nextDayVar <= endDay && (nextDayVar = 7 + nextDayVar);
                endDay = 7 + endDay;
              }
              return nextDayVar >= initDay && nextDayVar <= endDay;
            });
            /* */
            const listaHorario = [];
            /*for(const [key, value] of Object.entries(daySeg)) {
                            const weekNextday = parseInt(key) >4 ? 'weekend' : 'week'
                            listaHorario.push({
                                earliest: horarioInSec[weekNextday].start + value,
                                latest: horarioInSec[weekNextday].end + value
                                })
                        }

                        if(days && horarioInSec[days]?.start >= 28600) {
                            listaHorario.push({
                                earliest: horarioInSec[days].start + daySeg[startDay],
                                latest: horarioInSec[days].end + daySeg[startDay]
                            })
                        } else if (days) {
                            listaHorario.push(
                                {
                                    earliest: 28800 + daySeg[startDay],
                                    latest: horarioInSec[days].end + daySeg[startDay]
                                },
                            )
                            if(weekNextday) {
                                listaHorario.push({
                                    earliest: horarioInSec[weekNextday].start + daySeg[nextDay],
                                    latest: 25200 + daySeg[nextDay]
                                })
                            }
                        }
                        */

            if (days) {
              if (horarioInSec[days].start <= horarioInSec[days].end) {
                listaHorario.push({
                  earliest: horarioInSec[days].start + daySeg[startDay],
                  latest: horarioInSec[days].end + daySeg[startDay],
                });
              } else if (horarioInSec[days].start > horarioInSec[days].end) {
                listaHorario.push({
                  earliest: horarioInSec[days].end + daySeg[startDay],
                  latest: horarioInSec[days].start + daySeg[startDay],
                });
                if (weekNextday) {
                  listaHorario.push({
                    earliest: horarioInSec[weekNextday].end + daySeg[nextDay],
                    latest: horarioInSec[weekNextday].start + daySeg[nextDay],
                  });
                }
              }
            }
            objSend.time_windows = listaHorario;
            if (objSend.time_windows.length === 0) {
              delete objSend.time_windows;
            }
          } else if (item.blocked) {
            objSend.time_windows = [
              {
                earliest: moment(item.startDateTime).unix(),
                latest: moment(item.startDateTime).unix() + 60,
              },
            ];
          }
          return objSend;
        });
        inicio = value;

        if (itemsToSort.length >= 3 && daysWilloptimizade.includes(day)) {
          const initItem = itemsToSort.shift();

          //se hace consulta de datos
          const dataToSend = {
            vehicles: [
              {
                vehicle_id: "vehicle-1",
                type_id: "user",
                start_address: {
                  location_id: initItem.address.location_id,
                  lon: initItem.address.lon,
                  lat: initItem.address.lat,
                },
              },
            ],
            vehicle_types: [
              {
                type_id: "user",
                profile: "car",
              },
            ],
            services: itemsToSort, //aqui va las actividades a ordenar

            configuration: {
              routing: {
                calc_points: false,
                snap_preventions: [
                  "motorway",
                  "trunk",
                  "tunnel",
                  "bridge",
                  "ferry",
                ],
              },
            },
          };

          //se obtiene un array con las actividades ordenadas
          const key = process.env.REACT_APP_GRAPHHOPPER_KEY || "drdcye5c";
          
          const optimalRoute = await axios.post(
            `https://graphhopper.com/api/1/vrp?key=${key}`,
            dataToSend
          );
          const resp = { ...optimalRoute.data.solution };
          if (resp.unassigned.services.length !== 0) {
            newfullItems = newfullItems.concat(itemsList);
          } else {
            const route = resp.routes[0].activities;
            route.pop();
            //const delindex = route.findIndex(i => i.name === 'finish')
            //route.splice(delindex, 1)
            const firstItem = { ...itemsObj[route["0"].location_id] };
            if (!firstItem.blocked) {
              firstItem.endDateTime = moment(`${day} ${startTimeActivities}:00`)
                .add(firstItem.dur, "hours")
                .format("YYYY/MM/DD HH:mm:00");
              firstItem.startDateTime = moment(
                `${day} ${startTimeActivities}:00`
              ).format("YYYY/MM/DD HH:mm:00");
            }
            delete firstItem.nextPlace;

            if (itemsObj[route["1"].location_id]) {
              try {
                firstItem.nextPlace = {
                  lat: itemsObj[route["1"].location_id].lat,
                  lon: itemsObj[route["1"].location_id].lon,
                  name: itemsObj[route["1"].location_id].name,
                };

                var dur;
                var poly;
                firstItem.typeTrip = "car";
                const resp = await api.get(
                  `/MapRoute/routes?origin=${firstItem.lat},${
                    firstItem.lon
                  }&destination=${itemsObj[route["1"].location_id].lat},${
                    itemsObj[route["1"].location_id].lon
                  }&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=car`
                );

                //en caso que ese modo de transporte no este disponible se guardan los lugares disponibles y se hace otra peticion
                if (
                  resp.data.data.available_travel_modes &&
                  resp.data.data.available_travel_modes.length > 0
                ) {
                  firstItem.typesTravels =
                    resp.data.data.available_travel_modes.map(
                      (el) => reversetravel[el.toLowerCase()]
                    );
                  firstItem.typeTrip = firstItem.typesTravels[0];
                  const respEmer = await api.get(
                    `/MapRoute/routes?origin=${firstItem.lat},${
                      firstItem.lon
                    }&destination=${itemsObj[route["1"].location_id].lat},${
                      itemsObj[route["1"].location_id].lon
                    }&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=${
                      resp.data.data.available_travel_modes[0]
                    }`
                  );
                  dur =
                    respEmer.data.data.routes?.[0]?.legs[0].duration.value || 0; // resp.data.routes?.[0]?.duration
                  poly =
                    respEmer.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
                } else {
                  dur = resp.data.data.routes?.[0]?.legs[0].duration.value || 0; // resp.data.routes?.[0]?.duration
                  poly = resp.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
                }
                if (dur) {
                  const durInMinutes = dur / 60.0;
                  firstItem.traveltime = durInMinutes || 1;
                  firstItem.polyline = poly;
                  firstItem.travelCost = 0;
                  firstItem.customTravel = false;
                } else {
                  firstItem.traveltime = 0;
                  firstItem.travelCost = 0;
                  firstItem.polyline = "";
                  firstItem.customTravel = false;
                  delete firstItem.nextPlace;
                }
              } catch (error) {
                //console.log(error)
                firstItem.traveltime = 0;
                firstItem.travelCost = 0;
                firstItem.polyline = "";
                firstItem.customTravel = false;
                delete firstItem.nextPlace;
              }

              firstItem.endDateTime = moment(`${day} ${startTimeActivities}:00`)
                .add(firstItem.dur, "hours")
                .add(firstItem.traveltime, "minutes"); //.format('YYYY/MM/DD HH:mm:00')
            }
            const newItemsList = [firstItem];
            delete route["0"];

            const ordenedlist = Object.entries(route).map(([key, value]) => {
              const it = { ...itemsObj[value.location_id] };
              return it;
            });
            /*
             */
            for (const it in ordenedlist) {
              const lastItem = newItemsList[newItemsList.length - 1];

              const item = { ...ordenedlist[it] };
              item.endDateTime = moment(item.endDateTime);
              item.startDateTime = moment(item.startDateTime);
              delete item.traveltime;
              if (ordenedlist[parseInt(it) + 1]) {
                try {
                  item.nextPlace = {
                    lat: ordenedlist[parseInt(it) + 1].lat,
                    lon: ordenedlist[parseInt(it) + 1].lon,
                    name: ordenedlist[parseInt(it) + 1].name,
                  };

                  dur = "";
                  poly = "";
                  item.typeTrip = "car";
                  const resp = await api.get(
                    `/MapRoute/routes?origin=${item.lat},${
                      item.lon
                    }&destination=${ordenedlist[parseInt(it) + 1].lat},${
                      ordenedlist[parseInt(it) + 1].lon
                    }&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=car`
                  );

                  //en caso que ese modo de transporte no este disponible se guardan los lugares disponibles y se hace otra peticion
                  if (
                    resp.data.data.available_travel_modes &&
                    resp.data.data.available_travel_modes.length > 0
                  ) {
                    item.typesTravels =
                      resp.data.data.available_travel_modes.map(
                        (el) => reversetravel[el.toLowerCase()]
                      );
                    item.typeTrip = item.typesTravels[0];
                    const respEmer = await api.get(
                      `/MapRoute/routes?origin=${item.lat},${
                        item.lon
                      }&destination=${ordenedlist[parseInt(it) + 1].lat},${
                        ordenedlist[parseInt(it) + 1].lon
                      }&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=${
                        resp.data.data.available_travel_modes[0]
                      }`
                    );
                    dur =
                      respEmer.data.data.routes?.[0]?.legs[0].duration.value ||
                      0; // resp.data.routes?.[0]?.duration
                    poly =
                      respEmer.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
                  } else {
                    dur =
                      resp.data.data.routes?.[0]?.legs[0].duration.value || 0; // resp.data.routes?.[0]?.duration
                    poly = resp.data.data.routes?.[0]?.overview_polyline.points; // resp.data.routes?.[0]?.duration
                  }
                  if (dur) {
                    const durInMinutes = dur / 60.0;
                    item.traveltime = durInMinutes || 1;
                    item.polyline = poly;
                    item.travelCost = 0;
                    item.customTravel = false;
                  } else {
                    item.traveltime = 0;
                    item.travelCost = 0;
                    item.polyline = "";
                    item.customTravel = false;
                    delete item.nextPlace;
                  }
                } catch (error) {
                  //console.log(error)
                  item.traveltime = 0;
                  item.travelCost = 0;
                  item.polyline = "";
                  item.customTravel = false;
                  delete item.nextPlace;
                }
              }
              //item.NewRoute =true
              if (!item.blocked) {
                const formatTime = formatHours(lastItem.endDateTime);
                item.startDateTime = moment(
                  `${formatTime.day} ${formatTime.time}`,
                  "YYYY/MM/DD HH:mm"
                );

                item.endDateTime = moment(item.startDateTime)
                  .add(item.dur, "hours")
                  .add(item.traveltime, "minutes"); //.format('YYYY/MM/DD HH:mm:00')
                newItemsList.push(item);
              } else {
                var firstItemSolap = newItemsList.findIndex(
                  (i) => moment(i.endDateTime) > moment(item.startDateTime)
                );
                if (firstItemSolap >= 0) {
                  newItemsList.splice(firstItemSolap, 0, item);
                  /*if(newItemsList[firstItemSolap-1] && newItemsList[firstItemSolap-1].endDateTime > newItemsList[firstItemSolap].startDateTime) {

                                        const oldit = newItemsList.splice(firstItemSolap-1, 1)[0];

                                        newItemsList.splice(firstItemSolap, 0, oldit);
                                        firstItemSolap--
                                    }*/
                  var itemStart = moment(item.endDateTime);
                  for (
                    var i = firstItemSolap + 1;
                    i < newItemsList.length;
                    i++
                  ) {
                    if (!newItemsList[i].blocked) {
                      newItemsList[i].startDateTime = moment(itemStart);
                      newItemsList[i].endDateTime = moment(itemStart).add(
                        newItemsList[i].dur,
                        "hours"
                      );
                    } else if (
                      newItemsList[i - 1] &&
                      newItemsList[i - 1].endDateTime >
                        newItemsList[i].startDateTime
                    ) {
                      newItemsList[i].endDateTime = moment(
                        newItemsList[i].startDateTime
                      ).add(newItemsList[i].dur, "hours");
                      delete newItemsList[i].nextPlace;
                      delete newItemsList[i].polyline;
                      delete newItemsList[i].traveltime;
                      var ele = { ...newItemsList[i] };
                      const oldit = newItemsList.splice(i - 1, 1)[0];

                      oldit.startDateTime = moment(ele.endDateTime);
                      oldit.endDateTime = moment(ele.endDateTime).add(
                        oldit.dur,
                        "hours"
                      );
                      ele = undefined;
                      newItemsList.splice(i, 0, oldit);
                      if (newItemsList[i + 1]) {
                        try {
                          newItemsList[i].nextPlace = {
                            lat: newItemsList[i + 1].lat,
                            lon: newItemsList[i + 1].lon,
                            name: newItemsList[i + 1].name,
                          };

                          var dura;
                          var polyline;
                          newItemsList[i].typeTrip = "car";
                          const resp = await api.get(
                            `/MapRoute/routes?origin=${newItemsList[i].lat},${
                              newItemsList[i].lon
                            }&destination=${newItemsList[i + 1].lat},${
                              newItemsList[i + 1].lon
                            }&key=${
                              process.env.REACT_APP_GOOGLEMAPS_KEY
                            }&mode=car`
                          );

                          //en caso que ese modo de transporte no este disponible se guardan los lugares disponibles y se hace otra peticion
                          if (
                            resp.data.data.available_travel_modes &&
                            resp.data.data.available_travel_modes.length > 0
                          ) {
                            newItemsList[i].typesTravels =
                              resp.data.data.available_travel_modes.map(
                                (el) => reversetravel[el.toLowerCase()]
                              );
                            newItemsList[i].typeTrip =
                              newItemsList[i].typesTravels[0];
                            const respEmer = await api.get(
                              `/MapRoute/routes?origin=${newItemsList[i].lat},${
                                newItemsList[i].lon
                              }&destination=${newItemsList[i + 1].lat},${
                                newItemsList[i + 1].lon
                              }&key=${
                                process.env.REACT_APP_GOOGLEMAPS_KEY
                              }&mode=${
                                resp.data.data.available_travel_modes[0]
                              }`
                            );
                            dura =
                              respEmer.data.data.routes?.[0]?.legs[0].duration
                                .value; // resp.data.routes?.[0]?.duration
                            polyline =
                              respEmer.data.data.routes?.[0]?.overview_polyline
                                .points; // resp.data.routes?.[0]?.duration
                          } else {
                            dura =
                              resp.data.data.routes?.[0]?.legs[0].duration
                                .value; // resp.data.routes?.[0]?.duration
                            polyline =
                              resp.data.data.routes?.[0]?.overview_polyline
                                .points; // resp.data.routes?.[0]?.duration
                          }
                          if (dur) {
                            const durInMinutes = dura / 60.0;
                            newItemsList[i].traveltime = durInMinutes || 1;
                            newItemsList[i].polyline = polyline;
                            newItemsList[i].travelCost = 0;
                            newItemsList[i].customTravel = false;
                          } else {
                            newItemsList[i].traveltime = 0;
                            newItemsList[i].travelCost = 0;
                            newItemsList[i].polyline = "";
                            newItemsList[i].customTravel = false;
                            delete newItemsList[i].nextPlace;
                          }
                        } catch (error) {
                          //console.log(error)
                          newItemsList[i].traveltime = 0;
                          newItemsList[i].travelCost = 0;
                          newItemsList[i].polyline = "";
                          newItemsList[i].customTravel = false;
                          delete newItemsList[i].nextPlace;
                        }
                      }
                      newItemsList[i].endDateTime = moment(
                        newItemsList[i].startDateTime
                      )
                        .add(newItemsList[i].dur, "hours")
                        .add(newItemsList[i].traveltime, "minutes");
                    }
                    itemStart = moment(newItemsList[i].endDateTime);
                  }
                  itemStart = undefined;
                } else {
                  newItemsList.push(item);
                }
              }
            }
            newfullItems = newfullItems.concat(newItemsList); //concat(newItemsBlocked)
          }
        } else {
          newfullItems = newfullItems.concat(itemsList);
        }
      }

      const ordenedlist = SortDate(
        newfullItems.map((item) => {
          item.startDateTime = moment(item.startDateTime).format(
            "YYYY/MM/DD HH:mm:00"
          );
          item.endDateTime = moment(item.endDateTime).format(
            "YYYY/MM/DD HH:mm:00"
          );
          return item;
        })
      );
      const listItems = await FixItems(ordenedlist);
      if (listItems.error) {
        dispatch(setLoadingCalendar(false));
        return { error: true };
      }
      dispatch(setAgendaPlaces(listItems));
      const code = state.calendar.code;
      await api.put(`/agendacalendar/modify/${idCalendar}?code=${code}`, {
        agendaItems: listItems.list,
      });
    } catch (error) {
      const hints = error.data?.hints || [];
      const exist = hints.find(
        (hint) => hints.details === "ConnectionNotFound"
      );

      dispatch(setLoadingCalendar(false));
      return {
        error: true,
        type: exist ? "PathNotFinded" : error?.data?.message,
      };
    }
  };
};
//funcion que busca la mejor ruta (no esta operativa por cambio de horarios)
export const setOptimalCalendar = (payload) => {
  return async (dispatch, getState) => {
    try {
      //datos--------------------------------------------------------------------------------------------------
      const state = getState();
      const itemsToDo = [...state.calendar.toDolist];
      const { lat, lon } = state.calendar.cityDataCoor;
      const airportCoord = !!state.calendar.cityDataCoor?.airportCoord?.lat
        ? state.calendar.cityDataCoor?.airportCoord
        : state.calendar.cityDataCoor;
      //TODO: cambiar el lat y longitude de origen para
      const origen = {
        lat: airportCoord.lat,
        lon: airportCoord.lon,
        time: moment(payload?.arrivalTime).format("YYYY-MM-DD 12:00:00"),
      };
      const destino = {
        lat: airportCoord.lat,
        lon: airportCoord.lon,
        time: moment(payload?.returnTime).format("YYYY-MM-DD 5:00:00"),
      };
      const hotel = {
        lat,
        lon,
      };
      if (itemsToDo.length > 30) return;
      const itemsObj = {};
      itemsToDo.forEach((i) => {
        itemsObj[i._id] = i;
      });
      //preparacion de los datos------------------------------------------------------------------------------------

      //aqui se valida que exista ruta en las actividades creadas
      const personalActivities = itemsToDo.filter((it) => {
        return it._id.includes("personal");
      });
      for (const personalAct of personalActivities) {
        try {
          const resp = await api.get(
            `/MapRoute/routes?origin=${personalAct.lat},${personalAct.lon}&destination=${origen.lat},${origen.lon}&key=${process.env.REACT_APP_GOOGLEMAPS_KEY}&mode=car`
          );

          const existRoute =
            resp.data.data.routes?.[0]?.legs[0].duration.value || 0; // resp.data.routes?.[0]?.duration

          if (!existRoute) {
            return;
          }
        } catch (error) {
          //console.log(error)
          return;
        }
      }
      const itemsToSort = itemsToDo.map((item) => {
        const objSend = {
          id: `${item._id}_${item.lat}`,
          address: {
            location_id: item._id,
            lon: item.lon,
            lat: item.lat,
          } /*
                    time_windows: [
                        {
                        earliest: 25200,
                        latest: 82800
                        },
                        {
                        earliest: 25200,
                        latest: 82800
                        }
                    ],*/,
          duration: parseInt(item.dur * 60 /*min*/ * 60 /*seg*/),
        };
        return objSend;
      });

      //optimizacion---------------------------------------------------------------------------------------------

      //se hace consulta de datos
      const dataToSend = {
        vehicles: [
          {
            vehicle_id: "vehicle-1",
            type_id: "user",
            start_address: {
              location_id: "HotelDirection",
              lon: origen.lon,
              lat: origen.lat,
            },
          },
        ],
        vehicle_types: [
          {
            type_id: "user",
            profile: "car",
          },
        ],
        services: itemsToSort, //aqui va las actividades a ordenar

        configuration: {
          routing: {
            calc_points: false,
            snap_preventions: [
              "motorway",
              "trunk",
              "tunnel",
              "bridge",
              "ferry",
            ],
          },
        },
      };

      //se obtiene un array con las actividades ordenadas
      const key = process.env.REACT_APP_GRAPHHOPPER_KEY || "drdcye5c";

      const optimalRoute = await axios.post(
        `https://graphhopper.com/api/1/vrp?key=${key}`,
        dataToSend
      );
      const resp = { ...optimalRoute.data.solution };
      if (resp.unassigned.services.length !== 0) {
      } else {
        const route = resp.routes[0].activities;
        route.pop();
        route.shift();
        const ordenedListTodo = route.map((it) => {
          return itemsObj[it.location_id];
        });

        const code = state.calendar.code;
        const responseOptim = await api.post(
          `/agendacalendar/createCalendarActivitesFromList/${state.calendar.id}?code=${code}`,
          {
            items: ordenedListTodo,
            tripIn: origen,
            tripOut: payload.haveTimes && destino,
            hotel: hotel,
          }
        );
        const { data, error /*meta*/ } = responseOptim;
        if (error) {
          return error;
        }

        const { activites: itemsActivities, toDoList } = data.data;

        const listItems = await FixItems(itemsActivities);
        if (listItems.error) {
          dispatch(setLoadingCalendar(false));
          return { error: true };
        }

        dispatch(setAgendaPlaces(listItems));
        dispatch(setPlaces(toDoList));
      }

      /*const ordenedlist = SortDate(newfullItems.map((item)=> {
                item.startDateTime =moment(item.startDateTime).format('YYYY/MM/DD HH:mm:00')
                item.endDateTime =moment(item.endDateTime).format('YYYY/MM/DD HH:mm:00')
                return (item)
            }))
            const listItems = await FixItems(ordenedlist)
            if(listItems.error){
                dispatch(setLoadingCalendar(false))
                return {error:true}
            }
            dispatch(setAgendaPlaces(listItems))
            const code = state.calendar.code
            /*await api.put(`/agendacalendar/modify/${idCalendar}?code=${code}`, {agendaItems: listItems.list})*/
    } catch (error) {
      //console.log(error)

      dispatch(setLoadingCalendar(false));
      return { error: true };
    }
  };
};
/*--------------------------------------------------------------Users and Inv Calendar----------------------*/
//se agrega un nuevo usuario que puede editar o solo ver
export const setNewUserAgenda = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      const newUsers = [...state.calendar.users];
      const newInv = [...state.calendar.inv];

      if (
        !newUsers.find((e) => {
          return e._id === payload[1]._id;
        }) &&
        !newInv.find((e) => {
          return e._id === payload[1]._id;
        })
      ) {
        //si es edit se puede editar
        var resp;
        if (payload[2] === "edit") {
          newUsers.push({
            ...payload[1],
            color: getRandomColor(newUsers.length + 1),
          });
          const localToken =
            window.sessionStorage.getItem("jwtuser") ||
            localStorage.getItem("jwtuser");
          const auth = state.user.token || localToken; //cookies.get('jwtuser')
          //se usa un map porque lo que se envia son los id
          resp = await api.put(
            `/agendacalendar/modifyUsers/${payload[0]}?code=${code}`,
            {
              users: newUsers.map((e) => e._id),
              userId: payload[1]._id,
              userEmail: payload[1].email,
            },
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          );
          dispatch(saveUsers(newUsers));
        } else {
          newInv.push({
            ...payload[1],
            color: getRandomColor(newUsers.length + 1),
          });
          const localToken =
            window.sessionStorage.getItem("jwtuser") ||
            localStorage.getItem("jwtuser");
          const auth = state.user.token || localToken; //cookies.get('jwtuser')
          resp = await api.put(
            `/agendacalendar/modifyInv/${payload[0]}?code=${code}`,
            {
              inv: newInv.map((e) => e._id),
              userId: payload[1]._id,
              userEmail: payload[1].email,
            },
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          );
          dispatch(saveInv(newInv));
        }

        if (resp.data.accounts.emailsEditInv) {
          const unregisters = {
            editors: resp.data.accounts.emailsEditInv,
            viewer: resp.data.accounts.emailsViewInv,
          };
          dispatch(setUnregisterUsers(unregisters));
        }
      }
    } catch (error) {
      //console.log(error)
    }
  };
};
//esta funcion cambia el tipo de usuario
export const changeTypeUser = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { idCalendar, user } = payload;
      const state = getState();
      const code = state.calendar.code;
      const newUsers = [...state.calendar.users];
      const newInv = [...state.calendar.inv];
      const userId = newUsers.findIndex((u) => u._id === user._id);
      const invId = newInv.findIndex((u) => u._id === user._id);
      var user_Id;
      //aqui se intercambian
      if (userId >= 0) {
        user_Id = newUsers[userId]._id;
        newInv.push(newUsers[userId]);
        newUsers.splice(userId, 1);
      } else if (invId >= 0) {
        user_Id = newInv[invId]._id;
        newUsers.push(newInv[invId]);
        newInv.splice(invId, 1);
      } else {
        return;
      }
      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      if (auth) {
        await api.put(
          `/agendacalendar/modifyUsers/${idCalendar}?code=${code}`,
          { users: newUsers.map((e) => e._id), userId: user_Id },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        await api.put(
          `/agendacalendar/modifyInv/${idCalendar}?code=${code}`,
          { inv: newInv.map((e) => e._id), userId: user_Id },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        dispatch(saveUsersInv({ inv: newInv, users: newUsers }));
      }
      /*
       */
    } catch (error) {
      //console.log(error)
    }
  };
};
//funcion para eliminar usuarios en el calendario
export const deleteUserCalendar = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      const arrayUsers = [...state.calendar.users];
      const arrayInv = [...state.calendar.inv];
      const delUsers = arrayUsers.findIndex((e) => {
        return e._id === payload[1]._id;
      });
      const delInv = arrayInv.findIndex((e) => {
        return e._id === payload[1]._id;
      });

      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      if (delUsers >= 0) {
        arrayUsers.splice(delUsers, 1);
        await api.put(
          `/agendacalendar/modifyUsers/${payload[0]}?code=${code}`,
          { users: arrayUsers.map((e) => e._id) },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        dispatch(saveUsers(arrayUsers));
      } else if (delInv >= 0) {
        arrayInv.splice(delInv, 1);
        await api.put(
          `/agendacalendar/modifyInv/${payload[0]}?code=${code}`,
          { inv: arrayInv.map((e) => e._id) },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        dispatch(saveInv(arrayInv));
      }
    } catch (error) {
      //console.log(error)
    }
  };
};

//funcion para eliminar usuarios no Registrados en el calendario
export const deleteUnregisterUserCalendar = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const arrayUsers = [...state.calendar.emailsEditInv];
      const arrayInv = [...state.calendar.emailsViewInv];
      const delUsers = arrayUsers.findIndex((e) => {
        return e === payload[1];
      });
      const delInv = arrayInv.findIndex((e) => {
        return e === payload[1];
      });

      if (delUsers >= 0) {
        arrayUsers.splice(delUsers, 1); /*
                const auth = state.user.token || localStorage.getItem('jwtuser') //cookies.get('jwtuser')
                await api.put(`/agendacalendar/modifyUsers/${payload[0]}`, {users: arrayUsers.map(e => e._id)}, {
                    headers : {
                        Authorization: `Bearer ${auth}`
                    }
                })
                dispatch(saveUsers(arrayUsers))*/
      } else if (delInv >= 0) {
        arrayInv.splice(delInv, 1); /*
                const auth = state.user.token || localStorage.getItem('jwtuser') //cookies.get('jwtuser')
                await api.put(`/agendacalendar/modifyInv/${payload[0]}`, {inv: arrayInv.map(e => e._id)}, {
                    headers : {
                        Authorization: `Bearer ${auth}`
                    }
                })
                dispatch(saveInv(arrayInv))*/
      }
      /*const adduser =*/
      if (payload[2]) {
        if (payload[2] === "edit") {
          arrayUsers.push(payload[1]);
        } else if (payload[2] === "inv") {
          arrayInv.push(payload[1]);
        }
      }
      const code = state.calendar.code;
      await api.put(
        `/agendacalendar/modifyUsersInvEmail/${payload[0]}?code=${code}`,
        {
          email: payload[1],
          to: payload[2],
        }
      );

      const unregisters = {
        editors: arrayUsers,
        viewer: arrayInv,
      };
      dispatch(setUnregisterUsers(unregisters));
    } catch (error) {
      //console.log(error)
    }
  };
};
/*------------------------------------------------------------------calendarList----------------------*/
//setclear
export const clearCalendarList = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setcalendarlist([]));
      dispatch(setcalendarinvlist([]));
      dispatch(setcalendarlistPage(0));
      dispatch(setcalendarlistCount(0));
      return;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//extrae un calendario en la lista
export const extractCalendarInList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      var copyCalendarList = [...state.calendarList.list];
      var copyCalendarListInv = [...state.calendarList.invList];
      const calendarId = copyCalendarList.findIndex((u) => u._id === payload);
      const calendarInvId = copyCalendarListInv.findIndex(
        (u) => u._id === payload
      );
      if (calendarId >= 0) {
        copyCalendarList.splice(calendarId, 1);
      } else if (calendarInvId >= 0) {
        copyCalendarListInv.splice(calendarInvId, 1);
      }
      if (calendarId >= 0 || calendarInvId >= 0) {
        dispatch(setcalendarlist(copyCalendarList));
        dispatch(setcalendarinvlist(copyCalendarListInv));
        dispatch(setcalendarlistCount(state.calendarList.Nitems - 1));
      }
      return;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//esta funcion busca todos los calendarios del usuario, tanto los que puede editar como los que solo puede ver
export const setCalendarList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      var copyCalendarList = [...state.calendarList.list];
      var copyCalendarListInv = [...state.calendarList.invList];
      var page = state.calendarList.page;
      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      const respCalendar = await api.get(
        `/agendacalendar/getUserCalendars?user=${payload}&page[number]=${page}`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      const { data, error, meta } = respCalendar.data;
      if (error) return error;
      copyCalendarList = copyCalendarList.concat(data.editor);
      copyCalendarListInv = copyCalendarListInv.concat(data.viewer);

      dispatch(setcalendarlist(copyCalendarList));
      dispatch(setcalendarinvlist(copyCalendarListInv));
      dispatch(setcalendarlistCount(meta.page.total));

      dispatch(setcalendarlistPage(++page));
      return { calendar: data.editor.length, calendarInv: data.viewer.length };
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//uncion para copiar un calendario de un usuario
export const CopyCalendarList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id, name } = payload;
      const state = getState();
      const code = state.calendar.code;
      const { token } = state.user;
      const calendarList = [...state.calendarList.list];
      const calendarInvList = [...state.calendarList.invList];
      const calendarIndex = calendarList.findIndex((u) => u._id === id);
      const calendarInvitedIndex = calendarInvList.findIndex(
        (u) => u._id === id
      );
      const newUsers = [jwtDecode(token).user._id];
      const respbd = await api.post(
        `/agendacalendar/clone/${id}?code=${code}`,
        { users: newUsers, name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, error } = respbd.data;
      if (error) return;

      if (calendarIndex >= 0) {
        calendarList.unshift(data);
      } else if (calendarInvitedIndex >= 0) {
        calendarInvList.unshift(data);
      } else {
        return;
      }
      dispatch(setcalendarlist(calendarList));
      dispatch(setcalendarinvlist(calendarInvList));
      //dispatch(setcalendarlistCount(meta.page.total))

      // dispatch(setcalendarlistPage(++page))
      return {
        calendar: calendarList.length,
        calendarInv: calendarInvList.length,
      };
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//elimina el calendario y lo retira de la lista
export const deleteCalendarinList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const code = state.calendar.code;
      const { token } = state.user;
      const calendarList = [...state.calendarList.list];
      const calendarInvList = [...state.calendarList.invList];
      const calendarId = calendarList.findIndex((u) => u._id === payload);
      const calendarInvId = calendarInvList.findIndex((u) => u._id === payload);
      var calendar;
      if (calendarId >= 0) {
        calendar = calendarList[calendarId];
      } else if (calendarInvId >= 0) {
        calendar = calendarInvList[calendarInvId];
      } else {
        var calendarsLocal = JSON.parse(localStorage.getItem("calendars"));
        var localDelete = false;
        for (var i = 0; i < calendarsLocal.length; i++) {
          if (calendarsLocal[i]._id === payload) {
            calendarsLocal.splice(i, 1);
            localDelete = true;
          }
        }
        if (localDelete) {
          await api.delete(`/agendacalendar/delete/${payload}`, {
            headers: {
              Authorization: `Bearer  `,
            },
          });
        }
        return;
      }
      const newUsers = [...calendar.users];
      const newInv = [...calendar.inv];
      const userId = newUsers.findIndex(
        (u) => u._id === jwtDecode(token).user._id
      );
      const invId = newInv.findIndex(
        (u) => u._id === jwtDecode(token).user._id
      );
      var respbd;

      if ((userId > 0 || invId >= 0) && newUsers.length >= 1) {
        //aqui se intercambian
        try {
          newUsers.splice(userId, 1);
          if (userId >= 0) {
            respbd = await api.put(
              `/agendacalendar/modifyUsers/${calendar._id}?code=${code}`,
              { users: newUsers.map((e) => e._id) },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else if (invId >= 0) {
            newInv.splice(invId, 1);
            respbd = await api.put(
              `/agendacalendar/modifyInv/${calendar._id}?code=${code}`,
              { inv: newInv.map((e) => e._id) },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        } catch (error) {
          //console.log(error)
        }
      } else {
        try {
          const localToken =
            window.sessionStorage.getItem("jwtuser") ||
            localStorage.getItem("jwtuser");
          const auth = state.user.token || localToken; //cookies.get('jwtuser')
          respbd = await api.delete(`/agendacalendar/delete/${payload}`, {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          });
        } catch (error) {
          //console.log(error)
          return false;
        }
      }

      if (!respbd.data.error && calendarId >= 0) {
        const calendarioscopy = [...calendarList];
        const ideliminar = calendarioscopy.findIndex((element) => {
          return element._id === payload;
        });
        calendarioscopy.splice(ideliminar, 1);
        dispatch(setcalendarlist(calendarioscopy));
      } else if (!respbd.data.error && calendarInvId >= 0) {
        const calendariosInvcopy = [...calendarInvList];
        const ideliminar = calendariosInvcopy.findIndex((element) => {
          return element._id === payload;
        });
        calendariosInvcopy.splice(ideliminar, 1);
        dispatch(setcalendarinvlist(calendariosInvcopy));
      }
      const calendarnew = {};
      calendarnew.city = "";
      calendarnew.cityName = "";
      calendarnew.cityNameEn = "";
      calendarnew.id = "";
      calendarnew.name = "";
      calendarnew.users = [];
      calendarnew.inv = [];
      calendarnew.agendaItems = [];
      calendarnew.toDolist = [];
      calendarnew.toDolistUser = [];
      calendarnew.maxDate = new Date();
      calendarnew.minDate = new Date();
      calendarnew.startDate = new Date();
      calendarnew.startAtTime = 6;
      calendarnew.public = false;
      dispatch(setinitialvalues(calendarnew));
      return true;
      /*
            if(!respbd.data.error) {
                    const ideliminar = calendarioscopy.findIndex((element) => {
                    return element._id === c._id
                    })
                    dispatch(setcalendarlist(respCalendar.data.data))
                } else {
                    const MySwal = withReactContent(Toast)
                    await MySwal.fire({
                        title: <p style={{fontSize: '1.5rem'}}>Password invalida</p>,
                        icon: 'error',
                    })
                }*/
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};

/*---------------------------------------------ShopCarList------------------------------------------------------*/
//la shopcar list es la lista de los lugares que el usuario quiere in en una ciudad
export const setNewShopCarList = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const localToken =
      window.sessionStorage.getItem("jwtuser") ||
      localStorage.getItem("jwtuser");
    const auth = state.user.token || localToken; //cookies.get('jwtuser')
    var newListShop = [];
    var resp;
    //en esta se obtiene la lista de la ciudad determinada

    try {
      //
      if (auth) {
        //TODO: hacer peticion para obtener la nueva lista si esta log
        resp = await api.get(`/user/getlist?id=${payload}`, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        const {
          data,
          error,
          //meta
        } = resp.data;
        if (error) return;

        newListShop = [...data];
      } else {
        const items = localStorage.getItem(payload)
          ? JSON.parse(localStorage.getItem(payload))
          : [];
        newListShop = items;
      }
    } catch (error) {
      //console.log(error)
      const items = localStorage.getItem(payload)
        ? JSON.parse(localStorage.getItem(payload))
        : [];
      newListShop = items;
    }
    try {
      const respPlaces = await api.get(`/adminsplaces/get/${payload}`);

      const { data, error, meta } = respPlaces.data;
      if (error) return;
      const dataobj = {
        list: newListShop,
        page: 0,
        places: data,
        Nitems: meta.page.total,
      };
      dispatch(setUserShopCarList(dataobj));
    } catch (error) {
      //console.log(error)
      return error;
    }
    return newListShop;
  };
};
export const setOnlyNewShopCarList = (payload) => {
  return async (dispatch, getState) => {
    //en esta se obtiene la lista de la ciudad determinada
    try {
      //
      const state = getState();
      const localToken =
        window.sessionStorage.getItem("jwtuser") ||
        localStorage.getItem("jwtuser");
      const auth = state.user.token || localToken; //cookies.get('jwtuser')
      var newListShop = [];
      var resp;
      if (auth) {
        //TODO: hacer peticion para obtener la nueva lista si esta log
        resp = await api.get(`/user/getlist?id=${payload}`, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
        const {
          data,
          error,
          //meta
        } = resp.data;
        if (error) return;
        newListShop = [...data];
      } else {
        const items = localStorage.getItem(payload)
          ? JSON.parse(localStorage.getItem(payload))
          : [];
        newListShop = items;
      }
      const data = {
        list: newListShop,
      };
      dispatch(setUserShopCarList(data));

      return newListShop;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//placesAdmin-----------------------------------------------------------------------------------------------------------------

//la shopcar list es la lista de los lugares que el usuario quiere in en una ciudad
export const setPlacesAdmin = (payload = {}) => {
  return async (dispatch, getState) => {
    const {
      query = "",
      hidden = "",
      incomplete = "",
      page = 0,
      city = "",
    } = payload;

    //const state = getState()
    //const localToken = window.sessionStorage.getItem('jwtuser')|| localStorage.getItem('jwtuser')
    // const auth = state.user.token || localToken //cookies.get('jwtuser')
    var newListShop = [];
    try {
      const data = {
        page: page,
        places: [],
        Nitems: 0,
        query: query,
        city,
      };
      dispatch(setPlacesAdminList(data));
    } catch (error) {
      //console.log(error)
    }
    try {
      const respcountry = await api.get(
        `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
      );
      const { data, error, meta } = respcountry.data;
      if (error) return;
      const dataobj = {
        page: page,
        places: data,
        Nitems: meta.page.total,
        query: query,
        hidden,
        incomplete,
        city,
      };
      dispatch(setPlacesAdminList(dataobj));
    } catch (error) {
      //console.log(error)
      return error;
    }
    return newListShop;
  };
};
//cargar nuevos blogs
export const loadNextPlacesAdmins = (payload) => {
  return async (dispatch, getState) => {
    //en esta se obtiene la lista de la ciudad determinada
    try {
      //
      const state = getState();
      var query = state.adminPlaces.query;
      var page = ++state.adminPlaces.page;
      var hidden = state.adminPlaces.hidden;
      var city = state.adminPlaces.city;
      var incomplete = state.adminPlaces.incomplete;
      const respcountry = await api.get(
        `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
      );
      const { data, error, meta } = respcountry.data;
      if (error) return;
      const dataobj = {
        page: page,
        places: data,
        Nitems: meta.page.total,
        query: query,
        hidden,
        incomplete,
        city,
      };
      dispatch(setPlacesAdminList(dataobj));
      return page;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
export const loadPrevPlacesAdmins = (payload) => {
  return async (dispatch, getState) => {
    //en esta se obtiene la lista de la ciudad determinada
    try {
      //
      const state = getState();
      var query = state.adminPlaces.query;
      var page = --state.adminPlaces.page;
      var hidden = state.adminPlaces.hidden;
      var incomplete = state.adminPlaces.incomplete;
      var city = state.adminPlaces.city;
      const respcountry = await api.get(
        `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
      );
      const { data, error, meta } = respcountry.data;
      if (error) return;
      const dataobj = {
        page: page,
        places: data,
        Nitems: meta.page.total,
        query: query,
        hidden,
        incomplete,
        city,
      };
      dispatch(setPlacesAdminList(dataobj));
      return page;
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//elimina el blog y lo retira de la lista
export const toggleHidePlacesAdmininList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //const code = state.calendar.code
      //const auth = state.user.token  //cookies.get('jwtuser')
      var List = [...state.adminPlaces.places];
      const blogId = List.findIndex((u) => u._id === payload);
      if (!(blogId >= 0)) {
        return;
      }

      const respbd = await api.get(`/adminsplaces/toggleHide/${payload}`);
      if (!respbd.data.error && blogId >= 0) {
        var query = state.adminPlaces.query;
        var page = state.adminPlaces.page;
        var hidden = state.adminPlaces.hidden;
        var incomplete = state.adminPlaces.incomplete;
        var city = state.adminPlaces.city;
        const respcountry = await api.get(
          `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
        );
        const { data, error, meta } = respcountry.data;
        if (error) return;
        const dataobj = {
          page: page,
          places: data,
          Nitems: meta.page.total,
          query: query,
          hidden,
          incomplete,
          city,
        };
        /*const calendarioscopy = [...List]
                const ideliminar = calendarioscopy.findIndex((element) => {
                    return element._id === payload
                })
                calendarioscopy[ideliminar].hide = !calendarioscopy[ideliminar].hide
                const data = {
                    page: state.adminPlaces.page,
                    places : calendarioscopy,
                    Nitems : (state.adminPlaces.Nitems),
                    query : state.adminPlaces.query,
                    hidden : state.adminPlaces.hidden,
                    incomplete : state.adminPlaces.incomplete
                }*/
        dispatch(setPlacesAdminList(dataobj));
        return true;
      }
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//elimina el blog y lo retira de la lista
export const toggleIncompletePlacesAdmininList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //const code = state.calendar.code
      //const auth = state.user.token  //cookies.get('jwtuser')
      var List = [...state.adminPlaces.places];
      const blogId = List.findIndex((u) => u._id === payload);
      if (!(blogId >= 0)) {
        return;
      }

      const respbd = await api.get(`/adminsplaces/toggleincomplete/${payload}`);
      if (!respbd.data.error && blogId >= 0) {
        /*const calendarioscopy = [...List]
                const ideliminar = calendarioscopy.findIndex((element) => {
                    return element._id === payload
                })
                calendarioscopy[ideliminar].hide = !calendarioscopy[ideliminar].hide
                const data = {
                    page: state.adminPlaces.page,
                    places : calendarioscopy,
                    Nitems : (state.adminPlaces.Nitems),
                    query : state.adminPlaces.query,
                    hidden : state.adminPlaces.hidden,
                    incomplete : state.adminPlaces.incomplete
                }*/
        var query = state.adminPlaces.query;
        var page = state.adminPlaces.page;
        var hidden = state.adminPlaces.hidden;
        var incomplete = state.adminPlaces.incomplete;
        var city = state.adminPlaces.city;
        const respcountry = await api.get(
          `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
        );
        const { data, error, meta } = respcountry.data;
        if (error) return;
        const dataobj = {
          page: page,
          places: data,
          Nitems: meta.page.total,
          query: query,
          hidden,
          incomplete,
          city,
        };
        dispatch(setPlacesAdminList(dataobj));
        return true;
      }
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//cambia el estado incompleto a todos los elementos en la pagina actual
export const toggleAllIncompletePlacesAdmininList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      var List = state.adminPlaces.places;
      for (const activitie of List) {
        /*const respbd = */ await api.get(
          `/adminsplaces/toggleincomplete/${activitie._id}`
        );
      }
      var query = state.adminPlaces.query;
      var page = state.adminPlaces.page;
      var hidden = state.adminPlaces.hidden;
      var incomplete = state.adminPlaces.incomplete;
      var city = state.adminPlaces.city;
      const respcountry = await api.get(
        `/adminsplaces/getfromcountry?country=${query}&&onlyHidden=${hidden}&&onlyIncomplete=${incomplete}&&page[number]=${page}&&city=${city}`
      );
      const { data, error, meta } = respcountry.data;
      if (error) return;
      const dataobj = {
        page: page,
        places: data,
        Nitems: meta.page.total,
        query: query,
        hidden,
        incomplete,
        city,
      };
      dispatch(setPlacesAdminList(dataobj));
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//elimina el blog y lo retira de la lista
export const deletePlacesAdmininList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //const code = state.calendar.code
      const auth = state.user.token; //cookies.get('jwtuser')
      var List = [...state.adminPlaces.places];
      const blogId = List.findIndex((u) => u._id === payload);
      if (!(blogId >= 0)) {
        return;
      }
      var respbd = await api.delete(`/adminsplaces/delete/${payload}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (!respbd.data.error && blogId >= 0) {
        const calendarioscopy = [...List];
        const ideliminar = calendarioscopy.findIndex((element) => {
          return element._id === payload;
        });
        calendarioscopy.splice(ideliminar, 1);
        const data = {
          page: state.adminPlaces.page,
          places: calendarioscopy,
          Nitems: state.adminPlaces.Nitems,
          query: state.adminPlaces.query,
          hidden: state.adminPlaces.hidden,
          incomplete: state.adminPlaces.incomplete,
        };
        dispatch(setPlacesAdminList(data));
        return true;
      }
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
//reemplaza un articulo por otro ya editado
export const replacePlacesAdmininList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      //const code = state.calendar.code
      //const auth = state.user.token  //cookies.get('jwtuser')
      var List = [...state.adminPlaces.places];
      const blogId = List.findIndex((u) => u._id === payload._id);
      if (!(blogId >= 0)) {
        return;
      }
      List[blogId] = payload;
      const data = {
        page: state.adminPlaces.page,
        places: List,
        Nitems: state.adminPlaces.Nitems,
        query: state.adminPlaces.query,
        hidden: state.adminPlaces.hidden,
        incomplete: state.adminPlaces.incomplete,
      };
      dispatch(setPlacesAdminList(data));
    } catch (error) {
      //console.log(error)
      return error;
    }
  };
};
