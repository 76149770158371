import _ from "lodash";
import { getRandomColor } from "../../utils/getRandomColor";
import SplitItemsByDays from "../../utils/SplitItemsByDays";
import {
  SET_PLACES,
  SET_AGENDA_PLACES,
  INITIAL_SET,
  SET_NEW_USER_AGENDA,
  SET_NEW_INV_AGENDA,
  SET_CALENDAR_PUBLIC,
  SET_NEW_USER_INV_AGENDA,
  SET_CALENDAR_LOADING,
  SET_CALENDAR_NAME,
  SET_UNREGISTER_USER_AGENDA,
  SET_PREV_AGENDA_PLACES,
} from "../const";

export const calendar = (state = {}, action) => {
  switch (action.type) {
    case SET_PLACES:
      const stateOld = { ...state };
      stateOld.toDolist = action.value;
      stateOld.defaultToDoList = false;
      return stateOld;
    case SET_AGENDA_PLACES:
      const stateNew = { ...state };
      let prevItems = [];

      if (stateNew.agendaList.length === action.value.list.length) {
        prevItems = stateNew.prevAgendaList.map((it) => [...it]);
        prevItems.push(_.cloneDeepWith(stateNew.agendaList));
        if (prevItems.length > 3) {
          prevItems.shift();
        }
      }
      if (!action.value.notSave) {
        stateNew.prevAgendaList = prevItems;
      }
      stateNew.agendaList = action.value.list;
      stateNew.isLoading = false;
      stateNew.dayAgenda = action.value.days;
      stateNew.itemsByDaysMarkers = SplitItemsByDays(
        action.value.list,
        action.value.days
      );
      return stateNew;
    case SET_PREV_AGENDA_PLACES:
      return { ...state, prevAgendaList: action.value };
    case SET_CALENDAR_NAME:
      return { ...state, name: action.value };
    case SET_NEW_USER_AGENDA:
      return { ...state, users: action.value };
    case SET_NEW_INV_AGENDA:
      return { ...state, inv: action.value };
    case SET_CALENDAR_LOADING:
      return { ...state, isLoading: action.value };
    case SET_NEW_USER_INV_AGENDA:
      const newStateUI = { ...state };
      newStateUI.inv = action.value.inv;
      newStateUI.users = action.value.users;
      return newStateUI;
    case SET_UNREGISTER_USER_AGENDA:
      const newStateunre = { ...state };
      newStateunre.emailsEditInv = action.value.editors;
      newStateunre.emailsViewInv = action.value.viewer;
      return newStateunre;
    case INITIAL_SET:
      const newState = { ...state };
      newState.name = action.value.name;
      newState.emailsEditInv = action.value.emailsEditInv || [];
      newState.emailsViewInv = action.value.emailsViewInv || [];
      newState.id = action.value.id;
      newState.users = action.value.users.map((e, i) => {
        e.color = getRandomColor(i);
        return e;
      });
      newState.dayAgenda = action.value.dayAgenda;

      newState.dataUser = action.value.dataUser;
      newState.inv = action.value.inv.map((e, i) => {
        e.color = getRandomColor(newState.users.length + i);
        return e;
      });
      newState.city = action.value.city;
      newState.cityName = action.value.cityName;
      newState.cityNameEn = action.value.cityNameEn || action.value.cityName;
      newState.cityEmoji = action.value?.cityEmoji || {
        emoji: "",
        url: "",
      };
      newState.cityDataCoor = action.value.cityDataCoor || {
        lat: 10,
        lon: 10,
        radius: 0,
      };
      newState.agendaList = action.value.agendaItems;

      newState.toDolist = action.value.toDolistUser
        ? action.value.toDolistUser.concat(action.value.toDolist)
        : action.value.toDolist;
      newState.toDoTourslist = action?.value?.toDoTourslist;
      newState.maxDate = action.value.maxDate;
      newState.minDate = action.value.minDate;
      newState.startDate = action.value.startDate;
      newState.startAtTime = action.value.startAtTime;
      newState.public = action.value.public;
      newState.code = action.value?.code || "";
      newState.hotel = action.value?.hotel || undefined;
      newState.defaultToDoList = !!action?.value?.defaultToDoList;
      newState.isLoading = !!action?.value?.isLoading;
      newState.errorLoad = !!action?.value?.errorLoad;

      newState.model = action?.value?.model;
      newState.itemsByDaysMarkers = SplitItemsByDays(
        newState.agendaList,
        newState.dayAgenda
      );
      return newState;
    case SET_CALENDAR_PUBLIC:
      return { ...state, public: action.value };

    default:
      return state;
  }
};
