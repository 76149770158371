const colorList=[
  '#44926F',
  '#CA1603',
  '#541A3F',
  '#EF864C',
  '#0C7800',
  '#6245DE',
  '#C568A1',
  '#C16D22',
  '#34045B',
  '#D8B32C',
  '#710E91',
  '#721C4A',
  '#5A382E',
  '#B2350A',
  '#546BBC',
  '#4726A6',
  '#276C66',
  '#02BCE2',
  '#8C7857',
  '#9419B0',
]

export function getTrueRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
export function getRandomColor(number=(Math.floor(Math.random() * 20))) {
  if(number > colorList.length){
    const modulo = parseInt(number / colorList.length)
    number = number - modulo* colorList.length
  }
    return colorList[number];
  }