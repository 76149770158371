import {SET_CALENDAR_COUNT_ITEMS, SET_CALENDAR_INV_LIST, SET_CALENDAR_LIST, SET_CALENDAR_LIST_PAGE} from '../const'

export const calendarList = (state = {},action) =>{
    switch (action.type) {
        case SET_CALENDAR_LIST:
            return {...state, list: action.value};
        case SET_CALENDAR_INV_LIST:
            return {...state, invList: action.value};
            case SET_CALENDAR_LIST_PAGE:
                return {...state, page: action.value};
            case SET_CALENDAR_COUNT_ITEMS:
                return {...state, Nitems: action.value};
        default:
            return state;
    }
}